.relative{
    position: relative;
}

.inline-block{
	display: inline-block;
}

.contenedor-centrador{
	display: table;
	height: 100%;
	width: 100%;
}
.centrador{
	display: table-cell;
	vertical-align: middle;
}

.has-error input{
	border-color: #c40044 !important;
}

.opacity_0 *{
	opacity: 0 !important;
}

.degradado_1{
	background: rgb(106,173,223);
	background: -moz-linear-gradient(90deg, rgba(106,173,223,1) 0%, rgba(158,27,126,1) 100%);
	background: -webkit-linear-gradient(90deg, rgba(106,173,223,1) 0%, rgba(158,27,126,1) 100%);
	background: linear-gradient(90deg, rgba(106,173,223,1) 0%, rgba(158,27,126,1) 100%);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#6aaddf",endColorstr="#9e1b7e",GradientType=1);  
}

.degradado_2{
	background: rgb(106,173,223);
	background: -moz-linear-gradient(90deg, rgba(106,173,223,1) 0%, rgba(166,187,53,1) 100%);
	background: -webkit-linear-gradient(90deg, rgba(106,173,223,1) 0%, rgba(166,187,53,1) 100%);
	background: linear-gradient(90deg, rgba(106,173,223,1) 0%, rgba(166,187,53,1) 100%);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#6aaddf",endColorstr="#a6bb35",GradientType=1); 
}
.degradado_3{
	background: rgb(181,57,37);
	background: -moz-linear-gradient(90deg, rgba(181,57,37,1) 0%, rgba(199,128,42,1) 100%);
	background: -webkit-linear-gradient(90deg, rgba(181,57,37,1) 0%, rgba(199,128,42,1) 100%);
	background: linear-gradient(90deg, rgba(181,57,37,1) 0%, rgba(199,128,42,1) 100%);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#B53925",endColorstr="#C7802A",GradientType=1); 
}