//Theme es el look and feel, con cambiar los colores y tipografias cambia drasticamente una pagina

@font-face {
    src: url("../fonts/Gotham-Black.otf");
    font-family: Gotham-Black;
    font-display: swap;
}

$fonts-Gotham-Black: 'Gotham-Black';

@font-face {
    src: url("../fonts/Gotham-Medium.otf");
    font-family: Gotham-Medium;
    font-display: swap;
}

$fonts-Gotham-Medium: 'Gotham-Medium';

@font-face {
    src: url("../fonts/Gotham-MediumItalic.otf");
    font-family: Gotham-MediumItalic;
    font-display: swap;
}

$fonts-Gotham-MediumItalic: 'Gotham-MediumItalic';

@font-face {
    src: url("../fonts/Gotham-Bold.otf");
    font-family: Gotham-Bold;
    font-display: swap;
}

$fonts-Gotham-Bold: 'Gotham-Bold';

@font-face {
    src: url("../fonts/Gotham-Book.otf");
    font-family: Gotham-Book;
    font-display: swap;
}

$fonts-Gotham-Book: 'Gotham-Book';

@font-face {
    src: url("../fonts/Gotham-BookItalic.otf");
    font-family: Gotham-Book-Italic;
    font-display: swap;
}

$fonts-Gotham-Book-Italic: 'Gotham-Book-Italic';

@font-face {
    src: url("../fonts/Gotham-LightItalic.otf");
    font-family: Gotham-LightItalic;
    font-display: swap;
}

$fonts-Gotham-LightItalic: 'Gotham-LightItalic';