.contact {
	.esquina_derecha {
		background: none;
		display: none;
	}

	min-height: 510px;

	@media (min-width: 414px) {
		// * 1.29;
		min-height: 510px * 1.29;
	}

	@media (min-width: 768px) {
		// * 2.4;
		min-height: 510px * 2.4;
	}

	@media (min-width: 992px) {
		//*0.827;
		min-height: (670px);
	}

	@media (min-width: 1200px) {
		//*0.827;
		min-height: (750px + 50px);
	}

	@media (min-width: (((1200px*1.6)))) {
		//*1.6;
		min-height: 800px * 1.6;
	}

	@media (min-width: (((1200px*2)))) {
		min-height: 820px * 2;
	}

	.seccion_0 {
		@media (min-width: 992px) {
			display: none;
		}

		.breadcrumb {
			line-height: 1;
			position: relative;
			display: block;
			display: inline-block;
			margin-bottom: 0px;
			padding-bottom: 9px;
			margin-top: 14px;
			margin-left: 8px;

			@media (min-width: 414px) {
				// * 1.29;
				padding-bottom: 9px * 1.29;
				margin-top: 14px * 1.29;
				margin-left: 8px * 1.29;
			}

			@media (min-width: 768px) {
				// * 2.4;
				padding-bottom: 9px * 2.4;
				margin-top: 14px * 2.4;
				margin-left: 8px * 2.4;
			}

			a {
				font-family: $fonts-Gotham-Book;
				font-size: 9px;
				color: #3c3d38;
				text-decoration: none;

				@media (min-width: 414px) {
					font-size: 9px * 1.29;
				}

				@media (min-width: 768px) {
					font-size: 9px * 2.4;
				}

				&.active {
					font-family: $fonts-Gotham-Bold;
					margin-right: 0px;
				}
			}
			span {
				color: #000000;
				font-family: $fonts-Gotham-Book;
				font-size: 9px;
				margin-left: 3px;
				margin-right: 0px;

				@media (min-width: 414px) {
					// * 1.29;
					font-size: 9px * 1.29;
					margin-left: 3px * 1.29;
				}

				@media (min-width: 768px) {
					// * 2.4;
					font-size: 9px * 2.4;
					margin-left: 3px * 2.4;
				}
			}

			&::before {
				//Linea inferior
				content: '';
				position: absolute;
				width: 100%;
				height: 1px;
				background: #000;
				bottom: 0;
				left: 0;
			}
		}
	} //Breadcums

	.seccion_1 {
		.cuadrito_inicial {
			position: absolute;
			z-index: 11;
			display: none;

			@media (min-width: 992px) {
				display: block;
				//*0.827;
				width: 19px * 0.827;
				height: 19px * 0.827;
				top: 478px * 0.827;
				right: 5px * 0.827;
			}
			@media (min-width: 1200px) {
				width: 19px;
				height: 19px;
				top: 478px;
				right: 5px;
			}
			@media (min-width: (1200px*1.6)) {
				//*1.6;
				width: 19px * 1.6;
				height: 19px * 1.6;
				top: 478px * 1.6;
				right: 5px * 1.6;
			}
			@media (min-width: (1200px*2)) {
				//*2;
				width: 19px * 2;
				height: 19px * 2;
				top: 478px * 2;
				right: 5px * 2;
			}
		}

		position: relative;

		.container {
			position: relative;
		}

		padding-top: 30px;

		@media (min-width: 414px) {
			// * 1.29;
			padding-top: 30px * 1.29;
		}

		@media (min-width: 768px) {
			// * 2.4;
			padding-top: 30px * 2.4;
		}

		@media (min-width: 992px) {
			//*0.827;
			padding-top: 132px * 0.827;
		}
		@media (min-width: 1200px) {
			padding-top: 132px;
		}
		@media (min-width: (1200px*1.6)) {
			//*1.6;
			padding-top: 132px * 1.6;
		}
		@media (min-width: (1200px*2)) {
			//*2;
			padding-top: 132px * 2;
		}

		.linea {
			height: 2px;
			width: 10px;
			margin-left: 8px;
			@media (min-width: 414px) {
				margin-left: 8px * 1.29;
			}

			@media (min-width: 768px) {
				margin-left: 8px * 2.4;
			}

			@media (min-width: 992px) {
				//*0.827;
				margin-left: 0;
			}

			@media (min-width: 414px) {
				// * 1.29;
				height: 2px * 1.29;
				width: 10px * 1.29;
			}

			@media (min-width: 768px) {
				// * 2.4;
				height: 2px * 2.4;
				width: 10px * 2.4;
			}

			@media (min-width: 992px) {
				//*0.827;
				height: 10px * 0.827;
				width: 30px * 0.827;
			}
			@media (min-width: 1200px) {
				height: 10px;
				width: 30px;
			}
			@media (min-width: (1200px*1.6)) {
				//*1.6;
				height: 10px * 1.6;
				width: 30px * 1.6;
			}
			@media (min-width: (1200px*2)) {
				//*2;
				height: 10px * 2;
				width: 30px * 2;
			}
		}

		.titulo {
			font-family: $fonts-Gotham-Medium;
			color: #3f3f3d;
			line-height: 1.2;

			margin-bottom: 16px;
			font-size: 20px;
			margin-left: 8px;
			margin-top: 5px;

			@media (min-width: 414px) {
				font-size: 20px * 1.29;
				margin-left: 8px * 1.29;
				margin-top: 5px * 1.29;
				margin-bottom: 16px * 1.29;
			}

			@media (min-width: 768px) {
				font-size: 20px * 2.4;
				margin-left: 8px * 2.4;
				margin-top: 5px * 2.4;
				margin-bottom: 16px * 2.4;
			}

			@media (min-width: 992px) {
				//*0.827;
				margin-left: 0;
				margin-top: 0;
			}

			@media (min-width: 992px) {
				//*0.827;
				font-size: 60px * 0.827;
				margin-bottom: 60px * 0.827;
			}
			@media (min-width: 1200px) {
				font-size: 60px;
				margin-bottom: 60px;
			}
			@media (min-width: (1200px*1.6)) {
				//*1.6;
				font-size: 60px * 1.6;
				margin-bottom: 60px * 1.6;
			}
			@media (min-width: (1200px*2)) {
				//*2;
				font-size: 60px * 2;
				margin-bottom: 60px * 2;
			}
			span {
				font-family: $fonts-Gotham-Black;
			}
		}

		.descripcion {
			font-family: $fonts-Gotham-Book;
			color: #3c3d38;
			line-height: 1.2;

			margin: 0 auto 17px;
			text-align: justify;

			font-size: 9px;
			width: 242px;

			@media (min-width: 414px) {
				// * 1.29;
				font-size: 9px * 1.29;
				width: 242px * 1.29;
				margin: 0 auto 17px * 1.29;
			}

			@media (min-width: 768px) {
				// * 2.4;
				font-size: 9px * 2.4;
				width: 242px * 2.4;
				margin: 0 auto 17px * 2.4;
			}

			@media (min-width: 992px) {
				//*0.827;
				text-align: left;
				letter-spacing: -0.016em;

				font-size: 19px * 0.827;
				width: 510px * 0.827;
				margin-bottom: 30px * 0.827;
				margin-left: initial;
				margin-right: initial;
			}
			@media (min-width: 1200px) {
				font-size: 19px;
				width: 510px;
				margin-bottom: 30px;
			}
			@media (min-width: (1200px*1.6)) {
				//*1.6;
				font-size: 19px * 1.6;
				width: 510px * 1.6;
				margin-bottom: 30px * 1.6;
			}
			@media (min-width: (1200px*2)) {
				//*2;
				font-size: 19px * 2;
				width: 510px * 2;
				margin-bottom: 30px * 2;
			}
		}
		.subtitulo {
			color: #222220;
			font-family: $fonts-Gotham-Medium;
			line-height: 1.2;
			font-size: 15px;
			text-align: center;

			@media (min-width: 414px) {
				// * 1.29;
				font-size: 15px * 1.29;
			}

			@media (min-width: 768px) {
				// * 2.4;
				font-size: 15px * 2.4;
			}

			@media (min-width: 992px) {
				//*0.827;
				text-align: left;
				font-size: 38px * 0.827;
				font-family: $fonts-Gotham-Bold;
			}
			@media (min-width: 1200px) {
				font-size: 38px;
			}
			@media (min-width: (1200px*1.6)) {
				//*1.6;
				font-size: 38px * 1.6;
			}
			@media (min-width: (1200px*2)) {
				//*2;
				font-size: 38px * 2;
			}
		}

		.columna-derecha {
			margin-top: 24px;
			margin-left: 0px;

			@media (min-width: 414px) {
				// * 1.29;
				margin-top: 24px * 1.29;
			}

			@media (min-width: 768px) {
				// * 2.4;
				margin-top: 24px * 2.4;
			}

			@media (min-width: 992px) {
				//*0.827;
				margin-top: 28px * 0.827;
				margin-left: 50px * 0.827;
			}
			@media (min-width: 1200px) {
				margin-top: 28px;
				margin-left: 50px;
			}
			@media (min-width: (1200px*1.6)) {
				//*1.6;
				margin-top: 28px * 1.6;
				margin-left: 50px * 1.6;
			}
			@media (min-width: (1200px*2)) {
				//*2;
				margin-top: 28px * 2;
				margin-left: 50px * 2;
			}

			.contenedor_pasos {
				height: 150px;
				margin-bottom: 20px;

				@media (min-width: 414px) {
					// * 1.29;
					height: 150px * 1.29;
					margin-bottom: 20px * 1.29;
				}

				@media (min-width: 768px) {
					// * 2.4;
					height: 150px * 2.4;
					margin-bottom: 20px * 2.4;
				}

				@media (min-width: 992px) {
					//*0.827;
					height: 230px * 0.827;
					margin-bottom: 50px * 0.827;
				}
				@media (min-width: 1200px) {
					height: 230px;
					margin-bottom: 50px;
				}
				@media (min-width: (1200px*1.6)) {
					//*1.6;
					height: 230px * 1.6;
					margin-bottom: 50px * 1.6;
				}
				@media (min-width: (1200px*2)) {
					//*2;
					height: 230px * 2;
					margin-bottom: 50px * 2;
				}

				.paso_1 {
					.opciones {
						.opcion {
							display: flex;
							align-items: center;
							font-family: $fonts-Gotham-Book;
							color: #9e9c9b;
							background: #fff;
							background-clip: padding-box;
							cursor: pointer;
							box-sizing: border-box;
							position: relative;

							width: 230px;
							height: 30px;
							margin-bottom: 10px;
							padding-left: 10px;

							$border: 1px;
							border-radius: 30px;
							font-size: 10px;
							border: solid $border transparent; /* !importanté */

							margin-left: auto;
							margin-right: auto;

							@media (min-width: 414px) {
								// * 1.29;
								width: 230px * 1.29;
								height: 30px * 1.29;
								font-size: 10px * 1.29;
								border-radius: 30px * 1.29;
								padding-left: 10px * 1.29;
							}

							@media (min-width: 768px) {
								// * 2.4;
								width: 230px * 2.4;
								height: 30px * 2.4;
								font-size: 10px * 2.4;
								border-radius: 30px * 2.4;
								padding-left: 10px * 2.4;
							}

							@media (min-width: 992px) {
								//*0.827;
								font-size: 15px * 0.827;
								width: 348px * 0.827;
								height: 38px * 0.827;
								margin-bottom: 26px * 0.827;
								padding-left: 16px * 0.827;
								border-radius: 38px * 0.827;

								margin-left: initial;
								margin-right: initial;
							}
							@media (min-width: 1200px) {
								font-size: 15px;
								width: 348px;
								height: 38px;
								margin-bottom: 26px;
								padding-left: 16px;
								border-radius: 38px;
							}
							@media (min-width: (1200px*1.6)) {
								//*1.6;
								font-size: 24px;
								width: 557px;
								height: 61px;
								margin-bottom: 42px;
								padding-left: 26px;

								$border: 2px;
								border-radius: 61px;
								border: solid $border transparent; /* !importanté */
							}
							@media (min-width: (1200px*2)) {
								//*2;
								font-size: 15px * 2;
								width: 348px * 2;
								height: 38px * 2;
								margin-bottom: 26px * 2;
								padding-left: 16px * 2;
								border-radius: 38px * 2;
							}

							&:before {
								content: '';
								position: absolute;
								top: 0;
								left: 0;
								right: 0;
								bottom: 0;
								z-index: -1;
								margin: -$border; /* !importanté */
								border-radius: inherit; /* !importanté */
								background: rgb(106, 173, 223);
								background: -moz-linear-gradient(
									90deg,
									rgba(106, 173, 223, 1) 0%,
									rgba(158, 27, 126, 1) 100%
								);
								background: -webkit-linear-gradient(
									90deg,
									rgba(106, 173, 223, 1) 0%,
									rgba(158, 27, 126, 1) 100%
								);
								background: linear-gradient(
									90deg,
									rgba(106, 173, 223, 1) 0%,
									rgba(158, 27, 126, 1) 100%
								);

								$border: 1px;
								margin: -$border; /* !importanté */

								@media (min-width: 992px) {
									//*0.827;
									$border: 1px;
									margin: -$border; /* !importanté */
								}
								@media (min-width: 1200px) {
									$border: 1px;
									margin: -$border; /* !importanté */
								}
								@media (min-width: (1200px*1.6)) {
									//*1.6;
									$border: 2px;
									margin: -$border; /* !importanté */
								}
								@media (min-width: (1200px*2)) {
									//*2;
									$border: 2px;
									margin: -$border; /* !importanté */
								}
							}

							.circulo {
								position: absolute;
								z-index: 1;
								top: 0;
								bottom: 0;
								//background-color: red;
								margin: auto;
								background-image: url(../img/contact/circulo.svg);
								background-size: 100% auto;
								background-repeat: no-repeat;

								width: 12px;
								height: 12px;
								right: 10px;

								@media (min-width: 414px) {
									// * 1.29;
									width: 12px * 1.29;
									height: 12px * 1.29;
									right: 10px * 1.29;
								}

								@media (min-width: 768px) {
									// * 2.4;
									width: 12px * 2.4;
									height: 12px * 2.4;
									right: 10px * 2.4;
								}

								@media (min-width: 992px) {
									//*0.827;

									width: 12px;
									height: 12px;
									right: 15px * 0.827;
								}
								@media (min-width: 1200px) {
									width: 14px;
									height: 14px;
									right: 15px;
								}
								@media (min-width: (1200px*1.6)) {
									//*1.6;

									width: 22px;
									height: 22px;
									right: 15px * 1.6;
								}
								@media (min-width: (1200px*2)) {
									//*2;

									width: 14px * 2;
									height: 14px * 2;
									right: 15px * 2;
								}
							}

							&.active {
								font-family: $fonts-Gotham-Bold;
								color: #fff;
								background-color: transparent;

								.circulo {
									background-image: url(../img/contact/circulo_active.svg);
								}
							}

							@media (hover: hover) {
								&:hover {
									font-family: $fonts-Gotham-Bold;
									color: #fff;
									background-color: transparent;

									.circulo {
										background-image: url(../img/contact/circulo_active.svg);
									}
								}
							}
						}
					}
				}

				.paso_2 {
					.contenedor_input {
						display: flex;
						align-items: center;

						position: relative;
						box-sizing: border-box;

						color: #fff;
						background: #fff;
						background-clip: padding-box; /* !importanté */

						width: 230px;
						height: 30px;
						margin-bottom: 10px;
						$border: 1px;
						border-radius: 30px;
						border: solid $border transparent; /* !importanté */
						margin-left: auto;
						margin-right: auto;

						@media (min-width: 414px) {
							// * 1.29;
							width: 230px * 1.29;
							height: 30px * 1.29;
							font-size: 10px * 1.29;
							border-radius: 30px * 1.29;
						}

						@media (min-width: 768px) {
							// * 2.4;
							width: 230px * 2.4;
							height: 30px * 2.4;
							font-size: 10px * 2.4;
							border-radius: 30px * 2.4;
						}

						@media (min-width: 992px) {
							//*0.827;
							height: 38px * 0.827;
							width: 300px * 0.827;
							margin-bottom: 26px * 0.827;
							$border: 1px;
							border-radius: 35px * 0.827;
							border: solid $border transparent; /* !importanté */
							margin-left: initial;
							margin-right: initial;
						}
						@media (min-width: 1200px) {
							height: 36px;
							width: 298px;
							margin-bottom: 26px;
							$border: 1px;
							border-radius: 35px;
							border: solid $border transparent; /* !importanté */
						}
						@media (min-width: (1200px*1.6)) {
							//*1.6;
							height: 61px;
							width: 479px;
							margin-bottom: 42px;
							$border: 2px;
							border-radius: 56px;
							border: solid $border transparent; /* !importanté */
						}
						@media (min-width: (1200px*2)) {
							//*2;
							height: 38px * 2;
							width: 300px * 2;
							margin-bottom: 26px * 2;
							$border: 1px * 2;
							border-radius: 35px * 2;
							border: solid $border transparent; /* !importanté */
						}

						&:before {
							content: '';
							position: absolute;
							top: 0;
							right: 0;
							bottom: 0;
							left: 0;
							z-index: -1;
							$border: 1px;
							margin: -$border; /* !importanté */
							border-radius: inherit; /* !importanté */
							background: linear-gradient(
								90deg,
								rgba(106, 173, 223, 1) 0%,
								rgba(158, 27, 126, 1) 100%
							);

							@media (min-width: 992px) {
								//*0.827;
								$border: 1px;
								margin: -$border; /* !importanté */
							}
							@media (min-width: 1200px) {
								$border: 1px;
								margin: -$border; /* !importanté */
							}
							@media (min-width: (1200px*1.6)) {
								//*1.6;
								$border: 2px;
								margin: -$border; /* !importanté */
							}
							@media (min-width: (1200px*2)) {
								//*2;
								$border: 1px * 2;
								margin: -$border; /* !importanté */
							}
						}
					}
					input {
						background-color: transparent;
						border: none;
						position: absolute;
						top: 0;
						left: 0;
						color: #3c3d38;
						line-height: 1.2;
						font-family: $fonts-Gotham-Book;

						padding-left: 15px;
						padding-right: 15px;
						font-size: 10px;
						height: 36px;
						width: 298px;
						border-radius: 35px;

						width: 100%;
						height: 100%;
						text-align: center;

						@media (min-width: 414px) {
							// * 1.29;
							font-size: 10px * 1.29;
						}

						@media (min-width: 768px) {
							// * 2.4;
							font-size: 10px * 2.4;
						}

						@media (min-width: 992px) {
							//*0.827;
							padding-left: 15px * 0.827;
							padding-right: 15px * 0.827;
							font-size: 15px * 0.827;
							height: 36px * 0.827;
							width: 298px * 0.827;
							border-radius: 35px * 0.827;
							text-align: left;
						}
						@media (min-width: 1200px) {
							padding-left: 15px;
							padding-right: 15px;
							font-size: 15px;
							height: 36px;
							width: 298px;
							border-radius: 35px;
						}
						@media (min-width: (1200px*1.6)) {
							//*1.6;
							padding-left: 24px;
							padding-right: 24px;
							font-size: 24px;
							height: 57px;
							width: 477px;
							border-radius: 56px;
						}
						@media (min-width: (1200px*2)) {
							//*2;
							padding-left: 15px * 2;
							padding-right: 15px * 2;
							font-size: 15px * 2;
							height: 36px * 2;
							width: 298px * 2;
							border-radius: 35px * 2;
						}

						&:focus-visible {
							outline: none;
						}
					}

					.contenedor_textarea {
						display: flex;
						align-items: center;
						position: relative;
						box-sizing: border-box;
						color: #fff;
						background: #fff;
						background-clip: padding-box; /* !importanté */

						height: 70px;
						width: 230px;
						$border: 1px;
						border-radius: 15px;
						border: solid $border transparent; /* !importanté */
						margin: auto;

						@media (min-width: 414px) {
							// * 1.29;
							width: 230px * 1.29;
							height: 70px * 1.29;
							border-radius: 15px * 1.29;
						}

						@media (min-width: 768px) {
							// * 2.4;
							width: 230px * 2.4;
							height: 70px * 2.4;
							border-radius: 15px * 2.4;
						}

						@media (min-width: 992px) {
							//*0.827;
							height: 102px * 0.827;
							width: 300px * 0.827;
							$border: 1px;
							border-radius: 18px * 0.827;
							border: solid $border transparent; /* !importanté */
							margin-left: initial;
							margin-right: initial;
						}
						@media (min-width: 1200px) {
							height: 102px;
							width: 298px;
							$border: 1px;
							border-radius: 18px;
							border: solid $border transparent; /* !importanté */
						}
						@media (min-width: (1200px*1.6)) {
							//*1.6;
							height: 163px;
							width: 480px;
							$border: 2px;
							border-radius: 29px;
							border: solid $border transparent; /* !importanté */
						}
						@media (min-width: (1200px*2)) {
							//*2;
							height: 102px * 2;
							width: 300px * 2;
							$border: 1px * 2;
							border-radius: 18px * 2;
							border: solid $border transparent; /* !importanté */
						}

						&:before {
							content: '';
							position: absolute;
							top: 0;
							right: 0;
							bottom: 0;
							left: 0;
							z-index: -1;
							$border: 1px;
							margin: -$border; /* !importanté */
							border-radius: inherit; /* !importanté */
							background: linear-gradient(
								90deg,
								rgba(106, 173, 223, 1) 0%,
								rgba(158, 27, 126, 1) 100%
							);

							@media (min-width: 992px) {
								//*0.827;
								$border: 1px;
								margin: -$border; /* !importanté */
							}
							@media (min-width: 1200px) {
								$border: 1px;
								margin: -$border; /* !importanté */
							}
							@media (min-width: (1200px*1.6)) {
								//*1.6;
								$border: 2px;
								margin: -$border; /* !importanté */
							}
							@media (min-width: (1200px*2)) {
								//*2;
								$border: 1px * 2;
								margin: -$border; /* !importanté */
							}
						}
					}

					textarea {
						background-color: transparent;
						border: none;
						position: absolute;
						top: 0;
						left: 0;
						color: #3c3d38;
						font-family: $fonts-Gotham-Book;
						line-height: 1.2;

						padding-left: 15px;
						padding-right: 15px;
						width: 100%;
						height: 100%;
						border-radius: 15px;
						font-size: 10px;
						resize: none;
						text-align: center;

						@media (min-width: 414px) {
							// * 1.29;
							font-size: 10px * 1.29;
							border-radius: 15px * 1.29;
						}

						@media (min-width: 768px) {
							// * 2.4;
							font-size: 10px * 2.4;
							border-radius: 15px * 2.4;
						}

						@media (min-width: 992px) {
							//*0.827;
							padding-left: 15px * 0.827;
							padding-right: 15px * 0.827;
							margin-top: 10px * 0.827;
							margin-bottom: 10px * 0.827;
							height: 80px * 0.827;
							width: 298px * 0.827;
							border-radius: 35px * 0.827;
							font-size: 15px * 0.827;
							text-align: left;
						}
						@media (min-width: 1200px) {
							padding-left: 15px;
							padding-right: 15px;
							margin-top: 10px;
							margin-bottom: 10px;
							height: 80px;
							width: 298px;
							border-radius: 35px;
							font-size: 15px;
						}
						@media (min-width: (1200px*1.6)) {
							//*1.6;
							padding-left: 15px * 1.6;
							padding-right: 15px * 1.6;
							margin-top: 10px * 1.6;
							margin-bottom: 10px * 1.6;
							height: 80px * 1.6;
							width: 298px * 1.6;
							border-radius: 35px * 1.6;
							font-size: 15px * 1.6;
						}
						@media (min-width: (1200px*2)) {
							//*2;
							padding-left: 15px * 2;
							padding-right: 15px * 2;
							margin-top: 10px * 2;
							margin-bottom: 10px * 2;
							height: 80px * 2;
							width: 298px * 2;
							border-radius: 35px * 2;
							font-size: 15px * 2;
						}

						&:focus-visible {
							outline: none;
						}
					}
				}
			}

			.contenedor_boton {
				display: flex;
				align-items: center;

				flex-direction: column-reverse;

				@media (min-width: 992px) {
					flex-direction: row;
				}

				#btn-next {
					display: flex !important;
					align-items: center;

					margin: auto;

					position: relative;
					box-sizing: border-box;
					color: #3f3f3d;
					background: #fff;
					background-clip: padding-box;

					text-decoration: none;
					justify-content: center;
					font-family: $fonts-Gotham-Medium;

					width: 100px;
					height: 30px;
					$border: 1px;
					border-radius: 30px;
					font-size: 10px;
					border: solid $border transparent; /* !importanté */

					@media (min-width: 414px) {
						// * 1.29;
						width: 100px * 1.29;
						height: 30px * 1.29;
						font-size: 10px * 1.29;
						border-radius: 30px * 1.29;
					}

					@media (min-width: 768px) {
						// * 2.4;
						width: 100px * 2.4;
						height: 30px * 2.4;
						font-size: 10px * 2.4;
						border-radius: 30px * 2.4;
					}

					@media (min-width: 992px) {
						//*0.827;
						width: 79px;
						height: 32px;
						font-size: 13px;
						margin-left: 17px;
						margin-right: initial;
						border-radius: 32px;
					}
					@media (min-width: 1200px) {
						width: 96px;
						height: 39px;
						font-size: 16px;
						margin-left: 21px;
						border-radius: 39px;
					}
					@media (min-width: (1200px*1.6)) {
						//*1.6;
						width: 153px;
						height: 62px;
						font-size: 25px;
						margin-left: 33px;
						border-radius: 62px;
						$border: 2px;

						border: solid $border transparent; /* !importanté */
					}
					@media (min-width: (1200px*2)) {
						//*2;
						width: 96px * 2;
						height: 39px * 2;
						font-size: 16px * 2;
						margin-left: 21px * 2;
						border-radius: 39px * 2;
					}

					&:before {
						content: '';
						position: absolute;
						top: 0;
						left: 0;
						right: 0;
						bottom: 0;
						z-index: -1;
						margin: -$border; /* !importanté */
						border-radius: inherit; /* !importanté */
						background: rgb(106, 173, 223);
						background: -moz-linear-gradient(
							90deg,
							rgba(106, 173, 223, 1) 0%,
							rgba(158, 27, 126, 1) 100%
						);
						background: -webkit-linear-gradient(
							90deg,
							rgba(106, 173, 223, 1) 0%,
							rgba(158, 27, 126, 1) 100%
						);
						background: linear-gradient(
							90deg,
							rgba(106, 173, 223, 1) 0%,
							rgba(158, 27, 126, 1) 100%
						);
						$border: 1px;
						margin: -$border; /* !importanté */

						@media (min-width: 992px) {
							//*0.827;
							$border: 1px;
							margin: -$border; /* !importanté */
						}
						@media (min-width: 1200px) {
							$border: 1px;
							margin: -$border; /* !importanté */
						}
						@media (min-width: (1200px*1.6)) {
							//*1.6;
							$border: 2px;
							margin: -$border; /* !importanté */
						}
						@media (min-width: (1200px*2)) {
							//*2;
							$border: 2px;
							margin: -$border; /* !importanté */
						}
					}

					&.send {
						width: 230px;

						@media (min-width: 414px) {
							// * 1.29;
							width: 230px * 1.29;
						}

						@media (min-width: 768px) {
							// * 2.4;
							width: 230px * 2.4;
						}

						@media (min-width: 992px) {
							//*0.827;
							width: 79px;
						}
						@media (min-width: 1200px) {
							width: 96px;
						}
						@media (min-width: (1200px*1.6)) {
							//*1.6;
							width: 153px;
						}
						@media (min-width: (1200px*2)) {
							//*2;
							width: 96px * 2;
						}
					}

					.texto {
						text-decoration: none;
						display: flex;
						justify-content: center;
						align-items: center;
						width: 100%;
						height: 100%;
						font-family: $fonts-Gotham-Medium;
						color: #3f3f3d;

						font-size: 10px;

						@media (min-width: 414px) {
							// * 1.29;
							font-size: 10px * 1.29;
						}

						@media (min-width: 768px) {
							// * 2.4;
							font-size: 10px * 2.4;
						}

						@media (min-width: (1200px*0.827)) {
							//*0.827;
							font-size: 16px * 0.827;
						}
						@media (min-width: 1200px) {
							font-size: 16px;
						}
						@media (min-width: (1200px*1.6)) {
							//*1.6;
							font-size: 16px * 1.6;
						}
						@media (min-width: (1200px*2)) {
							//*2;
							font-size: 16px * 2;
						}

						img {
							margin-left: 10px;
							height: 10px;
							width: auto;

							@media (min-width: 414px) {
								// * 1.29;
								height: 10px * 1.29;
								margin-left: 10px * 1.29;
							}

							@media (min-width: 768px) {
								// * 2.4;
								height: 10px * 2.4;
								margin-left: 10px * 2.4;
							}

							@media (min-width: 992px) {
								//*0.827;
								margin-left: 15px * 0.827;
								height: 13px * 0.827;
							}
							@media (min-width: 1200px) {
								margin-left: 15px;
								height: 13px;
							}
							@media (min-width: (1200px*1.6)) {
								//*1.6;
								margin-left: 15px * 1.6;
								height: 13px * 1.6;
							}
							@media (min-width: (1200px*2)) {
								//*2;
								margin-left: 15px * 2;
								height: 13px * 2;
							}
						}
					}
				}

				.linea_2 {
					position: relative;
					width: 230px;

					height: 1px;

					@media (min-width: 414px) {
						// * 1.29;
						width: 230px * 1.29;
					}

					@media (min-width: 768px) {
						// * 2.4;
						width: 230px * 2.4;
					}

					@media (min-width: 992px) {
						background-color: #3f3f3d;
						//*0.827;
						display: block;
						width: 278px * 0.827;
						height: 1px;
					}
					@media (min-width: 1200px) {
						width: 278px;
						height: 1px;
					}
					@media (min-width: (1200px*1.6)) {
						//*1.6;
						width: 278px * 1.6;
						height: 1px * 1.6;
					}
					@media (min-width: (1200px*2)) {
						//*2;
						width: 278px * 2;
						height: 1px * 2;
					}

					.mensaje {
						position: absolute;
						width: 100%;
						font-size: 10px;
						//background-color: red;
						top: 4px;
						text-align: center;
						font-family: $fonts-Gotham-Book;
						color: #3c3d38;

						@media (min-width: 414px) {
							font-size: 10px * 1.29;
						}

						@media (min-width: 768px) {
							font-size: 10px * 2.4;
						}

						@media (min-width: 992px) {
							//*0.827;
							text-align: right;
							font-size: 13.5px * 0.827;
						}
						@media (min-width: 1200px) {
							font-size: 13.5px;
						}
						@media (min-width: (1200px*1.6)) {
							//*1.6;
							font-size: 13.5px * 1.6;
							top: 4px * 1.6;
						}
						@media (min-width: (1200px*2)) {
							//*2;
							font-size: 13.5px * 2;
							top: 4px * 2;
						}
					}
				}
			}
		}
	}
}
