.d2 {
	@media (max-width: 991px) {
		.seccion_1 .container,
		.seccion_3 .container {
			max-width: initial;
		}
	}

	#giffy-images-container {
		img {
			position: absolute;
			top: 0;
			left: 0;
			transition: opacity 0.3s;
		}
	}

	.esquina_derecha {
		background: none;
	}
	.seccion_0 {
		.breadcrumb {
			line-height: 1;
			position: relative;
			display: block;
			display: inline-block;
			margin-bottom: 0px;

			padding-bottom: 9px;
			margin-top: 14px;
			margin-left: 8px;

			@media (min-width: 414px) {
				// * 1.29;
				padding-bottom: 9px * 1.29;
				margin-top: 14px * 1.29;
				margin-left: 8px * 1.29;
			}

			@media (min-width: 768px) {
				// * 2.4;
				padding-bottom: 9px * 2.4;
				margin-top: 14px * 2.4;
				margin-left: 8px * 2.4;
			}

			@media (min-width: 992px) {
				//*0.827;
				margin-left: 0px;
				padding-bottom: 14px * 0.827;
				margin-top: 14px * 0.827;
			}

			@media (min-width: 1200px) {
				padding-bottom: 14px;
				margin-top: 14px;
			}
			@media (min-width: (1200px*1.6)) {
				//*1.6;
				padding-bottom: 14px * 1.6;
				margin-top: 14px * 1.6;
			}

			@media (min-width: (1200px*2)) {
				//*2;
				padding-bottom: 14px * 2;
				margin-top: 14px * 2;
			}

			a {
				font-family: $fonts-Gotham-Book;
				font-size: 9px;
				color: #3c3d38;
				text-decoration: none;

				@media (min-width: 414px) {
					font-size: 9px * 1.29;
				}

				@media (min-width: 768px) {
					font-size: 9px * 2.4;
				}

				@media (min-width: 992px) {
					font-size: 9px * 0.827;
				}

				@media (min-width: 1200px) {
					font-size: 9px;
				}

				@media (min-width: (1200px*1.6)) {
					font-size: 9px * 1.6;
				}

				@media (min-width: (1200px*2)) {
					font-size: 9px * 2;
				}

				&.active {
					font-family: $fonts-Gotham-Bold;
					margin-right: 0px; //18px

					@media (min-width: 992px) {
						margin-right: 18px * 0.827;
					}

					@media (min-width: 1200px) {
						margin-right: 18px;
					}

					@media (min-width: (1200px*1.6)) {
						margin-right: 18px * 1.6;
					}

					@media (min-width: (1200px*2)) {
						margin-right: 18px * 2;
					}
				}
			}
			span {
				color: #000000;
				font-family: $fonts-Gotham-Book;
				font-size: 9px;
				margin-left: 3px;
				margin-right: 0px;

				@media (min-width: 414px) {
					// * 1.29;
					font-size: 9px * 1.29;
					margin-left: 3px * 1.29;
				}

				@media (min-width: 768px) {
					// * 2.4;
					font-size: 9px * 2.4;
					margin-left: 3px * 2.4;
				}

				@media (min-width: 992px) {
					//*0.827;
					font-size: 9px * 0.827;
					margin-left: 7px * 0.827;
					margin-right: 8px * 0.827;
				}

				@media (min-width: 1200px) {
					font-size: 9px;
					margin-left: 7px;
					margin-right: 8px;
				}

				@media (min-width: (1200px*1.6)) {
					//*1.6;
					font-size: 9px * 1.6;
					margin-left: 7px * 1.6;
					margin-right: 8px * 1.6;
				}

				@media (min-width: (1200px*2)) {
					//*2;
					font-size: 9px * 2;
					margin-left: 7px * 2;
					margin-right: 8px * 2;
				}
			}

			&::before {
				//Linea inferior
				content: '';
				position: absolute;
				width: 100%;
				height: 1px;
				background: #000;
				bottom: 0;
				left: 0;
			}
		}
	}

	.seccion_1 {
		position: relative;

		padding-bottom: 36px;

		@media (min-width: 414px) {
			padding-bottom: 36px * 1.29;
		}

		@media (min-width: 768px) {
			padding-bottom: 36px * 2.4;
		}

		@media (min-width: 992px) {
			padding-bottom: 0px;
		}

		.container {
			position: relative;
		}

		padding-top: 30px;

		@media (min-width: 414px) {
			// * 1.29;
			padding-top: 30px * 1.29;
		}

		@media (min-width: 768px) {
			// * 2.4;
			padding-top: 30px * 2.4;
		}

		@media (min-width: 992px) {
			//*0.827;
			padding-top: 94px * 0.827;
		}

		@media (min-width: 1200px) {
			padding-top: 94px;
		}

		@media (min-width: (1200px*1.6)) {
			//*1.6;
			padding-top: 94px * 1.6;
		}

		@media (min-width: (1200px*2)) {
			//*2;
			padding-top: 94px * 2;
		}

		.linea {
			height: 2px;
			width: 10px;
			margin-left: 8px;

			@media (min-width: 414px) {
				margin-left: 8px * 1.29;
			}

			@media (min-width: 768px) {
				margin-left: 8px * 2.4;
			}

			@media (min-width: 992px) {
				//*0.827;
				margin-left: 0;
			}

			@media (min-width: 414px) {
				// * 1.29;
				height: 2px * 1.29;
				width: 10px * 1.29;
			}

			@media (min-width: 768px) {
				// * 2.4;
				height: 2px * 2.4;
				width: 10px * 2.4;
			}

			@media (min-width: 992px) {
				//*0.827;
				margin-top: 8px * 0.827;
				height: 10px * 0.827;
				width: 30px * 0.827;
			}

			@media (min-width: 1200px) {
				margin-top: 8px;
				height: 10px;
				width: 30px;
			}

			@media (min-width: (1200px*1.6)) {
				//*1.6;
				margin-top: 8px * 1.6;
				height: 10px * 1.6;
				width: 30px * 1.6;
			}

			@media (min-width: (1200px*2)) {
				//*2;
				margin-top: 8px * 2;
				height: 10px * 2;
				width: 30px * 2;
			}
		}

		.titulo {
			font-family: $fonts-Gotham-Medium;
			color: #3f3f3d;
			line-height: 1.2;
			font-size: 20px;
			margin-left: 8px;
			margin-top: 5px;
			margin-bottom: 16px;

			@media (min-width: 414px) {
				font-size: 20px * 1.29;
				margin-left: 8px * 1.29;
				margin-top: 5px * 1.29;
				margin-bottom: 16px * 1.29;
			}

			@media (min-width: 768px) {
				font-size: 20px * 2.4;
				margin-left: 8px * 2.4;
				margin-top: 5px * 2.4;
				margin-bottom: 16px * 2.4;
			}

			@media (min-width: 992px) {
				//*0.827;
				font-family: $fonts-Gotham-Black;
				line-height: 1.4; //.93
				font-size: 50px * 0.827;
				margin-bottom: 60px * 0.827;
				padding-top: 6px * 0.827;

				margin-left: 0;
				margin-top: 0;
			}

			@media (min-width: 1200px) {
				font-size: 50px;
				margin-bottom: 60px;
				padding-top: 6px;
			}

			@media (min-width: (1200px*1.6)) {
				//*1.6;
				font-size: 50px * 1.6;
				margin-bottom: 60px * 1.6;
				padding-top: 6px * 1.6;
			}

			@media (min-width: (1200px*2)) {
				//*2;
				font-size: 50px * 2;
				margin-bottom: 60px * 2;
				padding-top: 6px * 2;
			}
		}

		.descripcion {
			font-family: $fonts-Gotham-Book;
			color: #3f3f3d;
			line-height: 1.2;
			text-align: left;

			margin: 0 auto 30px;
			text-align: justify;

			font-size: 9px;
			width: 240px;

			@media (min-width: 414px) {
				// * 1.29;
				font-size: 9px * 1.29;
				width: 240px * 1.29;
				margin: 0 auto 30px * 1.29;
			}

			@media (min-width: 768px) {
				// * 2.4;
				font-size: 9px * 2.4;
				width: 240px * 2.4;
				margin: 0 auto 30px * 2.4;
			}

			@media (min-width: 992px) {
				//*0.827;
				text-align: left;
				letter-spacing: -0.013em;

				margin-bottom: 0px * 0.827;
				font-size: 16px * 0.827;
				width: 506.66px * 0.827;
				margin-right: initial;
				margin-left: initial;
			}
			@media (min-width: 1200px) {
				font-size: 16px;
				margin-bottom: 0px;
				width: 506.66px;
			}
			@media (min-width: (1200px*1.6)) {
				//*1.6;
				font-size: 16px * 1.6;
				margin-bottom: 0px * 1.6;
				width: 506.66px * 1.6;
			}
			@media (min-width: (1200px*2)) {
				//*2;
				font-size: 16px * 2;
				margin-bottom: 0px * 2;
				width: 506.66px * 2;
			}
		}

		.contenedor_imagen_principal {
			position: relative;
			&::before {
				position: absolute;
				z-index: -1;
				width: 100%;
				left: 0;
				bottom: -36px;
				height: calc(50% + 36px);
				content: '';
				background-color: #941b7e;

				@media (min-width: 414px) {
					// * 1.29;

					bottom: -36px * 1.29;
					height: calc(50% + (36px * 1.29));
				}

				@media (min-width: 768px) {
					// * 2.4;
					bottom: -36px * 2.4;
					height: calc(50% + (36px * 2.4));
				}
				@media (min-width: 992px) {
					display: none;
				}
			}
		}

		.imagen_principal {
			width: 100%;
			background-size: cover;
			background-position: 50% 50%;
			position: relative;
			background: #fff;

			max-width: 280px;
			height: 158px;
			margin: auto;

			@media (min-width: 414px) {
				// * 1.29;
				max-width: 280px * 1.29;
				height: 158px * 1.29;
			}

			@media (min-width: 768px) {
				// * 2.4;
				max-width: 280px * 2.4;
				height: 158px * 2.4;
			}

			@media (min-width: 992px) {
				//*0.827;
				max-width: 569px * 0.827;
				height: 320px * 0.827;
				margin-left: 15px * 0.827;
				margin-right: initial;
			}

			@media (min-width: 1200px) {
				max-width: 569px;
				height: 320px;
				margin-left: 15px;
			}

			@media (min-width: (1200px*1.6)) {
				//*1.6;
				max-width: 569px * 1.6;
				height: 320px * 1.6;
				margin-left: 15px * 1.6;
			}

			@media (min-width: (1200px*2)) {
				//*2;
				max-width: 569px * 2;
				height: 320px * 2;
				margin-left: 15px * 2;
			}

			img {
				width: 100%;
			}
		}
	}

	.seccion_2 {
		padding-top: 83px; //166px; original
		padding-bottom: 83px;
		position: relative;

		/* clear fix */
		.grid:after {
			content: '';
			display: block;
			clear: both;
		}

		.grid-item,
		.grid-sizer {
			width: 0.01%;
		}

		.gutter-sizer {
			width: 0.2%;
		}

		.grid-item {
			float: left;
			//height: 100px;
		}

		.imagen {
			.imagen_interna {
				background-size: auto 100%;
				background-position: 50% 50%;
				transition: background-size 0.5s ease-in;
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;
				//border: 1px solid #fff;
			}
			.titulo {
				display: none;
			}

			.titulo {
				font-size: 23px;
				margin-bottom: 8px;
			}

			.go {
				display: none;
				width: 185px;
				height: 45px;
				font-size: 20px;

				img {
					display: block;
					margin-left: 11px;
					height: 29px;
					padding-top: 2px;

					@media (min-width: 992px) {
						//*0.827;
						margin-left: 11px * 0.827;
						height: 29px * 0.827;
						padding-top: 2px * 0.827;
					}
					@media (min-width: 1200px) {
						margin-left: 11px;
						height: 29px;
						padding-top: 2px;
					}
					@media (min-width: (1200px*1.6)) {
						//*1.6;
						margin-left: 11px * 1.6;
						height: 29px * 1.6;
						padding-top: 2px * 1.6;
					}
					@media (min-width: (1200px*2)) {
						//*2;
						margin-left: 11px * 2;
						height: 29px * 2;
						padding-top: 2px * 2;
					}
				}
			}

			&:hover {
				.imagen_interna {
					background-size: auto 110% !important;
				}

				.overlay_1 {
					z-index: 1;
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					//background-color: #3F3F3D ;
					background-color: rgba(#3f3f3d, 1);
					//background-blend-mode:luminosity;
					mix-blend-mode: color;
				}
				.overlay_2 {
					z-index: 2;
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					//background-color: #3F3F3D ;
					background-color: rgba(#6aaddf, 0.5);
					//background-blend-mode:luminosity;
					mix-blend-mode: color;
				}
				.overlay_3 {
					z-index: 3;
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					//background-color: #3F3F3D ;
					background: rgb(0, 0, 0);
					background: -moz-linear-gradient(
						90deg,
						rgba(0, 0, 0, 1) 0%,
						rgba(0, 0, 0, 0) 100%
					);
					background: -webkit-linear-gradient(
						90deg,
						rgba(0, 0, 0, 1) 0%,
						rgba(0, 0, 0, 0) 100%
					);
					background: linear-gradient(
						90deg,
						rgba(0, 0, 0, 1) 0%,
						rgba(0, 0, 0, 0) 100%
					);
					filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000",endColorstr="#000000",GradientType=1);
					opacity: 0.7;
				}

				.titulo {
					display: block;
					z-index: 4;
					font-family: $fonts-Gotham-Black;
					color: white;
					text-align: center;
				}

				.go {
					z-index: 5;
					text-decoration: none;
					display: flex !important;
					justify-content: center;
					align-items: center;
					font-family: $fonts-Gotham-Book;
					color: #fff;
					position: relative;

					&:before {
						content: '';
						position: absolute;
						z-index: -1;
						top: 0;
						left: 0;
						right: 0;
						bottom: 0;
						/*background: rgb(106,173,223);
						background: -moz-linear-gradient(90deg, rgba(106,173,223,1) 0%, rgba(166,187,53,1) 100%);
						background: -webkit-linear-gradient(90deg, rgba(106,173,223,1) 0%, rgba(166,187,53,1) 100%);
						background: linear-gradient(90deg, rgba(106,173,223,1) 0%, rgba(166,187,53,1) 100%);
						filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#6aaddf",endColorstr="#a6bb35",GradientType=1); 
						
						background: rgb(106,173,223);
						background: -moz-linear-gradient(90deg, rgba(106,173,223,1) 0%, rgba(158,27,126,1) 100%);
						background: -webkit-linear-gradient(90deg, rgba(106,173,223,1) 0%, rgba(158,27,126,1) 100%);
						background: linear-gradient(90deg, rgba(106,173,223,1) 0%, rgba(158,27,126,1) 100%);
						filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#6aaddf",endColorstr="#9e1b7e",GradientType=1);  
						*/
						background: rgb(181, 57, 37);
						background: -moz-linear-gradient(
							90deg,
							rgba(181, 57, 37, 1) 0%,
							rgba(199, 128, 42, 1) 100%
						);
						background: -webkit-linear-gradient(
							90deg,
							rgba(181, 57, 37, 1) 0%,
							rgba(199, 128, 42, 1) 100%
						);
						background: linear-gradient(
							90deg,
							rgba(181, 57, 37, 1) 0%,
							rgba(199, 128, 42, 1) 100%
						);
						filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#B53925",endColorstr="#C7802A",GradientType=1);

						-webkit-mask: linear-gradient(#fff 0 0) content-box,
							linear-gradient(#fff 0 0);
						mask: linear-gradient(#fff 0 0) content-box,
							linear-gradient(#fff 0 0);
						-webkit-mask-composite: destination-out;
						mask-composite: exclude;
						padding: 2px;
						border-radius: 35px;

						@media (min-width: 992px) {
							//*0.827;
							padding: 1px;
							border-radius: 29px;
						}
						@media (min-width: 1200px) {
							padding: 2px;
							border-radius: 35px;
						}
						@media (min-width: (1200px*1.6)) {
							//*1.6;
							padding: 3px;
							border-radius: 35px * 1.6;
						}
						@media (min-width: (1200px*2)) {
							//*2;
							padding: 2px * 2;
							border-radius: 35px * 2;
						}
					}
				}
			}
		}

		.imagen_0 {
			width: 37.38%;
			&:before {
				content: '';
				display: block;
				padding-top: 100%;
				width: 100%;
				position: relative;
				float: left;
			}

			.imagen_interna {
				height: 100%;
				width: 100%;
				position: absolute;
				top: 0;
				left: 0;

				&:before {
					content: '';
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
				}
			}
			background-color: red;
		}
		.imagen_1 {
			width: 31.31%;
			background-color: purple;
			&:before {
				content: '';
				display: block;
				padding-top: 100%;
				width: 100%;
				position: relative;
				float: left;
			}

			.imagen_interna {
				height: 100%;
				width: 100%;
				position: absolute;
				top: 0;
				left: 0;

				&:before {
					content: '';
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
				}
			}
		}
		.imagen_2 {
			width: 31.31%;
			background-color: yellow;
			&:before {
				content: '';
				display: block;
				padding-top: 100%;
				width: 100%;
				position: relative;
				float: left;
			}

			.imagen_interna {
				height: 100%;
				width: 100%;
				position: absolute;
				top: 0;
				left: 0;

				&:before {
					content: '';
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
				}
			}
		}
		.imagen_3 {
			width: 25.24%;

			background-color: green;
			&:before {
				content: '';
				display: block;
				padding-top: 98.2%;
				width: 100%;
				position: relative;
				float: left;
			}

			.imagen_interna {
				height: 100%;
				width: 100%;
				position: absolute;
				top: 0;
				left: 0;

				&:before {
					content: '';
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
				}
			}
		}
		.imagen_4 {
			width: 37.38%;
			background-color: blue;
			&:before {
				content: '';
				display: block;
				padding-top: 100%;
				width: 100%;
				position: relative;
				float: left;
			}

			.imagen_interna {
				height: 100%;
				width: 100%;
				position: absolute;
				top: 0;
				left: 0;

				&:before {
					content: '';
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
				}
			}
		}
		.imagen_5 {
			width: 18.69%;
			background-color: gray;
			&:before {
				content: '';
				display: block;
				padding-top: 100%;
				width: 100%;
				position: relative;
				float: left;
			}

			.imagen_interna {
				height: 100%;
				width: 100%;
				position: absolute;
				top: 0;
				left: 0;

				&:before {
					content: '';
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
				}
			}
		}
		.imagen_6 {
			background-color: gray;
			width: 18.69%;
			&:before {
				content: '';
				display: block;
				padding-top: 100%;
				width: 100%;
				position: relative;
				float: left;
			}

			.imagen_interna {
				height: 100%;
				width: 100%;
				position: absolute;
				top: 0;
				left: 0;

				&:before {
					content: '';
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
				}
			}
		}
		.imagen_7 {
			background-color: gray;
			width: 31.31%;
			&:before {
				content: '';
				display: block;
				padding-top: 100%;
				width: 100%;
				position: relative;
				float: left;
			}

			.imagen_interna {
				height: 100%;
				width: 100%;
				position: absolute;
				top: 0;
				left: 0;

				&:before {
					content: '';
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
				}
			}
		}
		.imagen_8 {
			background-color: gray;
			width: 31.31%;
			&:before {
				content: '';
				display: block;
				padding-top: 100%;
				width: 100%;
				position: relative;
				float: left;
			}

			.imagen_interna {
				height: 100%;
				width: 100%;
				position: absolute;
				top: 0;
				left: 0;

				&:before {
					content: '';
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
				}
			}
		}
		.imagen_9 {
			background-color: gray;
			width: 18.69%;
			&:before {
				content: '';
				display: block;
				padding-top: 100%;
				width: 100%;
				position: relative;
				float: left;
			}

			.imagen_interna {
				height: 100%;
				width: 100%;
				position: absolute;
				top: 0;
				left: 0;

				&:before {
					content: '';
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
				}
			}
		}
		.imagen_10 {
			background-color: gray;
			width: 18.72%;
			&:before {
				content: '';
				display: block;
				padding-top: 100%;
				width: 100%;
				position: relative;
				float: left;
			}

			.imagen_interna {
				height: 100%;
				width: 100%;
				position: absolute;
				top: 0;
				left: 0;

				&:before {
					content: '';
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
				}
			}
		}

		.rectangulo_morado {
			width: 190px;
			height: 76px;
			position: absolute;
			right: 0;
			top: 128px;
			background-color: #941b7e;
			z-index: 1;
		}

		.imagen_grande {
			position: relative;
			width: 100%;

			//padding-top: 56.25;

			margin-bottom: 4px;

			&:after {
				//padding-top: 100%;
				padding-top: 56.25%;
				/* 16:9 ratio */
				display: block;
				content: '';
			}
		}
		.fila {
			display: flex;
		}
		.imagen_mediana {
			position: relative;
			width: calc(50% - 2px);

			//padding-top: 56.25;

			margin-bottom: 4px;

			&:after {
				//padding-top: 100%;
				padding-top: 56.25%;
				/* 16:9 ratio */
				display: block;
				content: '';
			}
		}

		.imagen_pequena {
			position: relative;
			width: calc(33.333% - 3px);

			//padding-top: 56.25;

			margin-bottom: 4px;

			&:after {
				//padding-top: 100%;
				padding-top: 56.25%;
				/* 16:9 ratio */
				display: block;
				content: '';
			}
		}
		.imagen_grande,
		.imagen_mediana,
		.imagen_pequena {
			.main {
				position: absolute;
				top: 0;
				bottom: 0;
				right: 0;
				left: 0;

				> * {
					width: 100%;
					height: 100%;
				}

				.imagen {
					background-color: #fff;
					background-size: cover;
					background-position: 50% 50%;
				}
			}
		}
	}

	.seccion_3 {
		padding-top: 5px;

		@media (min-width: 414px) {
			padding-top: 5px * 1.29;
		}

		@media (min-width: 768px) {
			padding-top: 5px * 2.4;
		}

		@media (min-width: 992px) {
			display: none;
		}

		.imagen {
			//height: 32vw;
			height: 50vw;
			background-size: cover;
			background-position: 50% 50%;
			margin-bottom: 5px;
			padding-left: 0;
			padding-right: 0;

			transition: background-size 0.5s ease-in;

			@media (min-width: 414px) {
				margin-bottom: 5px * 1.29;
			}

			@media (min-width: 768px) {
				margin-bottom: 5px * 2.4;
			}

			.imagen_interna {
				height: 100%;
				width: 100%;
				display: flex;
				justify-content: center;
				align-items: center;
				flex-direction: column;
				position: relative;
			}

			.overlay_1 {
				z-index: 3;
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				//background-color: #3F3F3D ;
				background: rgb(0, 0, 0);
				background: -moz-linear-gradient(
					0deg,
					rgba(0, 0, 0, 1) 0%,
					rgba(0, 0, 0, 0) 100%
				);
				background: -webkit-linear-gradient(
					0deg,
					rgba(0, 0, 0, 1) 0%,
					rgba(0, 0, 0, 0) 100%
				);
				background: linear-gradient(
					0deg,
					rgba(0, 0, 0, 1) 0%,
					rgba(0, 0, 0, 0) 100%
				);
				filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000",endColorstr="#000000",GradientType=1);
				opacity: 0.7;
			}

			.titulo {
				font-family: $fonts-Gotham-Black;
				color: #fff;
				font-size: 19.3px;
				margin-bottom: 2px;
				position: relative;
				z-index: 5;

				@media (min-width: 414px) {
					// * 1.29;
					font-size: 19.3px * 1.29;
					margin-bottom: 2px * 1.29;
				}

				@media (min-width: 768px) {
					// * 2.4;
					font-size: 19.3px * 2.4;
					margin-bottom: 2px * 2.4;
				}
			}

			.boton-movile {
				display: block;
				width: 68px;
				height: 29px;
				background-image: url(../img/work/boton-movile.svg);
				background-size: 100% auto;
				background-repeat: no-repeat;
				position: relative;
				z-index: 5;

				@media (min-width: 414px) {
					// * 1.29;
					width: 68px * 1.29;
					height: 29px * 1.29;
				}

				@media (min-width: 768px) {
					// * 2.4;
					width: 68px * 2.4;
					height: 29px * 2.4;
				}
			}
		}
	}

	#footer_work {
		//solo visible en pagina pequeñas

		background-color: #941b7e;
		width: 100%;
		height: 25px;
		margin-top: 84px;

		@media (min-width: 414px) {
			// * 1.29;
			height: 25px * 1.29;
			margin-top: 84px * 1.29;
		}

		@media (min-width: 768px) {
			// * 2.4;
			height: 25px * 2.4;
			margin-top: 84px * 2.4;
		}

		@media (min-width: (1200px * 0.827)) {
			//*0.827;
			display: none;
		}
	}
}
