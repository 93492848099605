/*
	Estilos del menu
*/
.esquina_derecha {
	//background-color: rgba(0,255,0,0.3);
	background-image: url(../img/home/esquina_derecha.svg);
	background-position: 0% 100%;
	background-repeat: no-repeat;
	top: 0;
	right: 0;
	position: absolute;
	z-index: -1;

	width: 62.5%; //803px;
	height: 800px; // 62.5vw;//
	background-size: 1371px auto; // 171% auto; //

	@media (min-width: ( 1200px*0.827)) {
		//width: 803px*0.827;
		height: 800px * 0.827;
		background-size: 1371px * 0.827 auto;
	}

	@media (min-width: 1200px) {
		//width: 803px;
		height: 800px;
		background-size: 1371px auto;
	}

	@media (min-width: (1200px*1.6)) {
		//width: 803px*1.6;
		height: 800px * 1.6;
		background-size: 1371px * 1.6 auto;
	}
	@media (min-width: (1200px*2)) {
		//width: 803px*2;
		height: 800px * 2;
		background-size: 1371px * 2 auto;
	}
}

nav.navbar {
	padding: 0;
	//overflow-x: clip;
	//border-bottom: 1px solid #000;

	.container {
		//background-color: rgba(255, 0, 0, 0.4);
		padding: 0;
		padding-top: 20px;
		padding-bottom: 20px;
		position: relative;

		@media (min-width: 414px) {
			padding-top: 20px * 1.29;
			padding-bottom: 20px * 1.29;
		}

		@media (min-width: 768px) {
			padding-top: 20px * 2.4;
			padding-bottom: 20px * 2.4;
		}

		@media (min-width: ( 1200px*0.827)) {
			padding-top: 38px * 0.827;
			padding-bottom: 38px * 0.827;
		}

		@media (min-width: 1200px) {
			padding-top: 38px;
			padding-bottom: 38px;
		}

		@media (min-width: (1200px*1.6)) {
			padding-top: 38px * 1.6;
			padding-bottom: 38px * 1.6;
		}
		@media (min-width: (1200px*2)) {
			padding-top: 38px * 2;
			padding-bottom: 38px * 2;
		}
	}
	.navbar-brand {
		padding: 0;

		margin-left: 20px;
		font-size: 13px;

		@media (min-width: 414px) {
			margin-left: 20px * 1.29;
		}

		@media (min-width: 768px) {
			margin-left: 20px * 2.4;
		}
		@media (min-width: ( 1200px*0.827)) {
			margin-left: 0;
		}

		img {
			height: 25.7px;

			width: auto;

			@media (min-width: 414px) {
				height: 25.7px * 1.29;
			}

			@media (min-width: 768px) {
				height: 25.7px * 2.4;
			}

			@media (min-width: ( 1200px*0.827)) {
				height: 56px * 0.827;
			}

			@media (min-width: 1200px) {
				height: 56px;
			}

			@media (min-width: (1200px*1.6)) {
				height: 56px * 1.6;
			}
			@media (min-width: (1200px*2)) {
				height: 56px * 2;
			}
		}
	}

	#icon-menu {
		cursor: pointer;
		z-index: 20;

		width: 22px;
		height: 16px;
		padding: 0px;
		margin-right: 20px;

		@media (min-width: 414px) {
			width: 22px * 1.29;
			height: 16px * 1.29;
			margin-right: 20px * 1.29;
		}

		@media (min-width: 768px) {
			width: 22px * 2.4;
			height: 16px * 2.4;
			margin-right: 20px * 2.4;
		}

		@media (min-width: ( 1200px*0.827)) {
			width: 46px * 0.827;
			height: 46px * 0.827;
			padding: 4px * 0.827;
			margin-right: 0;
		}

		@media (min-width: 1200px) {
			width: 46px;
			height: 46px;
			padding: 4px;
		}

		@media (min-width: (1200px*1.6)) {
			width: 46px * 1.6;
			height: 46px * 1.6;
			padding: 4px * 1.6;
		}
		@media (min-width: (1200px*2)) {
			width: 46px * 2;
			height: 46px * 2;
			padding: 4px * 2;
		}
		.cls-1 {
			opacity: 1;
			transition: opacity, stroke 0.2s ease;
		}

		&.active {
			.cls-none {
				opacity: 0;
			}
			.cls-1 {
				stroke: #f1efda;
			}
		}
	}
}
//trasnparencia del cuadro superior del menu en desktop perp solo en home

.menu_principal {
	top: 0;
	position: absolute;
	z-index: 15;
	background-color: #6aaee0;
	outline: 0;
	transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;
	//opacity: 0;
	//transition: transform 0.5s ease-in-out, opacity 0s ease-in-out;
	display: none;
	//transform: translateY(-100%);

	//transform: translateY(-100%);

	right: 0px;
	width: 290px;
	//height: 676px; //Original
	height: 480px;
	padding: 110px 70px 0 38px;

	@media (min-width: 414px) {
		// * 1.29;
		width: 290px * 1.29;
		//height: 480px * 1.29;
	}

	@media (min-width: 768px) {
		// * 2.4;
		//width: 290px * 2.4;
		//height: 480px * 2.4;
	}

	@media (min-width: ( 1200px*0.827)) {
		//*0.827;
		right: -16px;
		width: 380px * 0.827;
		height: 500px * 0.827;
		padding: 110px * 0.827 70px * 0.827 0 38px * 0.827;
	}
	@media (min-width: 1024px) {
		//right: -70px*0.827;
		right: -32px;
	}
	@media (min-width: 1200px) {
		//right: calc((100vw - 1140px)/2 * -1);
		right: -30px;
		width: 380px;
		height: 500px;
		padding: 110px 70px 0 38px;
	}
	@media (min-width: 1280px) {
		right: -70px;
	}
	@media (min-width: (1200px*1.6)) {
		//*1.6;
		right: -30px * 1.6;
		width: 380px * 1.6;
		height: 500px * 1.6;
		padding: 110px * 1.6 70px * 1.6 0 38px * 1.6;
	}
	@media (min-width: (1280px*1.6)) {
		right: -70px * 1.6;
	}
	@media (min-width: (1200px*2)) {
		//*2;
		right: -30px * 2;
		width: 380px * 2;
		height: 500px * 2;
		padding: 110px * 2 70px * 2 0 38px * 2;
	}
	@media (min-width: (1280px*2)) {
		right: -70px * 2;
	}

	&.active {
		//visibility: visible;
		//opacity: 1;
		//transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;
		//transform: translateY(0%);
		display: block;
		//transform: translateY(0);
	}

	ul {
		padding-left: 0;

		li {
			list-style: none;
			padding-left: 0;
			display: block;
			font-family: $fonts-Gotham-Book;
			padding-top: 8px;
			padding-bottom: 8px;

			@media (min-width: ( 1200px*0.827)) {
				//*0.827;
				padding-top: 8px * 0.827;
				padding-bottom: 8px * 0.827;
			}
			@media (min-width: 1200px) {
				padding-top: 8px;
				padding-bottom: 8px;
			}
			@media (min-width: (1200px*1.6)) {
				//*1.6;
				padding-top: 8px * 1.6;
				padding-bottom: 8px * 1.6;
			}
			@media (min-width: (1200px*2)) {
				//*2;
				padding-top: 8px * 2;
				padding-bottom: 8px * 2;
			}

			.logo {
				height: 35px;

				@media (min-width: ( 1200px*0.827)) {
					//*0.827;
					height: 35px * 0.827;
				}
				@media (min-width: 1200px) {
					height: 35px;
				}
				@media (min-width: (1200px*1.6)) {
					//*1.6;
					height: 35px * 1.6;
				}
				@media (min-width: (1200px*2)) {
					//*2;
					height: 35px * 2;
				}
			}

			a {
				color: #f1efda;
				text-decoration: none;
				display: block;
				font-size: 15px;

				@media (min-width: ( 1200px*0.827)) {
					//*0.827;
					font-size: 15px * 0.827;
				}
				@media (min-width: 1200px) {
					font-size: 15px;
				}
				@media (min-width: (1200px*1.6)) {
					//*1.6;
					font-size: 15px * 1.6;
				}
				@media (min-width: (1200px*2)) {
					//*2;
					font-size: 15px * 2;
				}

				&.active,
				&:hover {
					font-family: $fonts-Gotham-Bold;
				}
			}

			&.submenu {
				ul {
					transform: scaleY(0);
					transform-origin: 0 0;
					transition: transform 0.4s 0s ease,
						max-height 0.1s 0.3s ease;
					max-height: 0;
					padding-left: 15px;

					@media (min-width: ( 1200px*0.827)) {
						//*0.827;
						padding-left: 15px * 0.827;
					}
					@media (min-width: 1200px) {
						padding-left: 15px;
					}
					@media (min-width: (1200px*1.6)) {
						//*1.6;
						padding-left: 15px * 1.6;
					}
					@media (min-width: (1200px*2)) {
						//*2;
						padding-left: 15px * 2;
					}

					li {
						padding-top: 3px;
						padding-bottom: 3px;

						@media (min-width: ( 1200px*0.827)) {
							//*0.827;
							padding-top: 3px * 0.827;
							padding-bottom: 3px * 0.827;
						}
						@media (min-width: 1200px) {
							padding-top: 3px;
							padding-bottom: 3px;
						}
						@media (min-width: (1200px*1.6)) {
							//*1.6;
							padding-top: 3px * 1.6;
							padding-bottom: 3px * 1.6;
						}
						@media (min-width: (1200px*2)) {
							//*2;
							padding-top: 3px * 2;
							padding-bottom: 3px * 2;
						}

						a {
							font-size: 12px;
							letter-spacing: -0.035em;

							@media (min-width: ( 1200px*0.827)) {
								//*0.827;
								font-size: 12px * 0.827;
							}
							@media (min-width: 1200px) {
								font-size: 12px;
							}
							@media (min-width: (1200px*1.6)) {
								//*1.6;
								font-size: 12px * 1.6;
							}
							@media (min-width: (1200px*2)) {
								//*2;
								font-size: 12px * 2;
							}
						}
					}
				}
				&.open {
					ul {
						max-height: 100px;
						transform: scaleY(100%);
						transition: transform 0.4s 0.3s ease,
							max-height 0.3s 0.1s ease;

						@media (min-width: ( 1200px*0.827)) {
							//*0.827;
							max-height: 100px * 0.827;
						}
						@media (min-width: 1200px) {
							max-height: 100px;
						}
						@media (min-width: (1200px*1.6)) {
							//*1.6;
							max-height: 100px * 1.6;
						}
						@media (min-width: (1200px*2)) {
							//*2;
							max-height: 100px * 2;
						}
					}
				}
			}
		}
	}
	.rrss {
		width: 100%;
		position: absolute;
		//background-color: red ;
		left: 0;
		bottom: 170px;
		padding-right: 73px;

		@media (min-width: ( 1200px*0.827)) {
			//*0.827;
			bottom: 170px * 0.827;
			padding-right: 73px * 0.827;
		}
		@media (min-width: 1200px) {
			bottom: 170px;
			padding-right: 73px;
		}
		@media (min-width: (1200px*1.6)) {
			//*1.6;
			bottom: 170px * 1.6;
			padding-right: 73px * 1.6;
		}
		@media (min-width: (1200px*2)) {
			//*2;
			bottom: 170px * 2;
			padding-right: 73px * 2;
		}

		.lenguaje {
			text-align: right;
			font-family: $fonts-Gotham-Book;
			color: #f1efda;
			font-size: 12px;
			margin-bottom: 8px;

			@media (min-width: ( 1200px*0.827)) {
				//*0.827;
				font-size: 12px * 0.827;
				margin-bottom: 8px * 0.827;
			}
			@media (min-width: 1200px) {
				font-size: 12px;
				margin-bottom: 8px;
			}
			@media (min-width: (1200px*1.6)) {
				//*1.6;
				font-size: 12px * 1.6;
				margin-bottom: 8px * 1.6;
			}
			@media (min-width: (1200px*2)) {
				//*2;
				font-size: 12px * 2;
				margin-bottom: 8px * 2;
			}

			a {
				font-family: $fonts-Gotham-Book;
				color: #f1efda;
				text-decoration: none;
				font-size: 12px;

				@media (min-width: ( 1200px*0.827)) {
					//*0.827;
					font-size: 12px * 0.827;
				}
				@media (min-width: 1200px) {
					font-size: 12px;
				}
				@media (min-width: (1200px*1.6)) {
					//*1.6;
					font-size: 12px * 1.6;
				}
				@media (min-width: (1200px*2)) {
					//*2;
					font-size: 12px * 2;
				}

				&.active {
					font-family: $fonts-Gotham-Bold;
				}
			}
		}

		.iconos {
			display: flex;
			flex-direction: row;
			justify-content: flex-end;
			.icono {
				margin-left: 6px;

				@media (min-width: ( 1200px*0.827)) {
					//*0.827;
					margin-left: 6px * 0.827;
				}
				@media (min-width: 1200px) {
					margin-left: 6px;
				}
				@media (min-width: (1200px*1.6)) {
					//*1.6;
					margin-left: 6px * 1.6;
				}
				@media (min-width: (1200px*2)) {
					//*2;
					margin-left: 6px * 2;
				}

				img {
					height: 22.85px;

					@media (min-width: ( 1200px*0.827)) {
						//*0.827;
						height: 22.85px * 0.827;
					}
					@media (min-width: 1200px) {
						height: 22.85px;
					}
					@media (min-width: (1200px*1.6)) {
						//*1.6;
						height: 22.85px * 1.6;
					}
					@media (min-width: (1200px*2)) {
						//*2;
						height: 22.85px * 2;
					}
				}
			}
		}
	}
	.cuadrito {
		background-color: #f1efda;
		position: absolute;
		width: 7.6px;
		height: 7.6px;
		//bottom: 237px; original
		bottom: 200px;
		left: 38px;

		@media (min-width: ( 1200px*0.827)) {
			//*0.827;
			width: 7.6px * 0.827;
			height: 7.6px * 0.827;
			bottom: 200px * 0.827;
			left: 38px * 0.827;
		}
		@media (min-width: 1200px) {
			width: 7.6px;
			height: 7.6px;
			bottom: 200px;
			left: 38px;
		}
		@media (min-width: (1200px*1.6)) {
			//*1.6;
			width: 7.6px * 1.6;
			height: 7.6px * 1.6;
			bottom: 200px * 1.6;
			left: 38px * 1.6;
		}
		@media (min-width: (1200px*2)) {
			//*2;
			width: 7.6px * 2;
			height: 7.6px * 2;
			bottom: 200px * 2;
			left: 38px * 2;
		}
	}
	.leyenda {
		position: absolute;
		font-family: $fonts-Gotham-Bold;
		color: #f1efda;
		line-height: 1;
		z-index: 1;
		letter-spacing: 0.05em;
		bottom: 91px;
		left: -30px;
		font-size: 38px;

		@media (min-width: 414px) {
			// * 1.29;
		}

		@media (min-width: 768px) {
			// * 2.4;
		}

		@media (min-width: ( 1200px*0.827)) {
			//*0.827;
			bottom: 91px * 0.827;
			left: -60px * 0.827;
			font-size: 45px * 0.827;
		}
		@media (min-width: 1200px) {
			bottom: 91px;
			left: -60px;
			font-size: 45px;
		}
		@media (min-width: (1200px*1.6)) {
			//*1.6;
			bottom: 91px * 1.6;
			left: -60px * 1.6;
			font-size: 45px * 1.6;
		}
		@media (min-width: (1200px*2)) {
			//*2;
			bottom: 91px * 2;
			left: -60px * 2;
			font-size: 45px * 2;
		}
	}

	.img-bottom {
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		background-image: url(../img/navbar/img-bottom.webp);
		background-size: cover;
		background-position: 50% 50%;
		height: 114px;

		@media (min-width: ( 1200px*0.827)) {
			//*0.827;
			height: 114px * 0.827;
		}
		@media (min-width: 1200px) {
			height: 114px;
		}
		@media (min-width: (1200px*1.6)) {
			//*1.6;
			height: 114px * 1.6;
		}
		@media (min-width: (1200px*2)) {
			//*2;
			height: 114px * 2;
		}
	}
} //Termina menu principal

#overlay_body {
	display: none;

	&.active {
		display: block;
		position: fixed;
		z-index: 12;
		background-color: #3f3f3d;
		opacity: 0.5;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
	}
}
