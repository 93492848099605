/*#jarallax-container-0{
    display: none;
}

@media (min-width: 768px) {

    #jarallax-container-0{
        display: block;
    }
    #jarallax-container-0 > div{
        background-color: rgba(0,0,0,.3);
        background-blend-mode: multiply;
    }
}*/

.jarallax,
.jarallax #jarallax-container-0 *{
	@media( max-width: 767px){
		position: relative;
		z-index: 0;
		transition: height .5s;
	}
}

.jarallax > .jarallax-img {
    position: absolute;
    object-fit: cover;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
}