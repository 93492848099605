.home {
	.seccion_1 {
		position: relative;

		@media (max-width: 991px) {
			width: 100vw;
			overflow: hidden;
		}

		padding-bottom: 40px;

		@media (min-width: 414px) {
			padding-bottom: 40px * 1.29;
		}

		@media (min-width: 768px) {
			padding-bottom: 40px * 2.4;
		}

		@media (min-width: 992px) {
			padding-bottom: 0px;
		}

		.container {
			position: relative;

			> .row {
				@media (max-width: 991px) {
					position: relative;
				}
			}

			.cuadrito_inicial {
				@media (min-width: 992px) {
					//*0.827;
					z-index: 11;
					position: absolute;
					width: 19px * 0.827;
					height: 19px * 0.827;
					top: 610px * 0.827;
					right: 5px * 0.827;
				}
				@media (min-width: 1200px) {
					width: 19px;
					height: 19px;
					top: 610px;
					right: 5px;
				}
				@media (min-width: (1200px*1.6)) {
					//*1.6;
					width: 19px * 1.6;
					height: 19px * 1.6;
					top: 610px * 1.6;
					right: 5px * 1.6;
				}
				@media (min-width: (1200px*2)) {
					//*2;
					width: 19px * 2;
					height: 19px * 2;
					top: 610px * 2;
					right: 5px * 2;
				}
			}
		}

		.esquina_izquierda {
			//background-color: rgba(0, 0, 255, 0.3);
			background-image: url(../img/home/esquina_izquierda.svg);
			background-position: 0% 100%;
			background-repeat: no-repeat;
			position: absolute;

			width: 166px;
			height: 166px;
			top: 208px;
			left: -130px;

			@media (min-width: 414px) {
				// * 1.29;
				width: 166px * 1.29;
				height: 166px * 1.29;
				top: 208px * 1.29;
				left: -130px * 1.29;
			}

			@media (min-width: 768px) {
				// * 2.4;
				width: 166px * 2.4;
				height: 166px * 2.4;
				top: 208px * 2.4;
				left: -130px * 2.4;
			}

			@media (min-width: 992px) {
				//*0.827;
				//width: 410px * 0.827;

				width: 640px * 0.827;
				height: 640px * 0.827;
				top: 200px * 0.827;
				left: -496px * 0.827;
			}
			@media (min-width: 1200px) {
				//width: 410px;
				width: 640px;
				height: 640px;
				top: 200px;
				left: -496px;
			}

			@media (min-width: (1200px*1.6)) {
				//*1.6;
				width: 640px * 1.6;
				height: 640px * 1.6;
				top: 200px * 1.6;
				left: -496px * 1.6;
			}
			@media (min-width: (1200px*2)) {
				//*2;
				width: 410px * 2;
				width: 640px * 2;
				height: 640px * 2;
				top: 200px * 2;
				left: -496px * 2;
			}
		}

		.contenedor_carrousel_1 {
			padding-left: 0;
		}
		.carrousel_1 {
			position: relative;
			width: 100%;
			margin-top: 18px;
			height: 320px;

			@media (min-width: 414px) {
				// * 1.29;
				margin-top: 18px * 1.29;
				height: 320px * 1.29;
			}

			@media (min-width: 768px) {
				// * 2.4;
				margin-top: 18px * 2.4;
				height: 320px * 2.4;
			}

			@media (min-width: 992px) {
				//*0.827;
				margin-top: 138px * 0.827;
				width: 477px * 0.827;
				height: 280px * 0.827;
			}
			@media (min-width: 1200px) {
				margin-top: 138px;
				width: 477px;
				height: 280px;
			}
			@media (min-width: (1200px*1.6)) {
				//*1.6;
				margin-top: 138px * 1.6;
				width: 477px * 1.6;
				height: 280px * 1.6;
			}
			@media (min-width: (1200px*2)) {
				//*2;
				margin-top: 138px * 2;
				width: 477px * 2;
				height: 280px * 2;
			}

			.linea {
				position: absolute;
				top: 0;
				height: 2px;
				width: 10px;
				left: 20px;

				@media (min-width: 414px) {
					// * 1.29;
					height: 2px * 1.29;
					width: 10px * 1.29;
					left: 20px * 1.29;
				}

				@media (min-width: 768px) {
					// * 2.4;
					height: 2px * 2.4;
					width: 10px * 2.4;
					left: 20px * 2.4;
				}

				@media (min-width: 992px) {
					//*0.827;
					height: 10px * 0.827;
					width: 30px * 0.827;
					left: 82px * 0.827;
				}
				@media (min-width: 1200px) {
					height: 10px;
					width: 30px;
					left: 82px;
				}
				@media (min-width: (1200px*1.6)) {
					//*1.6;
					height: 10px * 1.6;
					width: 30px * 1.6;
					left: 82px * 1.6;
				}
				@media (min-width: (1200px*2)) {
					//*2;
					height: 10px * 2;
					width: 30px * 2;
					left: 82px * 2;
				}
			}

			.opciones {
				position: absolute;
				left: 0;
				right: 0;
				bottom: 0;
				width: 90px;
				height: 6px;
				margin-left: auto;
				margin-right: auto;

				@media (min-width: 414px) {
					// * 1.29;
					width: 90px * 1.29;
					height: 6px * 1.29;
				}

				@media (min-width: 768px) {
					// * 2.4;
					width: 90px * 2.4;
					height: 6px * 2.4;
				}

				@media (max-width: 991px) {
					display: flex;
					align-content: center;
					justify-content: space-between;
					//*0.827;
				}
				//background-color: rgba(255,0,0,0.3);

				@media (min-width: 992px) {
					//*0.827;
					left: 6px * 0.827;
					top: 86px * 0.827;
					width: 9px * 0.827;
					height: 125px * 0.827;
					right: initial;
					bottom: initial;
					margin-left: initial;
					margin-right: initial;
				}
				@media (min-width: 1200px) {
					left: 6px;
					top: 86px;
					width: 9px;
					height: 125px;
				}
				@media (min-width: (1200px*1.6)) {
					//*1.6;
					left: 6px * 1.6;
					top: 86px * 1.6;
					width: 9px * 1.6;
					height: 125px * 1.6;
				}
				@media (min-width: (1200px*2)) {
					//*2;
					left: 6px * 2;
					top: 86px * 2;
					width: 9px * 2;
					height: 125px * 2;
				}

				.opcion {
					cursor: pointer;
					border-radius: 50%;

					border: 1px solid #212120;
					width: 6px;
					height: 6px;

					@media (min-width: 414px) {
						// * 1.29;
						border: 1px solid #212120;
						width: 6px * 1.29;
						height: 6px * 1.29;
					}

					@media (min-width: 768px) {
						// * 2.4;
						border: 2px solid #212120;
						width: 6px * 2.4;
						height: 6px * 2.4;
					}

					@media (min-width: 992px) {
						//*0.827;
						margin-bottom: 20px * 0.827;
						border: 1px solid #212120;
						width: 9px * 0.827;
						height: 9px * 0.827;
					}
					@media (min-width: 1200px) {
						margin-bottom: 20px;
						border: 1px solid #212120;
						width: 9px;
						height: 9px;
					}
					@media (min-width: (1200px*1.6)) {
						//*1.6;
						margin-bottom: 20px * 1.6;
						border: 1px * 1.6 solid #212120;
						width: 9px * 1.6;
						height: 9px * 1.6;
					}
					@media (min-width: (1200px*2)) {
						//*2;
						margin-bottom: 20px * 2;
						border: 1px * 2 solid #212120;
						width: 9px * 2;
						height: 9px * 2;
					}

					&.active {
						background-color: #9e1b7e;
						border-color: #9e1b7e;
					}
				}
			}

			.contenedor_texto {
				position: absolute;
				line-height: 1;

				//background-color: rgba(0,0,255,0.3);
				left: 25px; //left: 40px;
				top: 62px;

				@media (min-width: 414px) {
					left: 25px * 1.29;
					top: 62px * 1.29;
				}

				@media (min-width: 768px) {
					left: 25px * 2.4;
					top: 62px * 2.4;
				}

				@media (min-width: 992px) {
					//*0.827;
					left: 82px * 0.827;
					width: 395px * 0.827;
					height: 249px * 0.827;
					bottom: 0;
					top: initial;
				}
				@media (min-width: 1200px) {
					left: 82px;
					width: 395px;
					height: 249px;
				}
				@media (min-width: (1200px*1.6)) {
					//*1.6;
					left: 82px * 1.6;
					width: 395px * 1.6;
					height: 249px * 1.6;
				}
				@media (min-width: (1200px*2)) {
					//*2;
					left: 82px * 2;
					width: 395px * 2;
					height: 249px * 2;
				}

				.linea_1 {
					font-family: $fonts-Gotham-Medium;
					color: #3f3f3d;
					font-size: 20px;
					line-height: 1.2;
					//margin-bottom: 2px;

					@media (min-width: 414px) {
						font-size: 20px * 1.29;
					}

					@media (min-width: 768px) {
						font-size: 20px * 2.4;
					}

					@media (min-width: 992px) {
						line-height: 1;
						letter-spacing: -0.006em;
						//*0.827;
						height: 50px * 0.827;
						font-size: 45px * 0.827;
						margin-bottom: 2px * 0.827;
					}
					@media (min-width: 1200px) {
						height: 50px;
						font-size: 45px;
						margin-bottom: 2px;
					}
					@media (min-width: (1200px*1.6)) {
						//*1.6;
						height: 50px * 1.6;
						font-size: 45px * 1.6;
						margin-bottom: 2px * 1.6;
					}
					@media (min-width: (1200px*2)) {
						//*2;
						height: 50px * 2;
						font-size: 45px * 2;
						margin-bottom: 2px * 2;
					}
				}
				.linea_2 {
					font-family: $fonts-Gotham-Black;
					color: #3f3f3d;
					font-size: 20px;
					line-height: 1.2;

					@media (min-width: 414px) {
						font-size: 20px * 1.29;
					}

					@media (min-width: 768px) {
						font-size: 20px * 2.4;
					}
					@media (min-width: 992px) {
						line-height: 1;
					}
				}
				.linea_3 {
					font-family: $fonts-Gotham-Black;
					color: #3f3f3d;
					font-size: 20px;
					line-height: 1.2;

					@media (min-width: 414px) {
						font-size: 20px * 1.29;
					}

					@media (min-width: 768px) {
						font-size: 20px * 2.4;
					}
					@media (min-width: 992px) {
						line-height: 1;
					}
				}

				&[data-opcion='1'] {
					@media (min-width: 992px) {
						//*0.827;
						width: 365px * 0.827;
					}
					@media (min-width: 1200px) {
						width: 365px;
					}
					@media (min-width: (1200px*1.6)) {
						//*1.6;
						width: 365px * 1.6;
					}
					@media (min-width: (1200px*2)) {
						//*2;
						width: 365px * 2;
					}

					.linea_2 {
						//margin-bottom: 5px;

						@media (min-width: 992px) {
							//*0.827;
							text-align: left;
							font-size: 60px * 0.827;
							margin-bottom: 5px * 0.827;
						}
						@media (min-width: 1200px) {
							font-size: 60px;
							margin-bottom: 5px;
						}
						@media (min-width: (1200px*1.6)) {
							//*1.6;
							font-size: 60px * 1.6;
							margin-bottom: 5px * 1.6;
						}
						@media (min-width: (1200px*2)) {
							//*2;
							font-size: 60px * 2;
							margin-bottom: 5px * 2;
						}
					}
					.linea_3 {
						@media (min-width: 992px) {
							//*0.827;
							font-size: 60px * 0.827;
							margin-bottom: 31px * 0.827;
							text-align: right;
						}
						@media (min-width: 1200px) {
							font-size: 60px;
							margin-bottom: 31px;
						}
						@media (min-width: (1200px*1.6)) {
							//*1.6;
							font-size: 60px * 1.6;
							margin-bottom: 31px * 1.6;
						}
						@media (min-width: (1200px*2)) {
							//*2;
							font-size: 60px * 2;
							margin-bottom: 31px * 2;
						}
					}
				} //Termina opcion 1

				&[data-opcion='2'] {
					@media (min-width: 992px) {
						//*0.827;
						width: 282px * 0.827;
					}
					@media (min-width: 1200px) {
						width: 282px;
					}
					@media (min-width: (1200px*1.6)) {
						//*1.6;
						width: 282px * 1.6;
					}
					@media (min-width: (1200px*2)) {
						//*2;
						width: 282px * 2;
					}

					.linea_3 {
						@media (min-width: 992px) {
							//*0.827;
							text-align: right;
							font-size: 60.95px * 0.827;
							margin-bottom: 39px * 0.827;
						}
						@media (min-width: 1200px) {
							font-size: 60.95px;
							margin-bottom: 39px;
						}
						@media (min-width: (1200px*1.6)) {
							//*1.6;
							font-size: 60.95px * 1.6;
							margin-bottom: 39px * 1.6;
						}
						@media (min-width: (1200px*2)) {
							//*2;
							font-size: 60.95px * 2;
							margin-bottom: 39px * 2;
						}
					}
				} //Termina opcion 2
				&[data-opcion='3'] {
					@media (min-width: 992px) {
						//*0.827;
						width: 366px * 0.827;
					}
					@media (min-width: 1200px) {
						width: 366px;
					}
					@media (min-width: (1200px*1.6)) {
						//*1.6;
						width: 366px * 1.6;
					}
					@media (min-width: (1200px*2)) {
						//*2;
						width: 366px * 2;
					}

					.linea_2 {
						@media (min-width: 992px) {
							//*0.827;
							text-align: center;
							font-size: 53.33px * 0.827;
							margin-bottom: 5px * 0.827;
						}
						@media (min-width: 1200px) {
							font-size: 53.33px;
							margin-bottom: 5px;
						}
						@media (min-width: (1200px*1.6)) {
							//*1.6;
							font-size: 53.33px * 1.6;
							margin-bottom: 5px * 1.6;
						}
						@media (min-width: (1200px*2)) {
							//*2;
							font-size: 53.33px * 2;
							margin-bottom: 5px * 2;
						}
					}
					.linea_3 {
						@media (min-width: 992px) {
							//*0.827;
							text-align: right;
							letter-spacing: -0.025em;
							font-size: 60.95px * 0.827;
							margin-bottom: 31px * 0.827;
						}
						@media (min-width: 1200px) {
							font-size: 60.95px;
							margin-bottom: 31px;
						}
						@media (min-width: (1200px*1.6)) {
							//*1.6;
							font-size: 60.95px * 1.6;
							margin-bottom: 31px * 1.6;
						}
						@media (min-width: (1200px*2)) {
							//*2;
							font-size: 60.95px * 2;
							margin-bottom: 31px * 2;
						}
					}
				} //Termina opcion 3
				&[data-opcion='4'] {
					@media (min-width: 992px) {
						//*0.827;
						width: 390px * 0.827;
					}
					@media (min-width: 1200px) {
						width: 390px;
					}
					@media (min-width: (1200px*1.6)) {
						//*1.6;
						width: 390px * 1.6;
					}
					@media (min-width: (1200px*2)) {
						//*2;
						width: 390px * 2;
					}

					.linea_2 {
						@media (min-width: 992px) {
							text-align: left;
							//*0.827;
							margin-bottom: 5px * 0.827;
							font-size: 60.95px * 0.827;
							margin-left: 38px * 0.827;
						}
						@media (min-width: 1200px) {
							margin-bottom: 5px;
							font-size: 60.95px;
							margin-left: 38px;
						}
						@media (min-width: (1200px*1.6)) {
							//*1.6;
							margin-bottom: 5px * 1.6;
							font-size: 60.95px * 1.6;
							margin-left: 38px * 1.6;
						}
						@media (min-width: (1200px*2)) {
							//*2;
							margin-bottom: 5px * 2;
							font-size: 60.95px * 2;
							margin-left: 38px * 2;
						}
					}
					.linea_3 {
						@media (min-width: 992px) {
							text-align: right;
							letter-spacing: -0.025em;
							//*0.827;
							font-size: 60.95px * 0.827;
							margin-bottom: 31px * 0.827;
						}
						@media (min-width: 1200px) {
							font-size: 60.95px;
							margin-bottom: 31px;
						}
						@media (min-width: (1200px*1.6)) {
							//*1.6;
							font-size: 60.95px * 1.6;
							margin-bottom: 31px * 1.6;
						}
						@media (min-width: (1200px*2)) {
							//*2;
							font-size: 60.95px * 2;
							margin-bottom: 31px * 2;
						}
					}
				} //Termina opcion 4
				&[data-opcion='5'] {
					@media (min-width: 992px) {
						//*0.827;
						width: 276px * 0.827;
					}
					@media (min-width: 1200px) {
						width: 276px;
					}
					@media (min-width: (1200px*1.6)) {
						//*1.6;
						width: 276px * 1.6;
					}
					@media (min-width: (1200px*2)) {
						//*2;
						width: 276px * 2;
					}

					.linea_2 {
						@media (min-width: 992px) {
							//*0.827;
							text-align: left;
							font-size: 53.33px * 0.827;
							height: 48px * 0.827;
						}
						@media (min-width: 1200px) {
							font-size: 53.33px;
							height: 48px;
						}
						@media (min-width: (1200px*1.6)) {
							//*1.6;
							font-size: 53.33px * 1.6;
							height: 48px * 1.6;
						}
						@media (min-width: (1200px*2)) {
							//*2;
							font-size: 53.33px * 2;
							height: 48px * 2;
						}
					}
					.linea_3 {
						@media (min-width: 992px) {
							//*0.827;
							text-align: right;
							font-size: 60.95px * 0.827;
							margin-bottom: 31px * 0.827;
						}
						@media (min-width: 1200px) {
							font-size: 60.95px;
							margin-bottom: 31px;
						}
						@media (min-width: (1200px*1.6)) {
							//*1.6;
							font-size: 60.95px * 1.6;
							margin-bottom: 31px * 1.6;
						}
						@media (min-width: (1200px*2)) {
							//*2;
							font-size: 60.95px * 2;
							margin-bottom: 31px * 2;
						}
					}
				} //Termina opcion 5

				//boton
				.contenedor-boton {
					display: flex;
					align-items: center;
					//width: 50vw;
					//width: 90%;

					position: relative;
					//padding: 30% 2em;
					box-sizing: border-box;

					color: #fff;
					background: #fff;
					background-clip: padding-box; /* !importanté */

					width: 72px;
					height: 30px;
					$border: 1px;
					border-radius: 30px;
					margin-top: 18px;
					border: solid $border transparent; /* !importanté */

					@media (min-width: 414px) {
						// * 1.29;
						width: 72px * 1.29;
						height: 30px * 1.29;
						$border: 1px;
						border-radius: 30px * 1.29;
						margin-top: 18px * 1.29;
						border: solid $border transparent; /* !importanté */
					}

					@media (min-width: 768px) {
						// * 2.4;
						width: 72px * 2.4;
						height: 30px * 2.4;
						$border: 2px;
						border-radius: 30px * 2.4;
						margin-top: 18px * 2.4;
						border: solid $border transparent; /* !importanté */
					}

					@media (min-width: 992px) {
						//*0.827;
						width: 124px * 0.827;
						height: 37px * 0.827;
						$border: 1px;
						border-radius: 35px * 0.827;
						border: solid $border transparent; /* !importanté */
						margin: auto;
						float: right;
					}
					@media (min-width: 1200px) {
						width: 124px;
						height: 37px;
						$border: 2px;
						border-radius: 35px;
						border: solid $border transparent; /* !importanté */
					}
					@media (min-width: (1200px*1.6)) {
						//*1.6;
						width: 124px * 1.6;
						height: 37px * 1.6;
						$border: 3px;
						border-radius: 35px * 1.6;
						border: solid $border transparent; /* !importanté */
					}
					@media (min-width: (1200px*2)) {
						//*2;
						width: 124px * 2;
						height: 37px * 2;
						$border: 4;
						border-radius: 35px * 2;
						border: solid $border transparent; /* !importanté */
					}

					&:before {
						content: '';
						position: absolute;
						top: 0;
						right: 0;
						bottom: 0;
						left: 0;
						z-index: -1;
						$border: 1px;
						margin: -$border; /* !importanté */
						border-radius: inherit; /* !importanté */
						background: linear-gradient(
							90deg,
							rgba(106, 173, 223, 1) 0%,
							rgba(158, 27, 126, 1) 100%
						);

						@media (min-width: 992px) {
							//*0.827;
							$border: 1px;
							margin: -$border; /* !importanté */
						}
						@media (min-width: 1200px) {
							$border: 2px;
							margin: -$border; /* !importanté */
						}
						@media (min-width: (1200px*1.6)) {
							//*1.6;
							$border: 3px;
							margin: -$border; /* !importanté */
						}
						@media (min-width: (1200px*2)) {
							//*2;
							$border: 4px;
							margin: -$border; /* !importanté */
						}
					}

					.go {
						text-decoration: none;
						display: flex;
						justify-content: center;
						align-items: center;
						width: 100%;
						height: 100%;
						font-family: $fonts-Gotham-Medium;
						color: #3f3f3d;

						font-size: 16px;

						@media (min-width: 414px) {
							font-size: 16px * 1.29;
						}

						@media (min-width: 768px) {
							font-size: 16px * 2.4;
						}

						@media (min-width: 992px) {
							//*0.827;
							font-size: 23px * 0.827;
						}
						@media (min-width: 1200px) {
							font-size: 23px;
						}
						@media (min-width: (1200px*1.6)) {
							//*1.6;
							font-size: 23px * 1.6;
						}
						@media (min-width: (1200px*2)) {
							//*2;
							font-size: 23px * 2;
						}

						img {
							display: block;
							height: 12px;
							padding-top: 1px;
							margin-left: 5px;

							@media (min-width: 414px) {
								height: 12px * 1.29;
								margin-left: 5px * 1.29;
							}

							@media (min-width: 768px) {
								height: 12px * 2.4;
								margin-left: 5px * 2.4;
							}

							@media (min-width: 992px) {
								//*0.827;
								margin-left: 11px * 0.827;
								height: 18px * 0.827;
								padding-top: 1px * 0.827;
							}
							@media (min-width: 1200px) {
								margin-left: 11px;
								height: 18px;
								padding-top: 1px;
							}
							@media (min-width: (1200px*1.6)) {
								//*1.6;
								margin-left: 11px * 1.6;
								height: 18px * 1.6;
								padding-top: 1px * 1.6;
							}
							@media (min-width: (1200px*2)) {
								//*2;
								margin-left: 11px * 2;
								height: 18px * 2;
								padding-top: 1px * 2;
							}
						}
					}
				}
			}
		}

		.contenedor-imagen-principal {
			@media (max-width: 991px) {
				position: absolute;
				right: 0;
				margin: auto;
				z-index: -1;
				//background-color: rgba(255, 0, 0, 0.3);
				top: 19px;
				left: 115px; //left: 140px;
				width: 246px;
				height: 489px;
				padding: 0;

				@media (min-width: 414px) {
					// * 1.29;
					top: 19px * 1.29;
					left: 115px * 1.29;
					width: 246px * 1.29;
					height: 489px * 1.29;
				}
				@media (min-width: 768px) {
					// * 2.4;
					top: 19px * 2.4;
					left: 115px * 2.4;
					width: 246px * 2.4;
					height: 489px * 2.4;
				}
			}
		}
		.imagen-principal {
			background-image: url(../img/home/personajes/personaje_1.png);
			background-size: 100% auto;
			background-position: 0% 25%;
			background-repeat: no-repeat;
			width: 100%;
			height: 100%;

			@media (min-width: 992px) {
				//*0.827;
				//width: 595px*0.827;
				width: 100%;
				height: 665px * 0.827;
				margin-left: 10px * 0.827;
				background-position: 100% 0%;
			}
			@media (min-width: 1200px) {
				//width: 595px;
				height: 665px;
				margin-left: 10px;
			}
			@media (min-width: (1200px*1.6)) {
				//*1.6;
				//width: 595px*1.6;
				height: 665px * 1.6;
				margin-left: 10px * 1.6;
			}
			@media (min-width: (1200px*2)) {
				//*2;
				//width: 595px*2;
				height: 665px * 2;
				margin-left: 10px * 2;
			}
		}
	} //Termina seccion 1

	.cinta_1 {
		position: relative;
		.fondo_mobile {
			background-color: #941b7e;
			height: 200px;
			width: 100%;
			position: absolute;
			top: 0;
			left: 0;

			@media (min-width: 414px) {
				height: 200px * 1.29;
			}

			@media (min-width: 768px) {
				height: 200px * 2.4;
			}

			@media (min-width: 992px) {
				display: none;
			}
		}
		.contenedor-titulo {
			padding-right: 0;
		}
		.titulo {
			width: 100%;
			display: flex;
			align-items: center;
			justify-content: flex-start;
			//padding-right: 64px;
			color: #fff;
			font-family: $fonts-Gotham-Bold;
			flex-direction: row;
			flex-wrap: wrap;
			align-content: center;

			height: 100px;
			font-size: 20px;
			padding-left: 28px;

			z-index: 2;

			@media (min-width: 414px) {
				// * 1.29;
				height: 100px * 1.29;
				font-size: 20px * 1.29;
				padding-left: 28px * 1.29;
			}

			@media (min-width: 768px) {
				// * 2.4;
				height: 100px * 2.4;
				font-size: 20px * 2.4;
				padding-left: 28px * 2.4;
			}

			@media (min-width: 992px) {
				//*0.827;
				height: 267px * 0.827;
				font-size: 39px * 0.827;
				justify-content: flex-end;
				padding-left: initial;
				color: #3f3f3d;
			}
			@media (min-width: 1200px) {
				height: 267px;
				font-size: 39px;
			}
			@media (min-width: (1200px*1.6)) {
				//*1.6;
				height: 267px * 1.6;
				font-size: 39px * 1.6;
			}
			@media (min-width: (1200px*2)) {
				//*2;
				height: 267px * 2;
				font-size: 39px * 2;
			}

			.linea {
				width: 20px;
				height: 4px;
				margin-left: 110px;
				z-index: 2;

				@media (min-width: 414px) {
					// * 1.29;
					width: 20px * 1.29;
					height: 4px * 1.29;
					margin-left: 110px * 1.29;
				}

				@media (min-width: 768px) {
					// * 2.4;
					width: 20px * 2.4;
					height: 4px * 2.4;
					margin-left: 110px * 2.4;
				}

				@media (min-width: 992px) {
					//*0.827;
					width: 38px * 0.827;
					height: 8px * 0.827;
				}
				@media (min-width: 1200px) {
					width: 38px;
					height: 8px;
				}
				@media (min-width: (1200px*1.6)) {
					//*1.6;
					width: 38px * 1.6;
					height: 8px * 1.6;
				}
				@media (min-width: (1200px*2)) {
					//*2;
					width: 38px * 2;
					height: 8px * 2;
				}
			}

			.texto {
				text-align: left;
				width: 100%;
				margin-bottom: 0px;
				z-index: 2;

				@media (min-width: 992px) {
					text-align: right;
					//*0.827;
					margin-bottom: 2px * 0.827;
				}
				@media (min-width: 1200px) {
					margin-bottom: 2px;
				}
				@media (min-width: (1200px*1.6)) {
					//*1.6;
					margin-bottom: 2px * 1.6;
				}
				@media (min-width: (1200px*2)) {
					//*2;
					margin-bottom: 2px * 2;
				}
			}
		}
		.contenedor-cuadro-gris {
			//margin-left: 64px;
			padding-right: 0;
			z-index: 2;
			@media (min-width: 992px) {
				background-color: #e7e7e7;
				//*0.827;
				width: 51.5%;
				padding-left: 76px * 0.827;
				padding-right: 76px * 0.827;
			}
			@media (min-width: 1200px) {
				padding-left: 76px;
				padding-right: 76px;
			}
			@media (min-width: (1200px*1.6)) {
				//*1.6;
				padding-left: 76px * 1.6;
				padding-right: 76px * 1.6;
			}
			@media (min-width: (1200px*2)) {
				//*2;
				padding-left: 76px * 2;
				padding-right: 76px * 2;
			}
		}
		.cuadro-gris {
			background-color: #e7e7e7;
			display: flex;
			align-items: center;
			justify-content: center;
			color: #222220;
			height: 167px;
			font-size: 12px;

			width: 240px;
			padding-left: 19px;
			padding-right: 19px;
			float: right;
			text-align: left;
			letter-spacing: -0.07em;
			line-height: 1.2;

			@media (min-width: 414px) {
				// * 1.29;
				height: 167px * 1.29;
				font-size: 12px * 1.29;

				width: 240px * 1.29;
				padding-left: 17px * 1.29;
				padding-right: 17px * 1.29;
			}

			@media (min-width: 768px) {
				// * 2.4;
				height: 167px * 2.4;
				font-size: 12px * 2.4;

				width: 240px * 2.4;
				padding-left: 17px * 2.4;
				padding-right: 17px * 2.4;
			}

			@media (min-width: 992px) {
				//*0.827;
				height: 267px * 0.827;
				font-size: 15px * 0.827;
				width: initial;
				padding-left: initial;
				padding-right: initial;
				float: none;
				text-align: left;
			}
			@media (min-width: 1200px) {
				height: 267px;
				font-size: 15px;
			}
			@media (min-width: (1200px*1.6)) {
				//*1.6;
				height: 267px * 1.6;
				font-size: 15px * 1.6;
			}
			@media (min-width: (1200px*2)) {
				//*2;
				height: 267px * 2;
				font-size: 15px * 2;
			}

			.azul {
				color: $color-azul;
			}
			.bold {
				font-family: $fonts-Gotham-Bold;
			}
			.book {
				font-family: $fonts-Gotham-Book;
			}
			.italic {
				font-family: $fonts-Gotham-Book-Italic;
			}
			.medium {
				padding-top: 0.3em;
				font-family: $fonts-Gotham-Medium;
				letter-spacing: 0em;
			}
		}
	} //Termina cinta 1

	.grid {
		> div > div > .col-12 {
			padding: 0;
		}

		.grid-contenedor {
			width: 94%;
			display: flex;
			flex-wrap: wrap;
			line-height: 1.2;
			flex-direction: column;
			overflow: hidden;
			position: relative;
			height: 533px;

			@media (min-width: 992px) {
				//*0.827;
				height: 533px * 0.827;
			}
			@media (min-width: 1200px) {
				height: 533px;
			}
			@media (min-width: (1200px*1.6)) {
				//*1.6;
				height: 533px * 1.6;
			}
			@media (min-width: (1200px*2)) {
				//*2;
				height: 533px * 2;
			}

			.slide {
				width: 100%;
				height: 100%;
				//background-color: rgba(243,173,0,.2) ;
				display: none;
				flex-wrap: wrap;
				flex-direction: column;
				justify-content: space-between;
				align-content: space-between;
				transition: right, left 1s;
				position: absolute;

				&.active {
					display: flex;
				}

				//Reglas fijas en todos los 2 slids
				.cuadro_principal,
				.cuadro_secundario {
					position: relative;
					background-size: auto 100%;
					background-position: 50% 50%;
					transition: background-size 0.5s ease-in;
					padding: 35px 38px;

					@media (min-width: 992px) {
						//*0.827;
						padding: 35px * 0.827 38px * 0.827;
					}
					@media (min-width: 1200px) {
						padding: 35px 38px;
					}
					@media (min-width: (1200px*1.6)) {
						//*1.6;
						padding: 35px * 1.6 38px * 1.6;
					}
					@media (min-width: (1200px*2)) {
						//*2;
						padding: 35px * 2 38px * 2;
					}

					.titulo {
						font-size: 23px;
						margin-bottom: 7px;

						@media (min-width: 992px) {
							//*0.827;
							font-size: 23px * 0.827;
							margin-bottom: 7px * 0.827;
						}
						@media (min-width: 1200px) {
							font-size: 23px;
							margin-bottom: 7px;
						}
						@media (min-width: (1200px*1.6)) {
							//*1.6;
							font-size: 23px * 1.6;
							margin-bottom: 7px * 1.6;
						}
						@media (min-width: (1200px*2)) {
							//*2;
							font-size: 23px * 2;
							margin-bottom: 7px * 2;
						}
					}

					/*.go{
						display: none;
						width: 139px;
						height: 61px;
						font-size: 39px;

						@media (min-width: 992px){
							//*0.827;
							width: 115px;
							height: 50px;
							font-size: 32px;
						}
						@media (min-width: 1200px){
							width: 139px;
							height: 61px;
							font-size: 39px;
						}
						@media (min-width: (1200px*1.6)){
							//*1.6;
							width: 222px;
							height: 97px;
							font-size: 62px;
						}
						@media (min-width: (1200px*2)){
							//*2;
							width: 139px*2;
							height: 61px*2;
							font-size: 39px*2;
						}

						img{
							display: block;
							margin-left: 11px;
							height: 29px;
							padding-top: 2px;

							@media (min-width: 992px){
								//*0.827;
								margin-left: 11px*0.827;
								height: 29px*0.827;
								padding-top: 2px*0.827;
							}
							@media (min-width: 1200px){
								margin-left: 11px;
								height: 29px;
								padding-top: 2px;
							}
							@media (min-width: (1200px*1.6)){
								//*1.6;
								margin-left: 11px*1.6;
								height: 29px*1.6;
								padding-top: 2px*1.6;
							}
							@media (min-width: (1200px*2)){
								//*2;
								margin-left: 11px*2;
								height: 29px*2;
								padding-top: 2px*2;
							}

						}
					}
					*/
					.go {
						display: none;
						width: 92px;
						height: 42px;
						font-size: 23px;

						@media (min-width: 992px) {
							//*0.827;
							width: 76px;
							height: 35px;
							font-size: 19px;
						}
						@media (min-width: 1200px) {
							width: 92px;
							height: 42px;
							font-size: 23px;
						}
						@media (min-width: (1200px*1.6)) {
							//*1.6;
							width: 147px;
							height: 67px;
							font-size: 37px;
						}
						@media (min-width: (1200px*2)) {
							//*2;
							width: 92px * 2;
							height: 42px * 2;
							font-size: 23px * 2;
						}

						img {
							display: block;
							margin-left: 10px;
							height: 22px;
							padding-top: 1px;

							@media (min-width: 992px) {
								//*0.827;
								margin-left: 10px * 0.827;
								height: 22px * 0.827;
								padding-top: 1px * 0.827;
							}
							@media (min-width: 1200px) {
								margin-left: 10px;
								height: 22px;
								padding-top: 1px;
							}
							@media (min-width: (1200px*1.6)) {
								//*1.6;
								margin-left: 10px * 1.6;
								height: 22px * 1.6;
								padding-top: 1px * 1.6;
							}
							@media (min-width: (1200px*2)) {
								//*2;
								margin-left: 10px * 2;
								height: 22px * 2;
								padding-top: 1px * 2;
							}
						}
					}
				}
				.cuadro_small {
					position: relative;
					background-size: auto 100%;
					background-position: 50% 50%;
					transition: background-size 0.5s ease-in;
					padding: 13px 14px;

					@media (min-width: 992px) {
						//*0.827;
						padding: 13px * 0.827 14px * 0.827;
					}
					@media (min-width: 1200px) {
						padding: 13px 14px;
					}
					@media (min-width: (1200px*1.6)) {
						//*1.6;
						padding: 13px * 1.6 14px * 1.6;
					}
					@media (min-width: (1200px*2)) {
						//*2;
						padding: 13px * 2 14px * 2;
					}

					.titulo {
						font-size: 23px;
						margin-bottom: 8px;

						@media (min-width: 992px) {
							//*0.827;
							font-size: 23px * 0.827;
							margin-bottom: 8px * 0.827;
						}
						@media (min-width: 1200px) {
							font-size: 23px;
							margin-bottom: 8px;
						}
						@media (min-width: (1200px*1.6)) {
							//*1.6;
							font-size: 23px * 1.6;
							margin-bottom: 8px * 1.6;
						}
						@media (min-width: (1200px*2)) {
							//*2;
							font-size: 23px * 2;
							margin-bottom: 8px * 2;
						}
					}

					.go {
						display: none;
						width: 92px;
						height: 42px;
						font-size: 23px;

						@media (min-width: 992px) {
							//*0.827;
							width: 76px;
							height: 35px;
							font-size: 19px;
						}
						@media (min-width: 1200px) {
							width: 92px;
							height: 42px;
							font-size: 23px;
						}
						@media (min-width: (1200px*1.6)) {
							//*1.6;
							width: 147px;
							height: 67px;
							font-size: 37px;
						}
						@media (min-width: (1200px*2)) {
							//*2;
							width: 92px * 2;
							height: 42px * 2;
							font-size: 23px * 2;
						}

						img {
							display: block;
							margin-left: 10px;
							height: 22px;
							padding-top: 1px;

							@media (min-width: 992px) {
								//*0.827;
								margin-left: 10px * 0.827;
								height: 22px * 0.827;
								padding-top: 1px * 0.827;
							}
							@media (min-width: 1200px) {
								margin-left: 10px;
								height: 22px;
								padding-top: 1px;
							}
							@media (min-width: (1200px*1.6)) {
								//*1.6;
								margin-left: 10px * 1.6;
								height: 22px * 1.6;
								padding-top: 1px * 1.6;
							}
							@media (min-width: (1200px*2)) {
								//*2;
								margin-left: 10px * 2;
								height: 22px * 2;
								padding-top: 1px * 2;
							}
						}
					}
				}

				.cuadro_principal,
				.cuadro_small.cuadro_small_1,
				.cuadro_small.cuadro_small_2,
				.cuadro_secundario {
					display: flex;
					flex-direction: column;
					justify-content: flex-end;

					.titulo {
						display: none;
					}
					.contenedor-boton {
						display: none;
					}

					&:hover {
						background-size: auto 110% !important;

						.overlay_1 {
							z-index: 1;
							position: absolute;
							top: 0;
							left: 0;
							width: 100%;
							height: 100%;
							//background-color: #3F3F3D ;
							background-color: rgba(#3f3f3d, 1);
							//background-blend-mode:luminosity;
							mix-blend-mode: color;
						}
						.overlay_2 {
							z-index: 2;
							position: absolute;
							top: 0;
							left: 0;
							width: 100%;
							height: 100%;
							//background-color: #3F3F3D ;
							background-color: rgba(#6aaddf, 0.5);
							//background-blend-mode:luminosity;
							mix-blend-mode: color;
						}
						.overlay_3 {
							z-index: 3;
							position: absolute;
							top: 0;
							left: 0;
							width: 100%;
							height: 100%;
							//background-color: #3F3F3D ;
							background: rgb(0, 0, 0);
							background: -moz-linear-gradient(
								90deg,
								rgba(0, 0, 0, 1) 0%,
								rgba(0, 0, 0, 0) 100%
							);
							background: -webkit-linear-gradient(
								90deg,
								rgba(0, 0, 0, 1) 0%,
								rgba(0, 0, 0, 0) 100%
							);
							background: linear-gradient(
								90deg,
								rgba(0, 0, 0, 1) 0%,
								rgba(0, 0, 0, 0) 100%
							);
							filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000",endColorstr="#000000",GradientType=1);
							opacity: 0.7;
						}

						.titulo {
							display: block;
							z-index: 4;
							font-family: $fonts-Gotham-Black;
							color: white;
						}

						.go {
							z-index: 5;
							text-decoration: none;
							display: flex !important;
							justify-content: center;
							align-items: center;
							font-family: $fonts-Gotham-Book;
							color: #fff;
							position: relative;

							&:before {
								content: '';
								position: absolute;
								z-index: -1;
								top: 0;
								left: 0;
								right: 0;
								bottom: 0;
								/*background: rgb(106,173,223);
								background: -moz-linear-gradient(90deg, rgba(106,173,223,1) 0%, rgba(166,187,53,1) 100%);
								background: -webkit-linear-gradient(90deg, rgba(106,173,223,1) 0%, rgba(166,187,53,1) 100%);
								background: linear-gradient(90deg, rgba(106,173,223,1) 0%, rgba(166,187,53,1) 100%);
								filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#6aaddf",endColorstr="#a6bb35",GradientType=1); 
								*/

								background: rgb(106, 173, 223);
								background: -moz-linear-gradient(
									90deg,
									rgba(106, 173, 223, 1) 0%,
									rgba(158, 27, 126, 1) 100%
								);
								background: -webkit-linear-gradient(
									90deg,
									rgba(106, 173, 223, 1) 0%,
									rgba(158, 27, 126, 1) 100%
								);
								background: linear-gradient(
									90deg,
									rgba(106, 173, 223, 1) 0%,
									rgba(158, 27, 126, 1) 100%
								);
								filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#6aaddf",endColorstr="#9e1b7e",GradientType=1);

								-webkit-mask: linear-gradient(#fff 0 0)
										content-box,
									linear-gradient(#fff 0 0);
								mask: linear-gradient(#fff 0 0) content-box,
									linear-gradient(#fff 0 0);
								-webkit-mask-composite: destination-out;
								mask-composite: exclude;
								padding: 2px;
								border-radius: 35px;

								@media (min-width: 992px) {
									//*0.827;
									padding: 1px;
									border-radius: 29px;
								}
								@media (min-width: 1200px) {
									padding: 2px;
									border-radius: 35px;
								}
								@media (min-width: (1200px*1.6)) {
									//*1.6;
									padding: 3px;
									border-radius: 35px * 1.6;
								}
								@media (min-width: (1200px*2)) {
									//*2;
									padding: 2px * 2;
									border-radius: 35px * 2;
								}
							}
						}
					}
				}

				&[data-slide='1'] {
					left: 0;
					.cuadro_principal {
						width: 42.5%;
						height: 100%;
						//background-image: url(../img/home/cuadro_principal.jpg);
					}
					.cuadro_small.cuadro_small_1 {
						width: calc(21.5% - 6px);
						height: calc(50% - 1.5px);
						//background-image: url(../img/home/cuadro_small_1.png);

						@media (min-width: 992px) {
							//*0.827;
							width: calc(21.5% - 6px * 0.827);
							height: calc(50% - 1.5px * 0.827);
						}
						@media (min-width: 1200px) {
							width: calc(21.5% - 6px);
							height: calc(50% - 1.5px);
						}
						@media (min-width: (1200px*1.6)) {
							//*1.6;
							width: calc(21.5% - 6px * 1.6);
							height: calc(50% - 1.5px * 1.6);
						}
						@media (min-width: (1200px*2)) {
							//*2;
							width: calc(21.5% - 6px * 2);
							height: calc(50% - 1.5px * 2);
						}
					}
					.cuadro_small.cuadro_small_2 {
						width: calc(21.5% - 6px);
						height: calc(50% - 1.5px);
						//background-image: url(../img/home/cuadro_small_2.png);

						@media (min-width: 992px) {
							//*0.827;
							width: calc(21.5% - 6px * 0.827);
							height: calc(50% - 1.5px * 0.827);
						}
						@media (min-width: 1200px) {
							width: calc(21.5% - 6px);
							height: calc(50% - 1.5px);
						}
						@media (min-width: (1200px*1.6)) {
							//*1.6;
							width: calc(21.5% - 6px * 1.6);
							height: calc(50% - 1.5px * 1.6);
						}
						@media (min-width: (1200px*2)) {
							//*2;
							width: calc(21.5% - 6px * 2);
							height: calc(50% - 1.5px * 2);
						}
					}
					.cuadro_secundario {
						width: 36%;
						height: 100%;
						//background-image: url(../img/home/cuadro_secundario.png);
					}
				}

				&[data-slide='2'] {
					left: 100%;
					z-index: 9;
					.cuadro_principal {
						width: 42.5%;
						height: 100%;
						//background-image: url(../img/home/cuadro_principal.jpg);
					}
					.cuadro_small.cuadro_small_1 {
						width: calc(21.5% - 6px);
						height: calc(50% - 1.5px);
						//background-image: url(../img/home/cuadro_small_1.png);

						@media (min-width: 992px) {
							//*0.827;
							width: calc(21.5% - 6px * 0.827);
							height: calc(50% - 1.5px * 0.827);
						}
						@media (min-width: 1200px) {
							width: calc(21.5% - 6px);
							height: calc(50% - 1.5px);
						}
						@media (min-width: (1200px*1.6)) {
							//*1.6;
							width: calc(21.5% - 6px * 1.6);
							height: calc(50% - 1.5px * 1.6);
						}
						@media (min-width: (1200px*2)) {
							//*2;
							width: calc(21.5% - 6px * 2);
							height: calc(50% - 1.5px * 2);
						}
					}
					.cuadro_small.cuadro_small_2 {
						width: calc(21.5% - 6px);
						height: calc(50% - 1.5px);
						//background-image: url(../img/home/cuadro_small_2.png);

						@media (min-width: 992px) {
							//*0.827;
							width: calc(21.5% - 6px * 0.827);
							height: calc(50% - 1.5px * 0.827);
						}
						@media (min-width: 1200px) {
							width: calc(21.5% - 6px);
							height: calc(50% - 1.5px);
						}
						@media (min-width: (1200px*1.6)) {
							//*1.6;
							width: calc(21.5% - 6px * 1.6);
							height: calc(50% - 1.5px * 1.6);
						}
						@media (min-width: (1200px*2)) {
							//*2;
							width: calc(21.5% - 6px * 2);
							height: calc(50% - 1.5px * 2);
						}
					}
					.cuadro_secundario {
						width: 36%;
						height: 100%;
						//background-image: url(../img/home/cuadro_secundario.png);
					}
				}
			}
		}
		.botones {
			width: 6%;
			height: 100%;
			//background-color: rgba(106,173,223,.2) ;
			display: flex;
			justify-content: center;
			align-items: center;
			background-color: #fff;
			z-index: 10;
			.flecha {
				background-image: url(../img/home/mayorque.svg);
				background-size: 100%;
				background-repeat: no-repeat;
				cursor: pointer;
				transition: transform 0.4s;

				width: 29px;
				height: 46px;

				@media (min-width: 992px) {
					//*0.827;
					width: 29px * 0.827;
					height: 46px * 0.827;
				}
				@media (min-width: 1200px) {
					width: 29px;
					height: 46px;
				}
				@media (min-width: (1200px*1.6)) {
					//*1.6;
					width: 29px * 1.6;
					height: 46px * 1.6;
				}
				@media (min-width: (1200px*2)) {
					//*2;
					width: 29px * 2;
					height: 46px * 2;
				}
				&.active {
					transform: rotate(-180deg);
				}
			}
		}
	} //Termina grid

	.grid_mobile {
		padding-bottom: 24px;

		@media (min-width: 414px) {
			padding-bottom: 24px * 1.29;
		}

		@media (min-width: 768px) {
			padding-bottom: 24px * 2.4;
		}

		> .container {
			padding-left: 0;
			padding-right: 0;

			> .row {
				margin-left: 0;
				margin-right: 0;
			}
		}

		.carousel-indicators {
			//position: relative;
			z-index: 10;
			margin-bottom: 0;
			bottom: -24px;

			@media (min-width: 414px) {
				bottom: -24px * 1.29;
			}

			@media (min-width: 768px) {
				bottom: -24px * 2.4;
			}

			button {
				border-radius: 50%;
				border: 1px solid #1b1b1a;
				width: 5px;
				height: 5px;
				margin-right: 9px;
				margin-left: 9px;

				@media (min-width: 414px) {
					// * 1.29;
					width: 5px * 1.29;
					height: 5px * 1.29;
					margin-right: 9px * 1.29;
					margin-left: 9px * 1.29;
				}

				@media (min-width: 768px) {
					// * 2.4;
					width: 5px * 2.4;
					height: 5px * 2.4;
					margin-right: 9px * 2.4;
					margin-left: 9px * 2.4;
				}

				&.active {
					background-color: #b81480;
					border: 1px solid #b81480;

					@media (min-width: 768px) {
						border: 2px solid #b81480;
					}
				}
			}
		}

		.carousel-item {
			transition: transform 0.6s ease-in-out;
			position: relative;

			.imagen {
				display: flex;
				width: 100%;
				height: 100%;
				background-size: cover;
				background-position: 50% 50%;

				justify-content: center;
				align-items: center;
				flex-direction: column;
				position: absolute;
				top: 0;
				left: 0;

				.overlay_1 {
					z-index: 3;
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					//background-color: #3F3F3D ;
					background: rgb(0, 0, 0);
					background: -moz-linear-gradient(
						0deg,
						rgba(0, 0, 0, 1) 0%,
						rgba(0, 0, 0, 0) 100%
					);
					background: -webkit-linear-gradient(
						0deg,
						rgba(0, 0, 0, 1) 0%,
						rgba(0, 0, 0, 0) 100%
					);
					background: linear-gradient(
						0deg,
						rgba(0, 0, 0, 1) 0%,
						rgba(0, 0, 0, 0) 100%
					);
					filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000",endColorstr="#000000",GradientType=1);
					opacity: 0.7;
				}

				.titulo {
					font-family: $fonts-Gotham-Black;
					color: #fff;
					font-size: 20px;
					margin-bottom: 2px;
					position: relative;
					z-index: 5;

					@media (min-width: 414px) {
						// * 1.29;
						font-size: 20px * 1.29;
						margin-bottom: 2px * 1.29;
					}

					@media (min-width: 768px) {
						// * 2.4;
						font-size: 20px * 2.4;
						margin-bottom: 2px * 2.4;
					}
				}
			}
			&:after {
				//padding-top: 100%;
				padding-top: 56.25%;
				/* 16:9 ratio */
				display: block;
				content: '';
			}
		}

		.boton-mobil {
			display: block;
			width: 71px;
			height: 30px;
			background-image: url(../img/home/boton-carousel.svg);
			background-size: 100% auto;
			background-repeat: no-repeat;
			position: relative;
			z-index: 5;

			@media (min-width: 414px) {
				// * 1.29;
				width: 71px * 1.29;
				height: 30px * 1.29;
			}

			@media (min-width: 768px) {
				// * 2.4;
				width: 71px * 2.4;
				height: 30px * 2.4;
			}
		}
	}

	.partners {
		position: relative;

		padding-bottom: 20px;

		@media (min-width: 414px) {
			padding-bottom: 20px * 1.29;
		}

		@media (min-width: 768px) {
			padding-bottom: 20px * 2.4;
		}

		@media (min-width: 992px) {
			padding-bottom: 0px;
		}

		.linea {
			@media (min-width: 992px) {
				//*0.827;
				position: absolute;
				background-color: #9e1b7e;
				right: 0;

				top: 150px * 0.827;
				width: 38px * 0.827;
				height: 419px * 0.827;
			}
			@media (min-width: 1200px) {
				top: 150px;
				width: 38px;
				height: 419px;
			}
			@media (min-width: (1200px*1.6)) {
				//*1.6;
				top: 150px * 1.6;
				width: 38px * 1.6;
				height: 419px * 1.6;
			}
			@media (min-width: (1200px*2)) {
				//*2;
				top: 150px * 2;
				width: 38px * 2;
				height: 419px * 2;
			}
		}

		.cuadrito {
			position: absolute;
			width: 5px;
			height: 5px;
			top: 376px;
			right: 26px;

			@media (min-width: 414px) {
				// * 1.29;
				width: 5px * 1.29;
				height: 5px * 1.29;
				top: 376px * 1.29;
				right: 26px * 1.29;
			}

			@media (min-width: 768px) {
				// * 2.4;
				width: 5px * 2.4;
				height: 5px * 2.4;
				top: 376px * 2.4;
				right: 26px * 2.4;
			}

			@media (min-width: 992px) {
				//*0.827;
				width: 19px * 0.827;
				height: 19px * 0.827;
				bottom: 80px * 0.827;
				right: 5px * 0.827 + 10;
				top: initial;
			}
			@media (min-width: 1200px) {
				width: 19px;
				height: 19px;
				bottom: 80px;
				right: 5px;
			}
			@media (min-width: (1200px*1.6)) {
				//*1.6;
				width: 19px * 1.6;
				height: 19px * 1.6;
				bottom: 80px * 1.6;
				right: 5px * 1.6;
			}
			@media (min-width: (1200px*2)) {
				//*2;
				width: 19px * 2;
				height: 19px * 2;
				bottom: 80px * 2;
				right: 5px * 2;
			}
		}

		> div {
			position: relative;

			//Verion movile no aplica

			@media (min-width: 992px) {
				//*0.827;
				padding: 0;
				padding-top: 100px * 0.827;
				padding-bottom: 175px * 0.827;
			}
			@media (min-width: 1200px) {
				padding-top: 100px;
				padding-bottom: 175px;
			}
			@media (min-width: (1200px*1.6)) {
				//*1.6;
				padding-top: 100px * 1.6;
				padding-bottom: 175px * 1.6;
			}
			@media (min-width: (1200px*2)) {
				//*2;
				padding-top: 100px * 2;
				padding-bottom: 175px * 2;
			}
		}

		.titulo {
			//background-color: rgba(106,173,223,.4) ;
			font-family: $fonts-Gotham-LightItalic;
			color: #41413f;
			font-size: 15px;
			text-align: center;
			padding-top: 49px;
			padding-bottom: 21px;

			@media (min-width: 414px) {
				// * 1.29;
				font-size: 15px * 1.29;
				padding-top: 49px * 1.29;
				padding-bottom: 21px * 1.29;
			}

			@media (min-width: 768px) {
				// * 2.4;
				font-size: 15px * 2.4;
				padding-top: 49px * 2.4;
				padding-bottom: 21px * 2.4;
			}

			@media (min-width: 992px) {
				//*0.827;
				text-align: left;
				font-size: 23px * 0.827;
				padding-left: 45px * 0.827;
				padding-top: 4px * 0.827;
				margin-top: 0;
				padding-bottom: initial;
			}
			@media (min-width: 1200px) {
				font-size: 23px;
				padding-left: 45px;
				padding-top: 4px;
			}
			@media (min-width: (1200px*1.6)) {
				//*1.6;
				font-size: 23px * 1.6;
				padding-left: 45px * 1.6;
				padding-top: 4px * 1.6;
			}
			@media (min-width: (1200px*2)) {
				//*2;
				font-size: 23px * 2;
				padding-left: 45px * 2;
				padding-top: 4px * 2;
			}
		}
		.logos {
			//background-color: rgba(206,100,100,.4) ;
			width: 100%;
			display: grid;

			grid-template-columns: repeat(4, 24.25%);
			grid-column-gap: 1%;
			grid-row-gap: 7px;

			@media (min-width: 414px) {
				grid-row-gap: 7px * 1.29;
			}

			@media (min-width: 768px) {
				grid-row-gap: 7px * 2.4;
			}

			@media (min-width: 992px) {
				//*0.827;
				grid-template-columns: repeat(5, 19%);
				grid-column-gap: 1.25%;
				grid-row-gap: 30px * 0.827;
			}
			@media (min-width: 1200px) {
				grid-row-gap: 30px;
			}
			@media (min-width: (1200px*1.6)) {
				//*1.6;
				grid-row-gap: 30px * 1.6;
			}
			@media (min-width: (1200px*2)) {
				//*2;
				grid-row-gap: 30px * 2;
			}

			.logo {
				width: 100%;
				height: 100%;
				display: flex;
				justify-content: center;
				align-items: center;
				transition: opacity 0.1s ease-in;
				opacity: 0.495;
				//opacity: 1;
				img {
					width: 80%;
				}

				//&:hover {
				//opacity: 1;
				//}
			}
		}
	}

	#footer_home {
		//solo visible en pagina pequeñas

		background-color: #941b7e;
		width: 100%;
		height: 25px;
		margin-top: 84px;

		@media (min-width: 414px) {
			// * 1.29;
			height: 25px * 1.29;
			margin-top: 84px * 1.29;
		}

		@media (min-width: 768px) {
			// * 2.4;
			height: 25px * 2.4;
			margin-top: 84px * 2.4;
		}

		@media (min-width: (1200px * 0.827)) {
			//*0.827;
			display: none;
		}
	}
}
