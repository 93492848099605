.delay-100m{
	animation-delay: 100ms;
}
.delay-200m{
	animation-delay: 200ms;
}
.delay-300m{
	animation-delay: 300ms;
}
.delay-400m{
	animation-delay: 400ms;
}
.delay-500m{
	animation-delay: 500ms;
}
.delay-600m{
	animation-delay: 600ms;
}
.delay-700m{
	animation-delay: 700ms;
}
.delay-800m{
	animation-delay: 800ms;
}
.delay-900m{
	animation-delay: 900ms;
}