
$color-azul-oscuro: #1c2441;

$color-rojo: #e6243c;

$color-azul: #6AADDF;

$color-titulos: #87704e;

$color-botones: #a2825a;

$white-color: #ffffff;
$black-color: #000000;

