//Los estilos que no dependen de clases, los que son aplicados a etiquetas, normalice
body {
	position: relative;
}
html {
	min-width: 320px;
	//background-color: red;
	scroll-behavior: smooth;
}

.container {
	max-width: 1140px;
	@media (min-width: 992px) {
		max-width: 960px;
	}
	@media (min-width: 1200px) {
		max-width: 1140px;
	}
	@media (min-width: 1400px) {
		max-width: 1140px !important;
	}
	@media (min-width: (1200px*1.6)) {
		max-width: 1140px * 1.6 !important;
	}
	@media (min-width: (1200px*2)) {
		max-width: 1140px * 2 !important;
	}
}

textarea {
	resize: none;
}

video {
	//background-image: url(/assets/img/cover.png);
	//background-size: cover;
	//background-position: 50% 50%;
	background-color: #000;
}

/*
html,
body{
	height: 100%;
}

body{
	position: relative;
	height: 100%;
}
@media (min-width: 768px) {
	body{
		height: initial;
	}
}

html{
    min-height: 480px;

	@media (min-width: 768px) {
		min-height: 1024px;
	}
	@media (min-width: 992px) {
		min-height: 768px;
	}
	@media (min-width: 1200px) {
		min-height: 720px;
	}
	@media (min-width: 2400px) {
		min-height: 1440px;
	}
}*/
