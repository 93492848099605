.video{
	iframe{
		height: 200px;
		display: block;
		@media (min-width: 360px){
			height: 316px;
		}
		@media (min-width: 768px){
			height: 370px;
		}
		@media (min-width: 992px){
			height: 413px;
		}
		@media (min-width: 1200px){
			height: 516px;
		}
		@media (min-width: 2400px){
			height: 1032px;
		}
	}
}