footer{
	height: 94px;
	background-color: $color-azul-oscuro;
	display: block;
	padding-top: 25px;
	@media (min-width: 992px){
		padding-top: 50px;	
		height: 187px;
	}
	
	img{
		width: 81px;
		display: block;
		margin: 0 auto 0;
		@media (min-width: 992px){
			width: 181px;
		}
	}

}