.jobs {
	@media (max-width: ((1200px * 0.827) - 1 )) {
		//*0.827;
		.esquina_derecha {
			background: none;
			display: none;
		}
	}

	min-height: 480px;

	@media (min-width: 414px) {
		// * 1.29;
		min-height: 480px * 1.29;
	}

	@media (min-width: 768px) {
		// * 2.4;
		min-height: 420px * 2.4;
	}

	@media (min-width: ((1200px * 0.827))) {
		//*0.827;
		min-height: (610px + 50px) * 0.827;
	}

	@media (min-width: 1200px) {
		//*0.827;
		min-height: (610px + 50px);
	}

	@media (min-width: ((1200px*1.6))) {
		//*1.6;
		min-height: (610px + 50px) * 1.6;
	}

	@media (min-width: ((1200px*2))) {
		min-height: (610px + 50px) * 2;
	}

	.seccion_0 {
		@media (min-width: (1200px * 0.827)) {
			display: none;
		}

		.breadcrumb {
			line-height: 1;
			position: relative;
			display: block;
			display: inline-block;
			margin-bottom: 0px;
			padding-bottom: 9px;
			margin-top: 14px;
			margin-left: 8px;

			@media (min-width: 414px) {
				// * 1.29;
				padding-bottom: 9px * 1.29;
				margin-top: 14px * 1.29;
				margin-left: 8px * 1.29;
			}

			@media (min-width: 768px) {
				// * 2.4;
				padding-bottom: 9px * 2.4;
				margin-top: 14px * 2.4;
				margin-left: 8px * 2.4;
			}

			a {
				font-family: $fonts-Gotham-Book;
				font-size: 9px;
				color: #3c3d38;
				text-decoration: none;

				@media (min-width: 414px) {
					font-size: 9px * 1.29;
				}

				@media (min-width: 768px) {
					font-size: 9px * 2.4;
				}

				&.active {
					font-family: $fonts-Gotham-Bold;
					margin-right: 0px;
				}
			}
			span {
				color: #000000;
				font-family: $fonts-Gotham-Book;
				font-size: 9px;
				margin-left: 3px;
				margin-right: 0px;

				@media (min-width: 414px) {
					// * 1.29;
					font-size: 9px * 1.29;
					margin-left: 3px * 1.29;
				}

				@media (min-width: 768px) {
					// * 2.4;
					font-size: 9px * 2.4;
					margin-left: 3px * 2.4;
				}
			}

			&::before {
				//Linea inferior
				content: '';
				position: absolute;
				width: 100%;
				height: 1px;
				background: #000;
				bottom: 0;
				left: 0;
			}
		}
	} //Breadcums

	.seccion_1 {
		.cuadrito_inicial {
			position: absolute;
			z-index: 11;

			width: 5px;
			height: 5px;
			top: 295px;
			right: 26px;

			@media (min-width: 414px) {
				// * 1.29;
				width: 5px * 1.29;
				height: 5px * 1.29;
				top: 295px * 1.29;
				right: 26px * 1.29;
			}

			@media (min-width: 768px) {
				// * 2.4;
				width: 5px * 2.4;
				height: 5px * 2.4;
				top: 295px * 2.4 - 140px;
				right: 26px * 2.4;
			}

			@media (min-width: (1200px*0.827)) {
				//*0.827;
				width: 19px * 0.827;
				height: 19px * 0.827;
				top: 478px * 0.827;
				right: 5px * 0.827;
			}
			@media (min-width: 1200px) {
				width: 19px;
				height: 19px;
				top: 478px;
				right: 5px;
			}
			@media (min-width: (1200px*1.6)) {
				//*1.6;
				width: 19px * 1.6;
				height: 19px * 1.6;
				top: 478px * 1.6;
				right: 5px * 1.6;
			}
			@media (min-width: (1200px*2)) {
				//*2;
				width: 19px * 2;
				height: 19px * 2;
				top: 478px * 2;
				right: 5px * 2;
			}
		}

		position: relative;

		.container {
			position: relative;
		}

		padding-top: 30px;

		@media (min-width: 414px) {
			// * 1.29;
			padding-top: 30px * 1.29;
		}

		@media (min-width: 768px) {
			// * 2.4;
			padding-top: 30px * 2.4;
		}

		@media (min-width: (1200px*0.827)) {
			//*0.827;
			padding-top: 132px * 0.827;
		}
		@media (min-width: 1200px) {
			padding-top: 132px;
		}
		@media (min-width: (1200px*1.6)) {
			//*1.6;
			padding-top: 132px * 1.6;
		}
		@media (min-width: (1200px*2)) {
			//*2;
			padding-top: 132px * 2;
		}

		.linea {
			height: 2px;
			width: 10px;
			margin-left: 8px;

			@media (min-width: 414px) {
				margin-left: 8px * 1.29;
			}

			@media (min-width: 768px) {
				margin-left: 8px * 2.4;
			}

			@media (min-width: (1200px * 0.827)) {
				//*0.827;
				margin-left: 0;
			}

			@media (min-width: 414px) {
				// * 1.29;
				height: 2px * 1.29;
				width: 10px * 1.29;
			}

			@media (min-width: 768px) {
				// * 2.4;
				height: 2px * 2.4;
				width: 10px * 2.4;
			}

			@media (min-width: (1200px*0.827)) {
				//*0.827;
				height: 10px * 0.827;
				width: 30px * 0.827;
			}
			@media (min-width: 1200px) {
				height: 10px;
				width: 30px;
			}
			@media (min-width: (1200px*1.6)) {
				//*1.6;
				height: 10px * 1.6;
				width: 30px * 1.6;
			}
			@media (min-width: (1200px*2)) {
				//*2;
				height: 10px * 2;
				width: 30px * 2;
			}
		}

		.titulo {
			font-family: $fonts-Gotham-Medium;
			color: #3f3f3d;
			line-height: 1.2;

			margin-bottom: 31px;
			font-size: 20px;
			margin-left: 8px;
			margin-top: 5px;

			@media (min-width: 414px) {
				font-size: 20px * 1.29;
				margin-left: 8px * 1.29;
				margin-top: 5px * 1.29;
				margin-bottom: 31px * 1.29;
			}

			@media (min-width: 768px) {
				font-size: 20px * 2.4;
				margin-left: 8px * 2.4;
				margin-top: 5px * 2.4;
				margin-bottom: 31px * 2.4;
			}

			@media (min-width: (1200px * 0.827)) {
				//*0.827;
				margin-left: 0;
				margin-top: 0;
			}

			@media (min-width: (1200px*0.827)) {
				//*0.827;
				font-size: 60px * 0.827;
				margin-bottom: 60px * 0.827;
			}
			@media (min-width: 1200px) {
				font-size: 60px;
				margin-bottom: 60px;
			}
			@media (min-width: (1200px*1.6)) {
				//*1.6;
				font-size: 60px * 1.6;
				margin-bottom: 60px * 1.6;
			}
			@media (min-width: (1200px*2)) {
				//*2;
				font-size: 60px * 2;
				margin-bottom: 60px * 2;
			}

			span {
				font-family: $fonts-Gotham-Black;
			}
		}

		.descripcion {
			font-family: $fonts-Gotham-Book;
			line-height: 1.2;
			color: #3c3d38;

			margin: 0 auto 40px;
			text-align: justify;

			font-size: 9px;
			width: 240px;

			@media (min-width: 414px) {
				// * 1.29;
				font-size: 9px * 1.29;
				width: 240px * 1.29;
				margin: 0 auto 40px * 1.29;
			}

			@media (min-width: 768px) {
				// * 2.4;
				font-size: 9px * 2.4;
				width: 240px * 2.4;
				margin: 0 auto 40px * 2.4;
			}

			@media (min-width: (1200px*0.827)) {
				//*0.827;
				text-align: left;
				letter-spacing: -0.016em;

				font-size: 19px * 0.827;
				width: 510px * 0.827;
				margin-bottom: 75px * 0.827;
				margin-left: initial;
				margin-right: initial;
			}
			@media (min-width: 1200px) {
				font-size: 19px;
				width: 510px;
				margin-bottom: 75px;
			}
			@media (min-width: (1200px*1.6)) {
				//*1.6;
				font-size: 19px * 1.6;
				width: 510px * 1.6;
				margin-bottom: 75px * 1.6;
			}
			@media (min-width: (1200px*2)) {
				//*2;
				font-size: 19px * 2;
				width: 510px * 2;
				margin-bottom: 75px * 2;
			}
		}
	}
	.seccion_2 {
		> div > div > div {
			display: flex;
			align-items: center;
			justify-content: space-between;
		}
		.linea {
			width: 343px;
			height: 1px;
			background-color: #3f3f3d;
			display: none;

			@media (min-width: (1200px*0.827)) {
				//*0.827;
				display: flex;
				width: 343px * 0.827;
				height: 1px * 0.827;
			}
			@media (min-width: 1200px) {
				width: 343px;
				height: 1px;
			}
			@media (min-width: (1200px*1.6)) {
				//*1.6;
				width: 343px * 1.6;
				height: 1px * 1.6;
			}
			@media (min-width: (1200px*2)) {
				//*2;
				width: 343px * 2;
				height: 1px * 2;
			}
		}
		.linea_2 {
			width: 343px;
			height: 1px;
			display: none;

			@media (min-width: (1200px*0.827)) {
				//*0.827;
				display: flex;
				width: 343px * 0.827;
				height: 1px * 0.827;
			}
			@media (min-width: 1200px) {
				width: 343px;
				height: 1px;
			}
			@media (min-width: (1200px*1.6)) {
				//*1.6;
				width: 343px * 1.6;
				height: 1px * 1.6;
			}
			@media (min-width: (1200px*2)) {
				//*2;
				width: 343px * 2;
				height: 1px * 2;
			}
		}
		.contenedor-boton {
			display: flex;
			align-items: center;
			//width: 50vw;
			//width: 90%;
			margin: auto;

			position: relative;
			//padding: 30% 2em;
			box-sizing: border-box;

			color: #fff;
			background: #fff;
			background-clip: padding-box; /* !importanté */

			width: 230px;
			height: 30px;
			$border: 1px;
			border-radius: 30px;
			font-size: 10px;
			border: solid $border transparent; /* !importanté */

			@media (min-width: 414px) {
				// * 1.29;
				width: 230px * 1.29;
				height: 30px * 1.29;
				font-size: 10px * 1.29;
				border-radius: 30px * 1.29;
			}

			@media (min-width: 768px) {
				// * 2.4;
				width: 230px * 2.4;
				height: 30px * 2.4;
				font-size: 10px * 2.4;
				border-radius: 30px * 2.4;
			}

			@media (min-width: (1200px*0.827)) {
				//*0.827;
				width: 251px;
				height: 40px * 0.827;
				padding-left: 8px;

				border-radius: 40px * 0.827;
			}
			@media (min-width: 1200px) {
				width: 304px;
				height: 40px;
				padding-left: 10px;

				border-radius: 40px;
			}
			@media (min-width: (1200px*1.6)) {
				//*1.6;
				width: 486px;
				height: 40px * 1.6;
				padding-left: 16px;

				$border: 1px;
				border-radius: 40px * 1.6;
				border: solid $border transparent; /* !importanté */
			}
			@media (min-width: (1200px*2)) {
				//*2;
				width: 304px * 2;
				height: 39px * 2;
				padding-left: 10px * 2;
				border-radius: 39px * 2;
			}

			&:before {
				content: '';
				position: absolute;
				top: 0;
				right: 0;
				bottom: 0;
				left: 0;
				z-index: -1;
				margin: -$border; /* !importanté */
				border-radius: inherit; /* !importanté */
				background: linear-gradient(
					90deg,
					rgba(106, 173, 223, 1) 0%,
					rgba(158, 27, 126, 1) 100%
				);

				$border: 1px;
				margin: -$border; /* !importanté */

				@media (min-width: (1200px*0.827)) {
					//*0.827;
					$border: 1px;
					margin: -$border; /* !importanté */
				}
				@media (min-width: 1200px) {
					$border: 1px;
					margin: -$border; /* !importanté */
				}
				@media (min-width: (1200px*1.6)) {
					//*1.6;
					$border: 2px;
					margin: -$border; /* !importanté */
				}
				@media (min-width: (1200px*2)) {
					//*2;
					$border: 2px;
					margin: -$border; /* !importanté */
				}
			}

			.go {
				text-decoration: none;
				display: flex;
				justify-content: center;
				align-items: center;
				width: 100%;
				height: 100%;
				font-family: $fonts-Gotham-Medium;
				color: #3f3f3d;

				font-size: 10px;

				@media (min-width: 414px) {
					// * 1.29;
					font-size: 10px * 1.29;
				}

				@media (min-width: 768px) {
					// * 2.4;
					font-size: 10px * 2.4;
				}

				@media (min-width: (1200px*0.827)) {
					//*0.827;
					font-size: 16px * 0.827;
				}
				@media (min-width: 1200px) {
					font-size: 16px;
				}
				@media (min-width: (1200px*1.6)) {
					//*1.6;
					font-size: 16px * 1.6;
				}
				@media (min-width: (1200px*2)) {
					//*2;
					font-size: 16px * 2;
				}

				img {
					margin-left: 10px;
					height: 10px;
					width: auto;

					@media (min-width: 414px) {
						// * 1.29;
						height: 10px * 1.29;
						margin-left: 10px * 1.29;
					}

					@media (min-width: 768px) {
						// * 2.4;
						height: 10px * 2.4;
						margin-left: 10px * 2.4;
					}

					@media (min-width: 992px) {
						//*0.827;
						height: 13px * 0.827;
						margin-left: 11px * 0.827;
					}
					@media (min-width: 1200px) {
						margin-left: 11px;
						height: 13px;
					}
					@media (min-width: (1200px*1.6)) {
						//*1.6;
						height: 13px * 1.6;
						margin-left: 11px * 1.6;
					}
					@media (min-width: (1200px*2)) {
						//*2;
						height: 13px * 2;
						margin-left: 11px * 2;
					}
				}
			}
		}
	}

	#footer_jobs {
		//solo visible en pagina pequeñas

		position: fixed;
		background-color: #941b7e;
		width: 100%;
		height: 25px;
		bottom: 0;

		@media (min-width: 414px) {
			// * 1.29;
			height: 25px * 1.29;
		}

		@media (min-width: 768px) {
			// * 2.4;
			height: 25px * 2.4;
		}

		@media (min-width: (1200px * 0.827)) {
			//*0.827;
			display: none;
		}
	}
}
