.work {
	.esquina_derecha {
		background: none;
	}
	.seccion_0 {
		.breadcrumb {
			line-height: 1;
			position: relative;
			display: block;
			display: inline-block;
			margin-bottom: 0px;
			padding-bottom: 9px;
			margin-top: 14px;
			margin-left: 8px;

			@media (min-width: 414px) {
				// * 1.29;
				padding-bottom: 9px * 1.29;
				margin-top: 14px * 1.29;
				margin-left: 8px * 1.29;
			}

			@media (min-width: 768px) {
				// * 2.4;
				padding-bottom: 9px * 2.4;
				margin-top: 14px * 2.4;
				margin-left: 8px * 2.4;
			}

			@media (min-width: 992px) {
				//*0.827;
				margin-left: 0px;
				padding-bottom: 14px * 0.827;
				margin-top: 14px * 0.827;
			}

			@media (min-width: 1200px) {
				padding-bottom: 14px;
				margin-top: 14px;
			}
			@media (min-width: (1200px*1.6)) {
				//*1.6;
				padding-bottom: 14px * 1.6;
				margin-top: 14px * 1.6;
			}

			@media (min-width: (1200px*2)) {
				//*2;
				padding-bottom: 14px * 2;
				margin-top: 14px * 2;
			}

			a {
				font-family: $fonts-Gotham-Book;
				font-size: 9px;
				color: #3c3d38;
				text-decoration: none;

				@media (min-width: 414px) {
					font-size: 9px * 1.29;
				}

				@media (min-width: 768px) {
					font-size: 9px * 2.4;
				}

				@media (min-width: 992px) {
					font-size: 9px * 0.827;
				}

				@media (min-width: 1200px) {
					font-size: 9px;
				}

				@media (min-width: (1200px*1.6)) {
					font-size: 9px * 1.6;
				}

				@media (min-width: (1200px*2)) {
					font-size: 9px * 2;
				}

				&.active {
					font-family: $fonts-Gotham-Bold;
					margin-right: 0px; //18px

					@media (min-width: 992px) {
						margin-right: 18px * 0.827;
					}

					@media (min-width: 1200px) {
						margin-right: 18px;
					}

					@media (min-width: (1200px*1.6)) {
						margin-right: 18px * 1.6;
					}

					@media (min-width: (1200px*2)) {
						margin-right: 18px * 2;
					}
				}
			}
			span {
				color: #000000;
				font-family: $fonts-Gotham-Book;
				font-size: 9px;
				margin-left: 3px;
				margin-right: 0px;

				@media (min-width: 414px) {
					// * 1.29;
					font-size: 9px * 1.29;
					margin-left: 3px * 1.29;
				}

				@media (min-width: 768px) {
					// * 2.4;
					font-size: 9px * 2.4;
					margin-left: 3px * 2.4;
				}

				@media (min-width: 992px) {
					//*0.827;
					font-size: 9px * 0.827;
					margin-left: 7px * 0.827;
					margin-right: 8px * 0.827;
				}

				@media (min-width: 1200px) {
					font-size: 9px;
					margin-left: 7px;
					margin-right: 8px;
				}

				@media (min-width: (1200px*1.6)) {
					//*1.6;
					font-size: 9px * 1.6;
					margin-left: 7px * 1.6;
					margin-right: 8px * 1.6;
				}

				@media (min-width: (1200px*2)) {
					//*2;
					font-size: 9px * 2;
					margin-left: 7px * 2;
					margin-right: 8px * 2;
				}
			}

			&::before {
				//Linea inferior
				content: '';
				position: absolute;
				width: 100%;
				height: 1px;
				background: #000;
				bottom: 0;
				left: 0;
			}
		}
	}

	.seccion_1 {
		position: relative;

		padding-bottom: 72px;

		@media (min-width: 414px) {
			padding-bottom: 72px * 1.29;
		}

		@media (min-width: 768px) {
			padding-bottom: 72px * 2.4;
		}

		@media (min-width: 992px) {
			padding-bottom: 0px;
		}

		.container {
			position: relative;
		}
		padding-top: 30px;

		@media (min-width: 414px) {
			// * 1.29;
			padding-top: 30px * 1.29;
		}

		@media (min-width: 768px) {
			// * 2.4;
			padding-top: 30px * 2.4;
		}

		@media (min-width: 992px) {
			padding-top: 94px * 0.827;
		}

		@media (min-width: 1200px) {
			padding-top: 94px;
		}

		@media (min-width: (1200px*1.6)) {
			padding-top: 94px * 1.6;
		}

		@media (min-width: (1200px*2)) {
			padding-top: 94px * 2;
		}

		.linea {
			height: 2px;
			width: 10px;
			margin-left: 8px;

			@media (min-width: 414px) {
				margin-left: 8px * 1.29;
			}

			@media (min-width: 768px) {
				margin-left: 8px * 2.4;
			}

			@media (min-width: 992px) {
				//*0.827;
				margin-left: 0;
			}

			@media (min-width: 414px) {
				// * 1.29;
				height: 2px * 1.29;
				width: 10px * 1.29;
			}

			@media (min-width: 768px) {
				// * 2.4;
				height: 2px * 2.4;
				width: 10px * 2.4;
			}

			@media (min-width: 992px) {
				//*0.827;
				height: 10px * 0.827;
				width: 30px * 0.827;
			}

			@media (min-width: 1200px) {
				height: 10px;
				width: 30px;
			}

			@media (min-width: (1200px*1.6)) {
				//*1.6;
				height: 10px * 1.6;
				width: 30px * 1.6;
			}

			@media (min-width: (1200px*2)) {
				//*2;
				height: 10px * 2;
				width: 30px * 2;
			}
		}

		.titulo {
			font-family: $fonts-Gotham-Medium;
			color: #3f3f3d;
			line-height: 1.2;
			font-size: 20px;
			margin-left: 8px;
			margin-top: 5px;
			margin-bottom: 8px;

			@media (min-width: 414px) {
				font-size: 20px * 1.29;
				margin-left: 8px * 1.29;
				margin-top: 5px * 1.29;
				margin-bottom: 8px * 1.29;
			}

			@media (min-width: 768px) {
				font-size: 20px * 2.4;
				margin-left: 8px * 2.4;
				margin-top: 5px * 2.4;
				margin-bottom: 8px * 2.4;
			}

			@media (min-width: 992px) {
				//*0.827;
				margin-left: 0;
				margin-top: 0;

				line-height: 0.93 * 0.827;
				font-size: 40px * 0.827;
				margin-bottom: 5px * 0.827;
				padding-top: 13px * 0.827;
			}

			@media (min-width: 1200px) {
				line-height: 0.93;
				font-size: 40px;
				margin-bottom: 5px;
				padding-top: 13px;
			}

			@media (min-width: (1200px*1.6)) {
				//*1.6;
				line-height: 0.93 * 1.6;
				font-size: 40px * 1.6;
				margin-bottom: 5px * 1.6;
				padding-top: 13px * 1.6;
			}

			@media (min-width: (1200px*2)) {
				//*2;
				line-height: 0.93 * 2;
				font-size: 40px * 2;
				margin-bottom: 5px * 2;
				padding-top: 13px * 2;
			}

			strong {
				//font-size: 61px;
				font-family: $fonts-Gotham-Black;

				@media (min-width: 992px) {
					font-size: 50px * 0.827;
				}

				@media (min-width: 1200px) {
					font-size: 50px;
				}

				@media (min-width: (1200px*1.6)) {
					font-size: 50px * 1.6;
				}

				@media (min-width: (1200px*2)) {
					font-size: 50px * 2;
				}
			}

			p {
				margin-bottom: 0;

				@media (max-width: 991px) {
					display: inline-block;
				}
			}
		}
		.cliente {
			font-family: $fonts-Gotham-MediumItalic;
			color: #3f3f3d;
			font-size: 6px;
			margin-bottom: 17px;
			margin-left: 8px;

			@media (min-width: 414px) {
				// * 1.29;
				font-size: 6px * 1.29;
				margin-bottom: 17px * 1.29;
				margin-left: 8px * 1.29;
			}

			@media (min-width: 768px) {
				// * 2.4;
				font-size: 6px * 2.4;
				margin-bottom: 17px * 2.4;
				margin-left: 8px * 2.4;
			}

			@media (min-width: 992px) {
				//*0.827;
				font-size: 11.43px * 0.827;
				margin-bottom: 43px * 0.827;
			}

			@media (min-width: 1200px) {
				font-size: 11.43px;
				margin-bottom: 43px;
			}

			@media (min-width: (1200px*1.6)) {
				//*1.6;
				font-size: 11.43px * 1.6;
				margin-bottom: 43px * 1.6;
			}

			@media (min-width: (1200px*2)) {
				//*2;
				font-size: 11.43px * 2;
				margin-bottom: 43px * 2;
			}
		}

		.descripcion {
			font-family: $fonts-Gotham-Book;
			color: #3f3f3d;
			line-height: 1.2;
			text-align: left;

			margin: 0 auto 24px;
			text-align: justify;

			font-size: 9px;
			width: 240px;

			@media (min-width: 414px) {
				// * 1.29;
				font-size: 9px * 1.29;
				width: 240px * 1.29;
				margin: 0 auto 24px * 1.29;
			}

			@media (min-width: 768px) {
				// * 2.4;
				font-size: 9px * 2.4;
				width: 240px * 2.4;
				margin: 0 auto 24px * 2.4;
			}

			@media (min-width: 992px) {
				//*0.827;
				text-align: left;

				letter-spacing: 0.01em;
				margin-bottom: 0px;
				font-size: 16px * 0.827;
				width: 420px * 0.827;

				margin-right: initial;
				margin-left: initial;
			}
			@media (min-width: 1200px) {
				font-size: 16px; //19.81px;
				width: 420px;
			}
			@media (min-width: (1200px*1.6)) {
				//*1.6;
				font-size: 16px * 1.6;
				width: 420px * 1.6;
			}
			@media (min-width: (1200px*2)) {
				//*2;
				font-size: 16px * 2; //19.81px;
				width: 420px * 2;
			}
		}

		.contenedor_imagen_principal {
			position: relative;
			&::before {
				position: absolute;
				z-index: -1;
				width: 100%;
				left: 0;
				bottom: -72px;
				height: calc(50% + 72px);
				content: '';
				background-color: #941b7e;

				@media (min-width: 414px) {
					// * 1.29;

					bottom: -72px * 1.29;
					height: calc(50% + (72px * 1.29));
				}

				@media (min-width: 768px) {
					// * 2.4;
					bottom: -72px * 2.4;
					height: calc(50% + (72px * 2.4));
				}
				@media (min-width: 992px) {
					display: none;
				}
			}
		}

		.imagen_principal {
			width: 100%;
			//background: red;
			background-size: cover;
			background-position: 50% 50%;
			//background-image: url(../img/work/imagen_principal.jpg);
			position: relative;
			max-width: 280px;
			height: 158px;
			margin: auto;

			@media (min-width: 414px) {
				max-width: 280px * 1.29;
				height: 158px * 1.29;
			}

			@media (min-width: 768px) {
				max-width: 280px * 2.4;
				height: 158px * 2.4;
			}

			@media (min-width: 992px) {
				height: 371px * 0.827;
				margin-right: initial;
				margin-left: initial;
			}

			@media (min-width: 1200px) {
				height: 371px;
				max-width: 100%;
			}

			@media (min-width: (1200px*1.6)) {
				height: 371px * 1.6;
			}

			@media (min-width: (1200px*2)) {
				height: 371px * 2;
			}

			.categoria {
				position: absolute;
				transform: translateX(-100%);
				font-family: $fonts-Gotham-Black;
				left: -76px;
				top: 37px;
				font-size: 12px;
				font-size: 22.86px;
				line-height: 1;
				color: #941b7e;

				//Ya no se usa
			}

			.linea_categoria {
				position: absolute;
				width: 70px;
				height: 1px;
				background-color: #000000;
				left: -70px;
				top: 51px;

				//Ya no se usa
			}

			.rectangulo_gris {
				@media (min-width: 992px) {
					//*0.827;
					position: absolute;
					background-color: #3f3f3d;
					height: 90px * 0.827;
					width: 15px * 0.827;
					left: -15px * 0.827;
					top: 117px * 0.827;
				}

				@media (min-width: 1200px) {
					height: 90px;
					width: 15px;
					left: -15px;
					top: 117px;
				}

				@media (min-width: (1200px*1.6)) {
					//*1.6;
					height: 90px * 1.6;
					width: 15px * 1.6;
					left: -15px * 1.6;
					top: 117px * 1.6;
				}

				@media (min-width: (1200px*2)) {
					//*2;
					height: 90px * 2;
					width: 15px * 2;
					left: -15px * 2;
					top: 117px * 2;
				}
			}

			.etiquetas {
				width: 100%;
				text-align: left;
				right: 0;
				position: absolute;

				font-family: $fonts-Gotham-MediumItalic;

				height: 15px;
				bottom: -18px;
				font-size: 6px;
				color: #fff;

				@media (min-width: 414px) {
					// * 1.29;
					height: 15px * 1.29;
					bottom: -18px * 1.29;
					font-size: 6px * 1.29;
				}

				@media (min-width: 768px) {
					// * 2.4;
					height: 15px * 2.4;
					bottom: -18px * 2.4;
					font-size: 6px * 2.4;
				}

				@media (min-width: 992px) {
					//*0.827;
					color: #9e9c9b;
					height: 20px * 0.827;
					bottom: -25px * 0.827;
					font-size: 9.14px * 0.827;
					text-align: right;
				}

				@media (min-width: 1200px) {
					height: 20px;
					bottom: -25px;
					font-size: 9.14px;
				}

				@media (min-width: (1200px*1.6)) {
					//*1.6;
					height: 20px * 1.6;
					bottom: -25px * 1.6;
					font-size: 9.14px * 1.6;
				}

				@media (min-width: (1200px*2)) {
					//*2;
					height: 20px * 2;
					bottom: -25px * 2;
					font-size: 9.14px * 2;
				}
			}
		}
	}

	.seccion_1_5 {
		margin-top: 20px;

		@media (min-width: 414px) {
			margin-top: 20px * 1.29;
		}

		@media (min-width: 768px) {
			margin-top: 20px * 2.4;
		}

		@media (min-width: 992px) {
			margin-top: 60px * 0.827;
		}

		@media (min-width: 1200px) {
			margin-top: 60px;
		}

		@media (min-width: (1200px*1.6)) {
			margin-top: 60px * 1.6;
		}

		@media (min-width: (1200px*2)) {
			margin-top: 60px * 2;
		}

		video,
		img {
			width: 100%;
		}
	}
	.seccion_2 {
		padding-top: 0px;
		position: relative;

		.rectangulo_morado {
			@media (min-width: 992px) {
				//*0.827;
				width: 190px * 0.827;
				height: 76px * 0.827;
				position: absolute;
				right: 0;
				top: 0px;
				background-color: #941b7e;
				z-index: 1;
			}

			@media (min-width: 1200px) {
				width: 190px;
				height: 76px;
			}

			@media (min-width: (1200px*1.6)) {
				width: 190px * 1.6;
				height: 76px * 1.6;
			}

			@media (min-width: (1200px*2)) {
				width: 190px * 2;
				height: 76px * 2;
			}
		}

		.imagen_grande {
			position: relative;
			width: 100%;
			margin-bottom: 5px;
			//padding-top: 56.25;

			@media (min-width: 414px) {
				margin-bottom: 5px * 1.29;
			}

			@media (min-width: 768px) {
				margin-bottom: 5px * 2.4;
			}

			@media (min-width: 992px) {
				margin-bottom: 4px * 0.827;
			}

			@media (min-width: 1200px) {
				margin-bottom: 4px;
			}

			@media (min-width: (1200px*1.6)) {
				margin-bottom: 4px * 1.6;
			}

			@media (min-width: (1200px*2)) {
				margin-bottom: 4px * 2;
			}

			&:after {
				//padding-top: 100%;
				padding-top: 56.25%;
				/* 16:9 ratio */
				display: block;
				content: '';
			}
		}
		.fila {
			display: flex;

			flex-direction: column !important;
			@media (min-width: 992px) {
				flex-direction: row !important;
			}
		}
		.imagen_mediana {
			position: relative;

			width: 100%;

			@media (min-width: 992px) {
				//*0.827;
				width: calc(50% - (2px * 0.827));
			}

			@media (min-width: 1200px) {
				width: calc(50% - 2px);
			}

			@media (min-width: (1200px*1.6)) {
				//*1.6;
				width: calc(50% - (2px * 1.6));
			}

			@media (min-width: (1200px*2)) {
				//*2;
				width: calc(50% - (2px * 2));
			}

			//padding-top: 56.25;

			margin-bottom: 5px;
			//padding-top: 56.25;

			@media (min-width: 414px) {
				margin-bottom: 5px * 1.29;
			}

			@media (min-width: 768px) {
				margin-bottom: 5px * 2.4;
			}

			@media (min-width: 992px) {
				margin-bottom: 4px * 0.827;
			}

			@media (min-width: 1200px) {
				margin-bottom: 4px;
			}

			@media (min-width: (1200px*1.6)) {
				margin-bottom: 4px * 1.6;
			}

			@media (min-width: (1200px*2)) {
				margin-bottom: 4px * 2;
			}

			&:after {
				//padding-top: 100%;
				padding-top: 56.25%;
				/* 16:9 ratio */
				display: block;
				content: '';
			}
		}

		.imagen_pequena {
			position: relative;
			width: 100%;
			//padding-top: 56.25;

			@media (min-width: 992px) {
				//*0.827;
				width: calc(33.333% - (3px * 0.827));
			}

			@media (min-width: 1200px) {
				width: calc(33.333% - 3px);
			}

			@media (min-width: (1200px*1.6)) {
				//*1.6;

				width: calc(33.333% - (3px * 1.6));
			}

			@media (min-width: (1200px*2)) {
				//*2;
				width: calc(33.333% - (3px * 2));
			}

			//padding-top: 56.25;

			margin-bottom: 5px;
			//padding-top: 56.25;

			@media (min-width: 414px) {
				margin-bottom: 5px * 1.29;
			}

			@media (min-width: 768px) {
				margin-bottom: 5px * 2.4;
			}

			@media (min-width: 992px) {
				margin-bottom: 4px * 0.827;
			}

			@media (min-width: 1200px) {
				margin-bottom: 4px;
			}

			@media (min-width: (1200px*1.6)) {
				margin-bottom: 4px * 1.6;
			}

			@media (min-width: (1200px*2)) {
				margin-bottom: 4px * 2;
			}

			&:after {
				//padding-top: 100%;
				padding-top: 56.25%;
				/* 16:9 ratio */
				display: block;
				content: '';
			}
		}
		.imagen_grande,
		.imagen_mediana,
		.imagen_pequena {
			.main {
				position: absolute;
				top: 0;
				bottom: 0;
				right: 0;
				left: 0;

				> * {
					max-width: 100%;
					max-height: 100%;
					width: 100%;
					margin: auto;
					display: block;
				}

				.imagen {
					background-color: #fff;
					background-size: cover;
					background-position: 50% 50%;
				}
			}
		}
	}

	.seccion_3 {
		//background-color: rgba(255,0,0,.1);
		margin-top: 32px;
		margin-bottom: 133px;

		.container {
			position: relative;
		}

		.fila {
			display: flex;
		}

		.btn_izquierdo {
			width: 59px;
			height: 39px;

			background-image: url(../img/work/btn_izquierdo.svg);
			background-size: 100% auto;
			background-repeat: no-repeat;
		}
		.btn_derecho {
			width: 59px;
			height: 39px;

			background-image: url(../img/work/btn_derecho.svg);
			background-size: 100% auto;
			background-repeat: no-repeat;
		}
		.orden {
			color: #3f3f3d;
			font-family: $fonts-Gotham-Medium;
			font-size: 11.43px;
			padding-top: 10px;
		}

		.cuadrito_inicial {
			position: absolute;
			width: 19px;
			height: 19px;
			top: 95px;
			right: 31px;
			z-index: 11;
		}
	}
}
