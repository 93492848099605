#carousel-home{
	
	width: 100vw;
	height: calc(100vh - 39px);

	@media (min-width: 768px){
		height: calc(100vh - 92px);
	}
	@media (min-width: 992px){
		height: 100vh;
	}

	.carousel-inner,
	.carousel-item,
	.item{
		width: 100%;
		height: 100%;
	}

	img{
		animation-duration: 3.5s;
	}
	.slide{
		background-size: cover; 
		background-position: 50% 50%; 
		width: 100%;
		height: 100%; 

		img{
			animation-delay: 1s;
		}
	}
	.slide_1{
		background-image: url(/assets/img/home/originales/home_01_xs.jpg);
		background-position: 80% 20%; 

		@media (min-width: 768px){
			background-image: url(/assets/img/home/originales/home_01_sm.jpg);
		}
		@media (min-width: 992px){
			background-image: url(/assets/img/home/originales/home_01_md.jpg);
		}
		@media (min-width: 1200px){
			background-image: url(/assets/img/home/originales/home_01_lg.jpg);
		}

		&.ingles{
			background-image: url(/assets/img/home/originales/home_01_xs_en.jpg);

			@media (min-width: 768px){
				background-image: url(/assets/img/home/originales/home_01_sm_en.jpg);
			}
			@media (min-width: 992px){
				background-image: url(/assets/img/home/originales/home_01_md_en.jpg);
			}
			@media (min-width: 1200px){
				background-image: url(/assets/img/home/originales/home_01_lg_en.jpg);
			}
		}

		img{
			width: 277px;
			margin: 0 auto;
			display: block;

			@media (min-width: 992px){
				width: 393px;
			}
		}
	}
	.slide_2{
		background-image: url(/assets/img/home/originales/home_002_xs.svg);

		@media (min-width: 992px){
			background-image: url(/assets/img/home/originales/home_002_md.svg);
		}

		&.ingles{
			background-image: url(/assets/img/home/originales/home_002_xs_en.svg);

			@media (min-width: 992px){
				background-image: url(/assets/img/home/originales/home_002_md_en.svg);
			}	
		}

		img{
			width: 262px;
			margin: 0 auto;
			display: block;

			@media (min-width: 992px){
				width: 470px;
			}
		}
	}
	.slide_3{
		background-image: url(/assets/img/home/originales/home_03_xs.jpg);

		@media (min-width: 768px){
			background-image: url(/assets/img/home/originales/home_03_sm.jpg);
			background-position: 80% 0%; 
		}
		@media (min-width: 992px){
			background-image: url(/assets/img/home/originales/home_03_md.jpg);
		}
		@media (min-width: 1200px){
			background-image: url(/assets/img/home/originales/home_03_lg.jpg);
		}

		&.ingles{
			background-image: url(/assets/img/home/originales/home_03_xs_en.jpg);

			@media (min-width: 768px){
				background-image: url(/assets/img/home/originales/home_03_sm_en.jpg);
			}
			@media (min-width: 992px){
				background-image: url(/assets/img/home/originales/home_03_lg_en.jpg);
			}
			@media (min-width: 1200px){
				background-image: url(/assets/img/home/originales/home_03_lg_en.jpg);
			}
		}

		img{
			width: 224px;
			margin: 0 auto;
			display: block;

			@media (min-width: 992px){
				width: 325px;
			}
		}
	}

	@media (min-width: 992px){
		.contenedor-centrador{
			width: calc(100% - 213px);
			float: right; 
		}
	}

	.carousel-indicators{
		background-color: rgba(30,33,54,.8); 
		width: 100%;
		height: 41px;
		left: 0;
		bottom: 0;
		margin: 0; 

		@media (min-width: 992px){
			width: calc(100% - 213px);
			left: auto;
			right: 0;
			height: 73px;
			background-color: transparent; 
		}

		li{
			width: 11px;
			height: 11px;
			background-color: transparent;
			border-color: $white-color;
			margin: 14px 8px 0;
			transition: all 1s;

			&.active{
				background-color: white;
				border-color: white;
			}
		}
	}

	&.puntos_azules{
		@media (min-width: 992px){
			.carousel-indicators{
				li{
					width: 11px;
					height: 11px;
					background-color: transparent;
					border-color: $color-azul-oscuro;
					margin: 14px 8px 0;
		
					&.active{
						background-color: $color-azul-oscuro;
						border-color: $color-azul-oscuro;
					}
				}
			}
		}
	}
}