.thankyou {
	.esquina_derecha {
		background: none;
		display: none;
	}

	.seccion_1 {
		.container {
			position: relative;
		}

		position: relative;
		min-height: 480px;

		@media (min-width: 414px) {
			// * 1.29;
			min-height: 480px * 1.29;
		}

		@media (min-width: 768px) {
			// * 2.4;
			min-height: 480px * 2.4;
		}

		@media (min-width: ((1200px * 0.827))) {
			//*0.827;
			min-height: (610px + 50px) * 0.827;
		}

		@media (min-width: 1200px) {
			//*0.827;
			min-height: (610px + 50px);
		}

		@media (min-width: ((1200px*1.6))) {
			//*1.6;
			min-height: (610px + 50px) * 1.6;
		}

		@media (min-width: ((1200px*2))) {
			min-height: (610px + 50px) * 2;
		}

		.cuadrito_inicial {
			position: absolute;
			z-index: 11;

			width: 5px;
			height: 5px;
			top: 370px;
			right: 26px;

			@media (min-width: 414px) {
				// * 1.29;
				width: 5px * 1.29;
				height: 5px * 1.29;
				top: 370px * 1.29;
				right: 26px * 1.29;
			}

			@media (min-width: 768px) {
				// * 2.4;
				width: 5px * 2.4;
				height: 5px * 2.4;
				top: 370px * 2.4 - 100px;
				right: 26px * 2.4;
			}

			@media (min-width: ((1200px*0.827))) {
				//*0.827;
				width: 19px * 0.827;
				height: 19px * 0.827;
				top: 610px * 0.827;
				right: 5px * 0.827;
			}

			@media (min-width: 1200px) {
				width: 19px;
				height: 19px;
				top: 610px;
				right: 5px;
			}

			@media (min-width: ((1200px*1.6))) {
				//*1.6;
				width: 19px * 1.6;
				height: 19px * 1.6;
				top: 610px * 1.6;
				right: 5px * 1.6;
			}

			@media (min-width: ((1200px*2))) {
				//*2;
				width: 19px * 2;
				height: 19px * 2;
				top: 610px * 2;
				right: 5px * 2;
			}
		}

		line-height: 1.2;
		text-align: center;

		.imagen {
			display: inline-block;
			width: auto;
			height: 118px;
			margin-top: 43px;
			margin-bottom: 18px;

			@media (min-width: 414px) {
				height: 118px * 1.29;
				margin-top: 43px * 1.29;
				margin-bottom: 18px * 1.29;
			}

			@media (min-width: 768px) {
				height: 118px * 2.4;
				margin-top: 43px * 2.4;
				margin-bottom: 18px * 2.4;
			}

			@media (min-width: ((1200px*0.827))) {
				//*0.827;
				height: 196px * 0.827;
				margin-top: 38px * 0.827;
				margin-bottom: 7px * 0.827;
				margin-right: 40px * 0.827;
			}

			@media (min-width: 1200px) {
				height: 196px;
				margin-top: 38px;
				margin-bottom: 7px;
				margin-right: 40px;
			}

			@media (min-width: ((1200px*1.6))) {
				//*1.6;
				height: 196px * 1.6;
				margin-top: 38px * 1.6;
				margin-bottom: 7px * 1.6;
				margin-right: 40px * 1.6;
			}

			@media (min-width: ((1200px*2))) {
				//*2;
				height: 196px * 2;
				margin-top: 38px * 2;
				margin-bottom: 7px * 2;
				margin-right: 40px * 2;
			}
		}

		h1 {
			font-family: $fonts-Gotham-Medium;
			font-size: 40px;
			color: #3f3f3d;
			margin-bottom: 0px;

			@media (min-width: 414px) {
				font-size: 40px * 1.29;
			}

			@media (min-width: 768px) {
				font-size: 40px * 2.4;
			}

			@media (min-width: ((1200px*0.827))) {
				font-size: 115px * 0.827;
				margin-bottom: 0.5rem;
			}
			@media (min-width: 1200px) {
				font-size: 115px;
			}
			@media (min-width: ((1200px*1.6))) {
				font-size: 115px * 1.6;
			}
			@media (min-width: ((1200px*2))) {
				font-size: 115px * 2;
			}

			span {
				font-family: $fonts-Gotham-Black;
			}
		}

		p {
			font-family: $fonts-Gotham-Book;
			color: #3c3d38;

			font-size: 12px;
			margin-bottom: 40px;

			@media (min-width: 414px) {
				// * 1.29;
				font-size: 12px * 1.29;
				margin-bottom: 40px * 1.29;
			}

			@media (min-width: 768px) {
				// * 2.4;
				font-size: 12px * 2.4;
				margin-bottom: 40px * 2.4;
			}

			@media (min-width: ((1200px*0.827))) {
				//*0.827;
				letter-spacing: -0.016em;
				font-size: 20px * 0.827;
				margin-bottom: 32px * 0.827;
			}
			@media (min-width: 1200px) {
				font-size: 20px;
				margin-bottom: 32px;
			}
			@media (min-width: ((1200px*1.6))) {
				//*1.6;

				font-size: 20px * 1.6;
				margin-bottom: 32px * 1.6;
			}
			@media (min-width: ((1200px*2))) {
				//*2;
				font-size: 20px * 2;
				margin-bottom: 32px * 2;
			}
		}

		.contenedor-boton {
			display: flex;
			align-items: center;
			//width: 50vw;
			//width: 90%;
			margin: auto;

			position: relative;
			//padding: 30% 2em;
			box-sizing: border-box;

			color: #fff;
			background: #fff;
			background-clip: padding-box; /* !importanté */

			width: 230px;
			height: 30px;
			$border: 1px;
			border-radius: 30px;
			font-size: 10px;
			border: solid $border transparent; /* !importanté */

			@media (min-width: 414px) {
				// * 1.29;
				width: 230px * 1.29;
				height: 30px * 1.29;
				font-size: 10px * 1.29;
				border-radius: 30px * 1.29;
			}

			@media (min-width: 768px) {
				// * 2.4;
				width: 230px * 2.4;
				height: 30px * 2.4;
				font-size: 10px * 2.4;
				border-radius: 30px * 2.4;
			}

			@media (min-width: (1200px*0.827)) {
				//*0.827;
				width: 217px * 0.827;
				height: 40px * 0.827;

				border-radius: 40px * 0.827;
			}
			@media (min-width: 1200px) {
				width: 217px;
				height: 40px;

				border-radius: 40px;
			}
			@media (min-width: (1200px*1.6)) {
				//*1.6;
				width: 217px * 1.6;
				height: 40px * 1.6;
				$border: 2px;
				border-radius: 40px * 1.6;
				border: solid $border transparent; /* !importanté */
			}
			@media (min-width: (1200px*2)) {
				//*2;
				width: 217px * 2;
				height: 40px * 2;

				border-radius: 40px * 2;
			}

			&:before {
				content: '';
				position: absolute;
				top: 0;
				right: 0;
				bottom: 0;
				left: 0;
				z-index: -1;
				margin: -$border; /* !importanté */
				border-radius: inherit; /* !importanté */
				background: linear-gradient(
					90deg,
					rgba(106, 173, 223, 1) 0%,
					rgba(158, 27, 126, 1) 100%
				);

				$border: 1px;
				margin: -$border; /* !importanté */

				@media (min-width: (1200px*0.827)) {
					//*0.827;
					$border: 1px;
					margin: -$border; /* !importanté */
				}
				@media (min-width: 1200px) {
					$border: 1px;
					margin: -$border; /* !importanté */
				}
				@media (min-width: (1200px*1.6)) {
					//*1.6;
					$border: 2px;
					margin: -$border; /* !importanté */
				}
				@media (min-width: (1200px*2)) {
					//*2;
					$border: 2px;
					margin: -$border; /* !importanté */
				}
			}

			.go {
				text-decoration: none;
				display: flex;
				justify-content: center;
				align-items: center;
				width: 100%;
				height: 100%;
				font-family: $fonts-Gotham-Medium;
				color: #3f3f3d;

				font-size: 10px;

				@media (min-width: 414px) {
					// * 1.29;
					font-size: 10px * 1.29;
				}

				@media (min-width: 768px) {
					// * 2.4;
					font-size: 10px * 2.4;
				}

				@media (min-width: (1200px*0.827)) {
					//*0.827;
					font-size: 16px * 0.827;
				}
				@media (min-width: 1200px) {
					font-size: 16px;
				}
				@media (min-width: (1200px*1.6)) {
					//*1.6;
					font-size: 16px * 1.6;
				}
				@media (min-width: (1200px*2)) {
					//*2;
					font-size: 16px * 2;
				}

				img {
					margin-left: 10px;
					height: 10px;
					width: auto;

					@media (min-width: 414px) {
						// * 1.29;
						height: 10px * 1.29;
						margin-left: 10px * 1.29;
					}

					@media (min-width: 768px) {
						// * 2.4;
						height: 10px * 2.4;
						margin-left: 10px * 2.4;
					}

					@media (min-width: (1200px*0.827)) {
						//*0.827;
						height: 13px * 0.827;
						margin-left: 11px * 0.827;
					}
					@media (min-width: 1200px) {
						margin-left: 11px;
						height: 13px;
					}
					@media (min-width: (1200px*1.6)) {
						//*1.6;
						height: 13px * 1.6;
						margin-left: 11px * 1.6;
					}
					@media (min-width: (1200px*2)) {
						//*2;
						height: 13px * 2;
						margin-left: 11px * 2;
					}
				}
			}
		}
	}

	#footer_thankyou {
		//solo visible en pagina pequeñas

		position: fixed;
		background-color: #941b7e;
		width: 100%;
		height: 25px;
		bottom: 0;

		@media (min-width: 414px) {
			// * 1.29;
			height: 25px * 1.29;
		}

		@media (min-width: 768px) {
			// * 2.4;
			height: 25px * 2.4;
		}

		@media (min-width: (1200px * 0.827)) {
			//*0.827;
			display: none;
		}
	}
}
