@charset "UTF-8";
/*
  This boilerplate is based in ITCSS and SMACSS
*/
.relative {
  position: relative;
}

.inline-block {
  display: inline-block;
}

.contenedor-centrador {
  display: table;
  height: 100%;
  width: 100%;
}

.centrador {
  display: table-cell;
  vertical-align: middle;
}

.has-error input {
  border-color: #c40044 !important;
}

.opacity_0 * {
  opacity: 0 !important;
}

.degradado_1 {
  background: #6aaddf;
  background: -moz-linear-gradient(90deg, #6aaddf 0%, #9e1b7e 100%);
  background: -webkit-linear-gradient(90deg, #6aaddf 0%, #9e1b7e 100%);
  background: linear-gradient(90deg, #6aaddf 0%, #9e1b7e 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#6aaddf",endColorstr="#9e1b7e",GradientType=1);
}

.degradado_2 {
  background: #6aaddf;
  background: -moz-linear-gradient(90deg, #6aaddf 0%, #a6bb35 100%);
  background: -webkit-linear-gradient(90deg, #6aaddf 0%, #a6bb35 100%);
  background: linear-gradient(90deg, #6aaddf 0%, #a6bb35 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#6aaddf",endColorstr="#a6bb35",GradientType=1);
}

.degradado_3 {
  background: #b53925;
  background: -moz-linear-gradient(90deg, #b53925 0%, #c7802a 100%);
  background: -webkit-linear-gradient(90deg, #b53925 0%, #c7802a 100%);
  background: linear-gradient(90deg, #b53925 0%, #c7802a 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#B53925",endColorstr="#C7802A",GradientType=1);
}

.delay-100m {
  animation-delay: 100ms;
}

.delay-200m {
  animation-delay: 200ms;
}

.delay-300m {
  animation-delay: 300ms;
}

.delay-400m {
  animation-delay: 400ms;
}

.delay-500m {
  animation-delay: 500ms;
}

.delay-600m {
  animation-delay: 600ms;
}

.delay-700m {
  animation-delay: 700ms;
}

.delay-800m {
  animation-delay: 800ms;
}

.delay-900m {
  animation-delay: 900ms;
}

@font-face {
  src: url("../fonts/Gotham-Black.otf");
  font-family: Gotham-Black;
  font-display: swap;
}

@font-face {
  src: url("../fonts/Gotham-Medium.otf");
  font-family: Gotham-Medium;
  font-display: swap;
}

@font-face {
  src: url("../fonts/Gotham-MediumItalic.otf");
  font-family: Gotham-MediumItalic;
  font-display: swap;
}

@font-face {
  src: url("../fonts/Gotham-Bold.otf");
  font-family: Gotham-Bold;
  font-display: swap;
}

@font-face {
  src: url("../fonts/Gotham-Book.otf");
  font-family: Gotham-Book;
  font-display: swap;
}

@font-face {
  src: url("../fonts/Gotham-BookItalic.otf");
  font-family: Gotham-Book-Italic;
  font-display: swap;
}

@font-face {
  src: url("../fonts/Gotham-LightItalic.otf");
  font-family: Gotham-LightItalic;
  font-display: swap;
}

body {
  position: relative;
}

html {
  min-width: 320px;
  scroll-behavior: smooth;
}

.container {
  max-width: 1140px;
}

@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }
}

@media (min-width: 1400px) {
  .container {
    max-width: 1140px !important;
  }
}

@media (min-width: 1920px) {
  .container {
    max-width: 1824px !important;
  }
}

@media (min-width: 2400px) {
  .container {
    max-width: 2280px !important;
  }
}

textarea {
  resize: none;
}

video {
  background-color: #000;
}

/*
html,
body{
	height: 100%;
}

body{
	position: relative;
	height: 100%;
}
@media (min-width: 768px) {
	body{
		height: initial;
	}
}

html{
    min-height: 480px;

	@media (min-width: 768px) {
		min-height: 1024px;
	}
	@media (min-width: 992px) {
		min-height: 768px;
	}
	@media (min-width: 1200px) {
		min-height: 720px;
	}
	@media (min-width: 2400px) {
		min-height: 1440px;
	}
}*/
#carousel-home {
  width: 100vw;
  height: calc(100vh - 39px);
}

@media (min-width: 768px) {
  #carousel-home {
    height: calc(100vh - 92px);
  }
}

@media (min-width: 992px) {
  #carousel-home {
    height: 100vh;
  }
}

#carousel-home .carousel-inner,
#carousel-home .carousel-item,
#carousel-home .item {
  width: 100%;
  height: 100%;
}

#carousel-home img {
  animation-duration: 3.5s;
}

#carousel-home .slide {
  background-size: cover;
  background-position: 50% 50%;
  width: 100%;
  height: 100%;
}

#carousel-home .slide img {
  animation-delay: 1s;
}

#carousel-home .slide_1 {
  background-image: url(/assets/img/home/originales/home_01_xs.jpg);
  background-position: 80% 20%;
}

@media (min-width: 768px) {
  #carousel-home .slide_1 {
    background-image: url(/assets/img/home/originales/home_01_sm.jpg);
  }
}

@media (min-width: 992px) {
  #carousel-home .slide_1 {
    background-image: url(/assets/img/home/originales/home_01_md.jpg);
  }
}

@media (min-width: 1200px) {
  #carousel-home .slide_1 {
    background-image: url(/assets/img/home/originales/home_01_lg.jpg);
  }
}

#carousel-home .slide_1.ingles {
  background-image: url(/assets/img/home/originales/home_01_xs_en.jpg);
}

@media (min-width: 768px) {
  #carousel-home .slide_1.ingles {
    background-image: url(/assets/img/home/originales/home_01_sm_en.jpg);
  }
}

@media (min-width: 992px) {
  #carousel-home .slide_1.ingles {
    background-image: url(/assets/img/home/originales/home_01_md_en.jpg);
  }
}

@media (min-width: 1200px) {
  #carousel-home .slide_1.ingles {
    background-image: url(/assets/img/home/originales/home_01_lg_en.jpg);
  }
}

#carousel-home .slide_1 img {
  width: 277px;
  margin: 0 auto;
  display: block;
}

@media (min-width: 992px) {
  #carousel-home .slide_1 img {
    width: 393px;
  }
}

#carousel-home .slide_2 {
  background-image: url(/assets/img/home/originales/home_002_xs.svg);
}

@media (min-width: 992px) {
  #carousel-home .slide_2 {
    background-image: url(/assets/img/home/originales/home_002_md.svg);
  }
}

#carousel-home .slide_2.ingles {
  background-image: url(/assets/img/home/originales/home_002_xs_en.svg);
}

@media (min-width: 992px) {
  #carousel-home .slide_2.ingles {
    background-image: url(/assets/img/home/originales/home_002_md_en.svg);
  }
}

#carousel-home .slide_2 img {
  width: 262px;
  margin: 0 auto;
  display: block;
}

@media (min-width: 992px) {
  #carousel-home .slide_2 img {
    width: 470px;
  }
}

#carousel-home .slide_3 {
  background-image: url(/assets/img/home/originales/home_03_xs.jpg);
}

@media (min-width: 768px) {
  #carousel-home .slide_3 {
    background-image: url(/assets/img/home/originales/home_03_sm.jpg);
    background-position: 80% 0%;
  }
}

@media (min-width: 992px) {
  #carousel-home .slide_3 {
    background-image: url(/assets/img/home/originales/home_03_md.jpg);
  }
}

@media (min-width: 1200px) {
  #carousel-home .slide_3 {
    background-image: url(/assets/img/home/originales/home_03_lg.jpg);
  }
}

#carousel-home .slide_3.ingles {
  background-image: url(/assets/img/home/originales/home_03_xs_en.jpg);
}

@media (min-width: 768px) {
  #carousel-home .slide_3.ingles {
    background-image: url(/assets/img/home/originales/home_03_sm_en.jpg);
  }
}

@media (min-width: 992px) {
  #carousel-home .slide_3.ingles {
    background-image: url(/assets/img/home/originales/home_03_lg_en.jpg);
  }
}

@media (min-width: 1200px) {
  #carousel-home .slide_3.ingles {
    background-image: url(/assets/img/home/originales/home_03_lg_en.jpg);
  }
}

#carousel-home .slide_3 img {
  width: 224px;
  margin: 0 auto;
  display: block;
}

@media (min-width: 992px) {
  #carousel-home .slide_3 img {
    width: 325px;
  }
}

@media (min-width: 992px) {
  #carousel-home .contenedor-centrador {
    width: calc(100% - 213px);
    float: right;
  }
}

#carousel-home .carousel-indicators {
  background-color: rgba(30, 33, 54, 0.8);
  width: 100%;
  height: 41px;
  left: 0;
  bottom: 0;
  margin: 0;
}

@media (min-width: 992px) {
  #carousel-home .carousel-indicators {
    width: calc(100% - 213px);
    left: auto;
    right: 0;
    height: 73px;
    background-color: transparent;
  }
}

#carousel-home .carousel-indicators li {
  width: 11px;
  height: 11px;
  background-color: transparent;
  border-color: #ffffff;
  margin: 14px 8px 0;
  transition: all 1s;
}

#carousel-home .carousel-indicators li.active {
  background-color: white;
  border-color: white;
}

@media (min-width: 992px) {
  #carousel-home.puntos_azules .carousel-indicators li {
    width: 11px;
    height: 11px;
    background-color: transparent;
    border-color: #1c2441;
    margin: 14px 8px 0;
  }
  #carousel-home.puntos_azules .carousel-indicators li.active {
    background-color: #1c2441;
    border-color: #1c2441;
  }
}

footer {
  height: 94px;
  background-color: #1c2441;
  display: block;
  padding-top: 25px;
}

@media (min-width: 992px) {
  footer {
    padding-top: 50px;
    height: 187px;
  }
}

footer img {
  width: 81px;
  display: block;
  margin: 0 auto 0;
}

@media (min-width: 992px) {
  footer img {
    width: 181px;
  }
}

/*#jarallax-container-0{
    display: none;
}

@media (min-width: 768px) {

    #jarallax-container-0{
        display: block;
    }
    #jarallax-container-0 > div{
        background-color: rgba(0,0,0,.3);
        background-blend-mode: multiply;
    }
}*/
@media (max-width: 767px) {
  .jarallax,
  .jarallax #jarallax-container-0 * {
    position: relative;
    z-index: 0;
    transition: height .5s;
  }
}

.jarallax > .jarallax-img {
  position: absolute;
  object-fit: cover;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

/*
	Estilos del menu
*/
.esquina_derecha {
  background-image: url(../img/home/esquina_derecha.svg);
  background-position: 0% 100%;
  background-repeat: no-repeat;
  top: 0;
  right: 0;
  position: absolute;
  z-index: -1;
  width: 62.5%;
  height: 800px;
  background-size: 1371px auto;
}

@media (min-width: 992.4px) {
  .esquina_derecha {
    height: 661.6px;
    background-size: 1133.817px auto;
  }
}

@media (min-width: 1200px) {
  .esquina_derecha {
    height: 800px;
    background-size: 1371px auto;
  }
}

@media (min-width: 1920px) {
  .esquina_derecha {
    height: 1280px;
    background-size: 2193.6px auto;
  }
}

@media (min-width: 2400px) {
  .esquina_derecha {
    height: 1600px;
    background-size: 2742px auto;
  }
}

nav.navbar {
  padding: 0;
}

nav.navbar .container {
  padding: 0;
  padding-top: 20px;
  padding-bottom: 20px;
  position: relative;
}

@media (min-width: 414px) {
  nav.navbar .container {
    padding-top: 25.8px;
    padding-bottom: 25.8px;
  }
}

@media (min-width: 768px) {
  nav.navbar .container {
    padding-top: 48px;
    padding-bottom: 48px;
  }
}

@media (min-width: 992.4px) {
  nav.navbar .container {
    padding-top: 31.426px;
    padding-bottom: 31.426px;
  }
}

@media (min-width: 1200px) {
  nav.navbar .container {
    padding-top: 38px;
    padding-bottom: 38px;
  }
}

@media (min-width: 1920px) {
  nav.navbar .container {
    padding-top: 60.8px;
    padding-bottom: 60.8px;
  }
}

@media (min-width: 2400px) {
  nav.navbar .container {
    padding-top: 76px;
    padding-bottom: 76px;
  }
}

nav.navbar .navbar-brand {
  padding: 0;
  margin-left: 20px;
  font-size: 13px;
}

@media (min-width: 414px) {
  nav.navbar .navbar-brand {
    margin-left: 25.8px;
  }
}

@media (min-width: 768px) {
  nav.navbar .navbar-brand {
    margin-left: 48px;
  }
}

@media (min-width: 992.4px) {
  nav.navbar .navbar-brand {
    margin-left: 0;
  }
}

nav.navbar .navbar-brand img {
  height: 25.7px;
  width: auto;
}

@media (min-width: 414px) {
  nav.navbar .navbar-brand img {
    height: 33.153px;
  }
}

@media (min-width: 768px) {
  nav.navbar .navbar-brand img {
    height: 61.68px;
  }
}

@media (min-width: 992.4px) {
  nav.navbar .navbar-brand img {
    height: 46.312px;
  }
}

@media (min-width: 1200px) {
  nav.navbar .navbar-brand img {
    height: 56px;
  }
}

@media (min-width: 1920px) {
  nav.navbar .navbar-brand img {
    height: 89.6px;
  }
}

@media (min-width: 2400px) {
  nav.navbar .navbar-brand img {
    height: 112px;
  }
}

nav.navbar #icon-menu {
  cursor: pointer;
  z-index: 20;
  width: 22px;
  height: 16px;
  padding: 0px;
  margin-right: 20px;
}

@media (min-width: 414px) {
  nav.navbar #icon-menu {
    width: 28.38px;
    height: 20.64px;
    margin-right: 25.8px;
  }
}

@media (min-width: 768px) {
  nav.navbar #icon-menu {
    width: 52.8px;
    height: 38.4px;
    margin-right: 48px;
  }
}

@media (min-width: 992.4px) {
  nav.navbar #icon-menu {
    width: 38.042px;
    height: 38.042px;
    padding: 3.308px;
    margin-right: 0;
  }
}

@media (min-width: 1200px) {
  nav.navbar #icon-menu {
    width: 46px;
    height: 46px;
    padding: 4px;
  }
}

@media (min-width: 1920px) {
  nav.navbar #icon-menu {
    width: 73.6px;
    height: 73.6px;
    padding: 6.4px;
  }
}

@media (min-width: 2400px) {
  nav.navbar #icon-menu {
    width: 92px;
    height: 92px;
    padding: 8px;
  }
}

nav.navbar #icon-menu .cls-1 {
  opacity: 1;
  transition: opacity, stroke 0.2s ease;
}

nav.navbar #icon-menu.active .cls-none {
  opacity: 0;
}

nav.navbar #icon-menu.active .cls-1 {
  stroke: #f1efda;
}

.menu_principal {
  top: 0;
  position: absolute;
  z-index: 15;
  background-color: #6aaee0;
  outline: 0;
  transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;
  display: none;
  right: 0px;
  width: 290px;
  height: 480px;
  padding: 110px 70px 0 38px;
}

@media (min-width: 414px) {
  .menu_principal {
    width: 374.1px;
  }
}

@media (min-width: 992.4px) {
  .menu_principal {
    right: -16px;
    width: 314.26px;
    height: 413.5px;
    padding: 90.97px 57.89px 0 31.426px;
  }
}

@media (min-width: 1024px) {
  .menu_principal {
    right: -32px;
  }
}

@media (min-width: 1200px) {
  .menu_principal {
    right: -30px;
    width: 380px;
    height: 500px;
    padding: 110px 70px 0 38px;
  }
}

@media (min-width: 1280px) {
  .menu_principal {
    right: -70px;
  }
}

@media (min-width: 1920px) {
  .menu_principal {
    right: -48px;
    width: 608px;
    height: 800px;
    padding: 176px 112px 0 60.8px;
  }
}

@media (min-width: 2048px) {
  .menu_principal {
    right: -112px;
  }
}

@media (min-width: 2400px) {
  .menu_principal {
    right: -60px;
    width: 760px;
    height: 1000px;
    padding: 220px 140px 0 76px;
  }
}

@media (min-width: 2560px) {
  .menu_principal {
    right: -140px;
  }
}

.menu_principal.active {
  display: block;
}

.menu_principal ul {
  padding-left: 0;
}

.menu_principal ul li {
  list-style: none;
  padding-left: 0;
  display: block;
  font-family: "Gotham-Book";
  padding-top: 8px;
  padding-bottom: 8px;
}

@media (min-width: 992.4px) {
  .menu_principal ul li {
    padding-top: 6.616px;
    padding-bottom: 6.616px;
  }
}

@media (min-width: 1200px) {
  .menu_principal ul li {
    padding-top: 8px;
    padding-bottom: 8px;
  }
}

@media (min-width: 1920px) {
  .menu_principal ul li {
    padding-top: 12.8px;
    padding-bottom: 12.8px;
  }
}

@media (min-width: 2400px) {
  .menu_principal ul li {
    padding-top: 16px;
    padding-bottom: 16px;
  }
}

.menu_principal ul li .logo {
  height: 35px;
}

@media (min-width: 992.4px) {
  .menu_principal ul li .logo {
    height: 28.945px;
  }
}

@media (min-width: 1200px) {
  .menu_principal ul li .logo {
    height: 35px;
  }
}

@media (min-width: 1920px) {
  .menu_principal ul li .logo {
    height: 56px;
  }
}

@media (min-width: 2400px) {
  .menu_principal ul li .logo {
    height: 70px;
  }
}

.menu_principal ul li a {
  color: #f1efda;
  text-decoration: none;
  display: block;
  font-size: 15px;
}

@media (min-width: 992.4px) {
  .menu_principal ul li a {
    font-size: 12.405px;
  }
}

@media (min-width: 1200px) {
  .menu_principal ul li a {
    font-size: 15px;
  }
}

@media (min-width: 1920px) {
  .menu_principal ul li a {
    font-size: 24px;
  }
}

@media (min-width: 2400px) {
  .menu_principal ul li a {
    font-size: 30px;
  }
}

.menu_principal ul li a.active, .menu_principal ul li a:hover {
  font-family: "Gotham-Bold";
}

.menu_principal ul li.submenu ul {
  transform: scaleY(0);
  transform-origin: 0 0;
  transition: transform 0.4s 0s ease, max-height 0.1s 0.3s ease;
  max-height: 0;
  padding-left: 15px;
}

@media (min-width: 992.4px) {
  .menu_principal ul li.submenu ul {
    padding-left: 12.405px;
  }
}

@media (min-width: 1200px) {
  .menu_principal ul li.submenu ul {
    padding-left: 15px;
  }
}

@media (min-width: 1920px) {
  .menu_principal ul li.submenu ul {
    padding-left: 24px;
  }
}

@media (min-width: 2400px) {
  .menu_principal ul li.submenu ul {
    padding-left: 30px;
  }
}

.menu_principal ul li.submenu ul li {
  padding-top: 3px;
  padding-bottom: 3px;
}

@media (min-width: 992.4px) {
  .menu_principal ul li.submenu ul li {
    padding-top: 2.481px;
    padding-bottom: 2.481px;
  }
}

@media (min-width: 1200px) {
  .menu_principal ul li.submenu ul li {
    padding-top: 3px;
    padding-bottom: 3px;
  }
}

@media (min-width: 1920px) {
  .menu_principal ul li.submenu ul li {
    padding-top: 4.8px;
    padding-bottom: 4.8px;
  }
}

@media (min-width: 2400px) {
  .menu_principal ul li.submenu ul li {
    padding-top: 6px;
    padding-bottom: 6px;
  }
}

.menu_principal ul li.submenu ul li a {
  font-size: 12px;
  letter-spacing: -0.035em;
}

@media (min-width: 992.4px) {
  .menu_principal ul li.submenu ul li a {
    font-size: 9.924px;
  }
}

@media (min-width: 1200px) {
  .menu_principal ul li.submenu ul li a {
    font-size: 12px;
  }
}

@media (min-width: 1920px) {
  .menu_principal ul li.submenu ul li a {
    font-size: 19.2px;
  }
}

@media (min-width: 2400px) {
  .menu_principal ul li.submenu ul li a {
    font-size: 24px;
  }
}

.menu_principal ul li.submenu.open ul {
  max-height: 100px;
  transform: scaleY(100%);
  transition: transform 0.4s 0.3s ease, max-height 0.3s 0.1s ease;
}

@media (min-width: 992.4px) {
  .menu_principal ul li.submenu.open ul {
    max-height: 82.7px;
  }
}

@media (min-width: 1200px) {
  .menu_principal ul li.submenu.open ul {
    max-height: 100px;
  }
}

@media (min-width: 1920px) {
  .menu_principal ul li.submenu.open ul {
    max-height: 160px;
  }
}

@media (min-width: 2400px) {
  .menu_principal ul li.submenu.open ul {
    max-height: 200px;
  }
}

.menu_principal .rrss {
  width: 100%;
  position: absolute;
  left: 0;
  bottom: 170px;
  padding-right: 73px;
}

@media (min-width: 992.4px) {
  .menu_principal .rrss {
    bottom: 140.59px;
    padding-right: 60.371px;
  }
}

@media (min-width: 1200px) {
  .menu_principal .rrss {
    bottom: 170px;
    padding-right: 73px;
  }
}

@media (min-width: 1920px) {
  .menu_principal .rrss {
    bottom: 272px;
    padding-right: 116.8px;
  }
}

@media (min-width: 2400px) {
  .menu_principal .rrss {
    bottom: 340px;
    padding-right: 146px;
  }
}

.menu_principal .rrss .lenguaje {
  text-align: right;
  font-family: "Gotham-Book";
  color: #f1efda;
  font-size: 12px;
  margin-bottom: 8px;
}

@media (min-width: 992.4px) {
  .menu_principal .rrss .lenguaje {
    font-size: 9.924px;
    margin-bottom: 6.616px;
  }
}

@media (min-width: 1200px) {
  .menu_principal .rrss .lenguaje {
    font-size: 12px;
    margin-bottom: 8px;
  }
}

@media (min-width: 1920px) {
  .menu_principal .rrss .lenguaje {
    font-size: 19.2px;
    margin-bottom: 12.8px;
  }
}

@media (min-width: 2400px) {
  .menu_principal .rrss .lenguaje {
    font-size: 24px;
    margin-bottom: 16px;
  }
}

.menu_principal .rrss .lenguaje a {
  font-family: "Gotham-Book";
  color: #f1efda;
  text-decoration: none;
  font-size: 12px;
}

@media (min-width: 992.4px) {
  .menu_principal .rrss .lenguaje a {
    font-size: 9.924px;
  }
}

@media (min-width: 1200px) {
  .menu_principal .rrss .lenguaje a {
    font-size: 12px;
  }
}

@media (min-width: 1920px) {
  .menu_principal .rrss .lenguaje a {
    font-size: 19.2px;
  }
}

@media (min-width: 2400px) {
  .menu_principal .rrss .lenguaje a {
    font-size: 24px;
  }
}

.menu_principal .rrss .lenguaje a.active {
  font-family: "Gotham-Bold";
}

.menu_principal .rrss .iconos {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.menu_principal .rrss .iconos .icono {
  margin-left: 6px;
}

@media (min-width: 992.4px) {
  .menu_principal .rrss .iconos .icono {
    margin-left: 4.962px;
  }
}

@media (min-width: 1200px) {
  .menu_principal .rrss .iconos .icono {
    margin-left: 6px;
  }
}

@media (min-width: 1920px) {
  .menu_principal .rrss .iconos .icono {
    margin-left: 9.6px;
  }
}

@media (min-width: 2400px) {
  .menu_principal .rrss .iconos .icono {
    margin-left: 12px;
  }
}

.menu_principal .rrss .iconos .icono img {
  height: 22.85px;
}

@media (min-width: 992.4px) {
  .menu_principal .rrss .iconos .icono img {
    height: 18.89695px;
  }
}

@media (min-width: 1200px) {
  .menu_principal .rrss .iconos .icono img {
    height: 22.85px;
  }
}

@media (min-width: 1920px) {
  .menu_principal .rrss .iconos .icono img {
    height: 36.56px;
  }
}

@media (min-width: 2400px) {
  .menu_principal .rrss .iconos .icono img {
    height: 45.7px;
  }
}

.menu_principal .cuadrito {
  background-color: #f1efda;
  position: absolute;
  width: 7.6px;
  height: 7.6px;
  bottom: 200px;
  left: 38px;
}

@media (min-width: 992.4px) {
  .menu_principal .cuadrito {
    width: 6.2852px;
    height: 6.2852px;
    bottom: 165.4px;
    left: 31.426px;
  }
}

@media (min-width: 1200px) {
  .menu_principal .cuadrito {
    width: 7.6px;
    height: 7.6px;
    bottom: 200px;
    left: 38px;
  }
}

@media (min-width: 1920px) {
  .menu_principal .cuadrito {
    width: 12.16px;
    height: 12.16px;
    bottom: 320px;
    left: 60.8px;
  }
}

@media (min-width: 2400px) {
  .menu_principal .cuadrito {
    width: 15.2px;
    height: 15.2px;
    bottom: 400px;
    left: 76px;
  }
}

.menu_principal .leyenda {
  position: absolute;
  font-family: "Gotham-Bold";
  color: #f1efda;
  line-height: 1;
  z-index: 1;
  letter-spacing: 0.05em;
  bottom: 91px;
  left: -30px;
  font-size: 38px;
}

@media (min-width: 992.4px) {
  .menu_principal .leyenda {
    bottom: 75.257px;
    left: -49.62px;
    font-size: 37.215px;
  }
}

@media (min-width: 1200px) {
  .menu_principal .leyenda {
    bottom: 91px;
    left: -60px;
    font-size: 45px;
  }
}

@media (min-width: 1920px) {
  .menu_principal .leyenda {
    bottom: 145.6px;
    left: -96px;
    font-size: 72px;
  }
}

@media (min-width: 2400px) {
  .menu_principal .leyenda {
    bottom: 182px;
    left: -120px;
    font-size: 90px;
  }
}

.menu_principal .img-bottom {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background-image: url(../img/navbar/img-bottom.webp);
  background-size: cover;
  background-position: 50% 50%;
  height: 114px;
}

@media (min-width: 992.4px) {
  .menu_principal .img-bottom {
    height: 94.278px;
  }
}

@media (min-width: 1200px) {
  .menu_principal .img-bottom {
    height: 114px;
  }
}

@media (min-width: 1920px) {
  .menu_principal .img-bottom {
    height: 182.4px;
  }
}

@media (min-width: 2400px) {
  .menu_principal .img-bottom {
    height: 228px;
  }
}

#overlay_body {
  display: none;
}

#overlay_body.active {
  display: block;
  position: fixed;
  z-index: 12;
  background-color: #3f3f3d;
  opacity: 0.5;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.progress-big {
  height: 40px;
}

.progress-big > div {
  font-size: 30px;
  line-height: 37px;
}

.video iframe {
  height: 200px;
  display: block;
}

@media (min-width: 360px) {
  .video iframe {
    height: 316px;
  }
}

@media (min-width: 768px) {
  .video iframe {
    height: 370px;
  }
}

@media (min-width: 992px) {
  .video iframe {
    height: 413px;
  }
}

@media (min-width: 1200px) {
  .video iframe {
    height: 516px;
  }
}

@media (min-width: 2400px) {
  .video iframe {
    height: 1032px;
  }
}

.thankyou .esquina_derecha {
  background: none;
  display: none;
}

.thankyou .seccion_1 {
  position: relative;
  min-height: 480px;
  line-height: 1.2;
  text-align: center;
}

.thankyou .seccion_1 .container {
  position: relative;
}

@media (min-width: 414px) {
  .thankyou .seccion_1 {
    min-height: 619.2px;
  }
}

@media (min-width: 768px) {
  .thankyou .seccion_1 {
    min-height: 1152px;
  }
}

@media (min-width: 992.4px) {
  .thankyou .seccion_1 {
    min-height: 545.82px;
  }
}

@media (min-width: 1200px) {
  .thankyou .seccion_1 {
    min-height: 660px;
  }
}

@media (min-width: 1920px) {
  .thankyou .seccion_1 {
    min-height: 1056px;
  }
}

@media (min-width: 2400px) {
  .thankyou .seccion_1 {
    min-height: 1320px;
  }
}

.thankyou .seccion_1 .cuadrito_inicial {
  position: absolute;
  z-index: 11;
  width: 5px;
  height: 5px;
  top: 370px;
  right: 26px;
}

@media (min-width: 414px) {
  .thankyou .seccion_1 .cuadrito_inicial {
    width: 6.45px;
    height: 6.45px;
    top: 477.3px;
    right: 33.54px;
  }
}

@media (min-width: 768px) {
  .thankyou .seccion_1 .cuadrito_inicial {
    width: 12px;
    height: 12px;
    top: 788px;
    right: 62.4px;
  }
}

@media (min-width: 992.4px) {
  .thankyou .seccion_1 .cuadrito_inicial {
    width: 15.713px;
    height: 15.713px;
    top: 504.47px;
    right: 4.135px;
  }
}

@media (min-width: 1200px) {
  .thankyou .seccion_1 .cuadrito_inicial {
    width: 19px;
    height: 19px;
    top: 610px;
    right: 5px;
  }
}

@media (min-width: 1920px) {
  .thankyou .seccion_1 .cuadrito_inicial {
    width: 30.4px;
    height: 30.4px;
    top: 976px;
    right: 8px;
  }
}

@media (min-width: 2400px) {
  .thankyou .seccion_1 .cuadrito_inicial {
    width: 38px;
    height: 38px;
    top: 1220px;
    right: 10px;
  }
}

.thankyou .seccion_1 .imagen {
  display: inline-block;
  width: auto;
  height: 118px;
  margin-top: 43px;
  margin-bottom: 18px;
}

@media (min-width: 414px) {
  .thankyou .seccion_1 .imagen {
    height: 152.22px;
    margin-top: 55.47px;
    margin-bottom: 23.22px;
  }
}

@media (min-width: 768px) {
  .thankyou .seccion_1 .imagen {
    height: 283.2px;
    margin-top: 103.2px;
    margin-bottom: 43.2px;
  }
}

@media (min-width: 992.4px) {
  .thankyou .seccion_1 .imagen {
    height: 162.092px;
    margin-top: 31.426px;
    margin-bottom: 5.789px;
    margin-right: 33.08px;
  }
}

@media (min-width: 1200px) {
  .thankyou .seccion_1 .imagen {
    height: 196px;
    margin-top: 38px;
    margin-bottom: 7px;
    margin-right: 40px;
  }
}

@media (min-width: 1920px) {
  .thankyou .seccion_1 .imagen {
    height: 313.6px;
    margin-top: 60.8px;
    margin-bottom: 11.2px;
    margin-right: 64px;
  }
}

@media (min-width: 2400px) {
  .thankyou .seccion_1 .imagen {
    height: 392px;
    margin-top: 76px;
    margin-bottom: 14px;
    margin-right: 80px;
  }
}

.thankyou .seccion_1 h1 {
  font-family: "Gotham-Medium";
  font-size: 40px;
  color: #3f3f3d;
  margin-bottom: 0px;
}

@media (min-width: 414px) {
  .thankyou .seccion_1 h1 {
    font-size: 51.6px;
  }
}

@media (min-width: 768px) {
  .thankyou .seccion_1 h1 {
    font-size: 96px;
  }
}

@media (min-width: 992.4px) {
  .thankyou .seccion_1 h1 {
    font-size: 95.105px;
    margin-bottom: 0.5rem;
  }
}

@media (min-width: 1200px) {
  .thankyou .seccion_1 h1 {
    font-size: 115px;
  }
}

@media (min-width: 1920px) {
  .thankyou .seccion_1 h1 {
    font-size: 184px;
  }
}

@media (min-width: 2400px) {
  .thankyou .seccion_1 h1 {
    font-size: 230px;
  }
}

.thankyou .seccion_1 h1 span {
  font-family: "Gotham-Black";
}

.thankyou .seccion_1 p {
  font-family: "Gotham-Book";
  color: #3c3d38;
  font-size: 12px;
  margin-bottom: 40px;
}

@media (min-width: 414px) {
  .thankyou .seccion_1 p {
    font-size: 15.48px;
    margin-bottom: 51.6px;
  }
}

@media (min-width: 768px) {
  .thankyou .seccion_1 p {
    font-size: 28.8px;
    margin-bottom: 96px;
  }
}

@media (min-width: 992.4px) {
  .thankyou .seccion_1 p {
    letter-spacing: -0.016em;
    font-size: 16.54px;
    margin-bottom: 26.464px;
  }
}

@media (min-width: 1200px) {
  .thankyou .seccion_1 p {
    font-size: 20px;
    margin-bottom: 32px;
  }
}

@media (min-width: 1920px) {
  .thankyou .seccion_1 p {
    font-size: 32px;
    margin-bottom: 51.2px;
  }
}

@media (min-width: 2400px) {
  .thankyou .seccion_1 p {
    font-size: 40px;
    margin-bottom: 64px;
  }
}

.thankyou .seccion_1 .contenedor-boton {
  display: flex;
  align-items: center;
  margin: auto;
  position: relative;
  box-sizing: border-box;
  color: #fff;
  background: #fff;
  background-clip: padding-box;
  /* !importanté */
  width: 230px;
  height: 30px;
  border-radius: 30px;
  font-size: 10px;
  border: solid 1px transparent;
  /* !importanté */
}

@media (min-width: 414px) {
  .thankyou .seccion_1 .contenedor-boton {
    width: 296.7px;
    height: 38.7px;
    font-size: 12.9px;
    border-radius: 38.7px;
  }
}

@media (min-width: 768px) {
  .thankyou .seccion_1 .contenedor-boton {
    width: 552px;
    height: 72px;
    font-size: 24px;
    border-radius: 72px;
  }
}

@media (min-width: 992.4px) {
  .thankyou .seccion_1 .contenedor-boton {
    width: 179.459px;
    height: 33.08px;
    border-radius: 33.08px;
  }
}

@media (min-width: 1200px) {
  .thankyou .seccion_1 .contenedor-boton {
    width: 217px;
    height: 40px;
    border-radius: 40px;
  }
}

@media (min-width: 1920px) {
  .thankyou .seccion_1 .contenedor-boton {
    width: 347.2px;
    height: 64px;
    border-radius: 64px;
    border: solid 2px transparent;
    /* !importanté */
  }
}

@media (min-width: 2400px) {
  .thankyou .seccion_1 .contenedor-boton {
    width: 434px;
    height: 80px;
    border-radius: 80px;
  }
}

.thankyou .seccion_1 .contenedor-boton:before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  margin: -2px;
  /* !importanté */
  border-radius: inherit;
  /* !importanté */
  background: linear-gradient(90deg, #6aaddf 0%, #9e1b7e 100%);
  margin: -1px;
  /* !importanté */
}

@media (min-width: 992.4px) {
  .thankyou .seccion_1 .contenedor-boton:before {
    margin: -1px;
    /* !importanté */
  }
}

@media (min-width: 1200px) {
  .thankyou .seccion_1 .contenedor-boton:before {
    margin: -1px;
    /* !importanté */
  }
}

@media (min-width: 1920px) {
  .thankyou .seccion_1 .contenedor-boton:before {
    margin: -2px;
    /* !importanté */
  }
}

@media (min-width: 2400px) {
  .thankyou .seccion_1 .contenedor-boton:before {
    margin: -2px;
    /* !importanté */
  }
}

.thankyou .seccion_1 .contenedor-boton .go {
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  font-family: "Gotham-Medium";
  color: #3f3f3d;
  font-size: 10px;
}

@media (min-width: 414px) {
  .thankyou .seccion_1 .contenedor-boton .go {
    font-size: 12.9px;
  }
}

@media (min-width: 768px) {
  .thankyou .seccion_1 .contenedor-boton .go {
    font-size: 24px;
  }
}

@media (min-width: 992.4px) {
  .thankyou .seccion_1 .contenedor-boton .go {
    font-size: 13.232px;
  }
}

@media (min-width: 1200px) {
  .thankyou .seccion_1 .contenedor-boton .go {
    font-size: 16px;
  }
}

@media (min-width: 1920px) {
  .thankyou .seccion_1 .contenedor-boton .go {
    font-size: 25.6px;
  }
}

@media (min-width: 2400px) {
  .thankyou .seccion_1 .contenedor-boton .go {
    font-size: 32px;
  }
}

.thankyou .seccion_1 .contenedor-boton .go img {
  margin-left: 10px;
  height: 10px;
  width: auto;
}

@media (min-width: 414px) {
  .thankyou .seccion_1 .contenedor-boton .go img {
    height: 12.9px;
    margin-left: 12.9px;
  }
}

@media (min-width: 768px) {
  .thankyou .seccion_1 .contenedor-boton .go img {
    height: 24px;
    margin-left: 24px;
  }
}

@media (min-width: 992.4px) {
  .thankyou .seccion_1 .contenedor-boton .go img {
    height: 10.751px;
    margin-left: 9.097px;
  }
}

@media (min-width: 1200px) {
  .thankyou .seccion_1 .contenedor-boton .go img {
    margin-left: 11px;
    height: 13px;
  }
}

@media (min-width: 1920px) {
  .thankyou .seccion_1 .contenedor-boton .go img {
    height: 20.8px;
    margin-left: 17.6px;
  }
}

@media (min-width: 2400px) {
  .thankyou .seccion_1 .contenedor-boton .go img {
    height: 26px;
    margin-left: 22px;
  }
}

.thankyou #footer_thankyou {
  position: fixed;
  background-color: #941b7e;
  width: 100%;
  height: 25px;
  bottom: 0;
}

@media (min-width: 414px) {
  .thankyou #footer_thankyou {
    height: 32.25px;
  }
}

@media (min-width: 768px) {
  .thankyou #footer_thankyou {
    height: 60px;
  }
}

@media (min-width: 992.4px) {
  .thankyou #footer_thankyou {
    display: none;
  }
}

.page_404 .esquina_derecha {
  background: none;
  display: none;
}

.page_404 .seccion_1 {
  position: relative;
  min-height: 480px;
  line-height: 1.2;
  text-align: center;
}

.page_404 .seccion_1 .container {
  position: relative;
}

@media (min-width: 414px) {
  .page_404 .seccion_1 {
    min-height: 619.2px;
  }
}

@media (min-width: 768px) {
  .page_404 .seccion_1 {
    min-height: 1152px;
  }
}

@media (min-width: 992.4px) {
  .page_404 .seccion_1 {
    min-height: 545.82px;
  }
}

@media (min-width: 1200px) {
  .page_404 .seccion_1 {
    min-height: 660px;
  }
}

@media (min-width: 1920px) {
  .page_404 .seccion_1 {
    min-height: 1056px;
  }
}

@media (min-width: 2400px) {
  .page_404 .seccion_1 {
    min-height: 1320px;
  }
}

.page_404 .seccion_1 .cuadrito_inicial {
  position: absolute;
  z-index: 11;
  width: 5px;
  height: 5px;
  top: 370px;
  right: 26px;
}

@media (min-width: 414px) {
  .page_404 .seccion_1 .cuadrito_inicial {
    width: 6.45px;
    height: 6.45px;
    top: 477.3px;
    right: 33.54px;
  }
}

@media (min-width: 768px) {
  .page_404 .seccion_1 .cuadrito_inicial {
    width: 12px;
    height: 12px;
    top: 788px;
    right: 62.4px;
  }
}

@media (min-width: 992.4px) {
  .page_404 .seccion_1 .cuadrito_inicial {
    width: 15.713px;
    height: 15.713px;
    top: 504.47px;
    right: 4.135px;
  }
}

@media (min-width: 1200px) {
  .page_404 .seccion_1 .cuadrito_inicial {
    width: 19px;
    height: 19px;
    top: 610px;
    right: 5px;
  }
}

@media (min-width: 1920px) {
  .page_404 .seccion_1 .cuadrito_inicial {
    width: 30.4px;
    height: 30.4px;
    top: 976px;
    right: 8px;
  }
}

@media (min-width: 2400px) {
  .page_404 .seccion_1 .cuadrito_inicial {
    width: 38px;
    height: 38px;
    top: 1220px;
    right: 10px;
  }
}

.page_404 .seccion_1 h1 {
  font-family: "Gotham-Medium";
  color: #3f3f3d;
  font-size: 40px;
  margin-top: 77px;
  margin-bottom: 0px;
}

@media (min-width: 414px) {
  .page_404 .seccion_1 h1 {
    font-size: 51.6px;
    margin-top: 99.33px;
  }
}

@media (min-width: 768px) {
  .page_404 .seccion_1 h1 {
    font-size: 96px;
    margin-top: 184.8px;
  }
}

@media (min-width: 992.4px) {
  .page_404 .seccion_1 h1 {
    margin-top: 63.679px;
    margin-bottom: 9.924px;
    font-size: 94.51783px;
  }
}

@media (min-width: 1200px) {
  .page_404 .seccion_1 h1 {
    margin-top: 77px;
    margin-bottom: 12px;
    font-size: 114.29px;
  }
}

@media (min-width: 1920px) {
  .page_404 .seccion_1 h1 {
    margin-top: 123.2px;
    margin-bottom: 19.2px;
    font-size: 182.864px;
  }
}

@media (min-width: 2400px) {
  .page_404 .seccion_1 h1 {
    margin-top: 123.2px;
    margin-bottom: 19.2px;
    font-size: 182.864px;
  }
}

.page_404 .seccion_1 h1 span {
  font-family: "Gotham-Black";
}

.page_404 .seccion_1 p {
  font-family: "Gotham-Book";
  color: #3c3d38;
  letter-spacing: -0.016em;
  font-size: 12px;
  margin-bottom: 33px;
}

@media (min-width: 414px) {
  .page_404 .seccion_1 p {
    font-size: 15.48px;
    margin-bottom: 42.57px;
  }
}

@media (min-width: 768px) {
  .page_404 .seccion_1 p {
    font-size: 28.8px;
    margin-bottom: 79.2px;
  }
}

@media (min-width: 992.4px) {
  .page_404 .seccion_1 p {
    font-size: 16.38287px;
    margin-bottom: 26.464px;
  }
}

@media (min-width: 1200px) {
  .page_404 .seccion_1 p {
    font-size: 19.81px;
    margin-bottom: 32px;
  }
}

@media (min-width: 1920px) {
  .page_404 .seccion_1 p {
    font-size: 31.696px;
    margin-bottom: 51.2px;
  }
}

@media (min-width: 2400px) {
  .page_404 .seccion_1 p {
    font-size: 39.62px;
    margin-bottom: 64px;
  }
}

.page_404 .seccion_1 .contenedor-boton {
  display: flex;
  align-items: center;
  margin: auto;
  position: relative;
  box-sizing: border-box;
  color: #fff;
  background: #fff;
  background-clip: padding-box;
  /* !importanté */
  width: 230px;
  height: 30px;
  border-radius: 30px;
  font-size: 10px;
  border: solid 1px transparent;
  /* !importanté */
}

@media (min-width: 414px) {
  .page_404 .seccion_1 .contenedor-boton {
    width: 296.7px;
    height: 38.7px;
    font-size: 12.9px;
    border-radius: 38.7px;
  }
}

@media (min-width: 768px) {
  .page_404 .seccion_1 .contenedor-boton {
    width: 552px;
    height: 72px;
    font-size: 24px;
    border-radius: 72px;
  }
}

@media (min-width: 992.4px) {
  .page_404 .seccion_1 .contenedor-boton {
    width: 179.459px;
    height: 33.08px;
    border-radius: 33.08px;
  }
}

@media (min-width: 1200px) {
  .page_404 .seccion_1 .contenedor-boton {
    width: 217px;
    height: 40px;
    border-radius: 40px;
  }
}

@media (min-width: 1920px) {
  .page_404 .seccion_1 .contenedor-boton {
    width: 347.2px;
    height: 64px;
    border-radius: 64px;
    border: solid 2px transparent;
    /* !importanté */
  }
}

@media (min-width: 2400px) {
  .page_404 .seccion_1 .contenedor-boton {
    width: 434px;
    height: 80px;
    border-radius: 80px;
  }
}

.page_404 .seccion_1 .contenedor-boton:before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  margin: -2px;
  /* !importanté */
  border-radius: inherit;
  /* !importanté */
  background: linear-gradient(90deg, #6aaddf 0%, #9e1b7e 100%);
  margin: -1px;
  /* !importanté */
}

@media (min-width: 992.4px) {
  .page_404 .seccion_1 .contenedor-boton:before {
    margin: -1px;
    /* !importanté */
  }
}

@media (min-width: 1200px) {
  .page_404 .seccion_1 .contenedor-boton:before {
    margin: -1px;
    /* !importanté */
  }
}

@media (min-width: 1920px) {
  .page_404 .seccion_1 .contenedor-boton:before {
    margin: -2px;
    /* !importanté */
  }
}

@media (min-width: 2400px) {
  .page_404 .seccion_1 .contenedor-boton:before {
    margin: -2px;
    /* !importanté */
  }
}

.page_404 .seccion_1 .contenedor-boton .go {
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  font-family: "Gotham-Medium";
  color: #3f3f3d;
  font-size: 10px;
}

@media (min-width: 414px) {
  .page_404 .seccion_1 .contenedor-boton .go {
    font-size: 12.9px;
  }
}

@media (min-width: 768px) {
  .page_404 .seccion_1 .contenedor-boton .go {
    font-size: 24px;
  }
}

@media (min-width: 992.4px) {
  .page_404 .seccion_1 .contenedor-boton .go {
    font-size: 13.232px;
  }
}

@media (min-width: 1200px) {
  .page_404 .seccion_1 .contenedor-boton .go {
    font-size: 16px;
  }
}

@media (min-width: 1920px) {
  .page_404 .seccion_1 .contenedor-boton .go {
    font-size: 25.6px;
  }
}

@media (min-width: 2400px) {
  .page_404 .seccion_1 .contenedor-boton .go {
    font-size: 32px;
  }
}

.page_404 .seccion_1 .contenedor-boton .go img {
  margin-left: 10px;
  height: 10px;
  width: auto;
}

@media (min-width: 414px) {
  .page_404 .seccion_1 .contenedor-boton .go img {
    height: 12.9px;
    margin-left: 12.9px;
  }
}

@media (min-width: 768px) {
  .page_404 .seccion_1 .contenedor-boton .go img {
    height: 24px;
    margin-left: 24px;
  }
}

@media (min-width: 992.4px) {
  .page_404 .seccion_1 .contenedor-boton .go img {
    height: 10.751px;
    margin-left: 9.097px;
  }
}

@media (min-width: 1200px) {
  .page_404 .seccion_1 .contenedor-boton .go img {
    margin-left: 11px;
    height: 13px;
  }
}

@media (min-width: 1920px) {
  .page_404 .seccion_1 .contenedor-boton .go img {
    height: 20.8px;
    margin-left: 17.6px;
  }
}

@media (min-width: 2400px) {
  .page_404 .seccion_1 .contenedor-boton .go img {
    height: 26px;
    margin-left: 22px;
  }
}

.page_404 .seccion_1 .imagen {
  display: block;
  margin-left: auto;
  margin-right: auto;
  background-image: url(../img/404/img.webp);
  background-size: 100% auto;
  background-position: 0% 50%;
  width: 144px;
  height: 73px;
  margin-top: 45px;
}

@media (min-width: 414px) {
  .page_404 .seccion_1 .imagen {
    width: 185.76px;
    height: 94.17px;
    margin-top: 58.05px;
  }
}

@media (min-width: 768px) {
  .page_404 .seccion_1 .imagen {
    width: 345.6px;
    height: 175.2px;
    margin-top: 108px;
  }
}

@media (min-width: 992.4px) {
  .page_404 .seccion_1 .imagen {
    width: 310.125px;
    height: 157.13px;
    margin-top: 37.215px;
  }
}

@media (min-width: 1200px) {
  .page_404 .seccion_1 .imagen {
    width: 375px;
    height: 190px;
    margin-top: 45px;
  }
}

@media (min-width: 1920px) {
  .page_404 .seccion_1 .imagen {
    width: 600px;
    height: 304px;
    margin-top: 72px;
  }
}

@media (min-width: 2400px) {
  .page_404 .seccion_1 .imagen {
    width: 750px;
    height: 380px;
    margin-top: 90px;
  }
}

.page_404 #footer_404 {
  position: fixed;
  background-color: #941b7e;
  width: 100%;
  height: 25px;
  bottom: 0;
}

@media (min-width: 414px) {
  .page_404 #footer_404 {
    height: 32.25px;
  }
}

@media (min-width: 768px) {
  .page_404 #footer_404 {
    height: 60px;
  }
}

@media (min-width: 992.4px) {
  .page_404 #footer_404 {
    display: none;
  }
}

.home .seccion_1 {
  position: relative;
  padding-bottom: 40px;
}

@media (max-width: 991px) {
  .home .seccion_1 {
    width: 100vw;
    overflow: hidden;
  }
}

@media (min-width: 414px) {
  .home .seccion_1 {
    padding-bottom: 51.6px;
  }
}

@media (min-width: 768px) {
  .home .seccion_1 {
    padding-bottom: 96px;
  }
}

@media (min-width: 992px) {
  .home .seccion_1 {
    padding-bottom: 0px;
  }
}

.home .seccion_1 .container {
  position: relative;
}

@media (max-width: 991px) {
  .home .seccion_1 .container > .row {
    position: relative;
  }
}

@media (min-width: 992px) {
  .home .seccion_1 .container .cuadrito_inicial {
    z-index: 11;
    position: absolute;
    width: 15.713px;
    height: 15.713px;
    top: 504.47px;
    right: 4.135px;
  }
}

@media (min-width: 1200px) {
  .home .seccion_1 .container .cuadrito_inicial {
    width: 19px;
    height: 19px;
    top: 610px;
    right: 5px;
  }
}

@media (min-width: 1920px) {
  .home .seccion_1 .container .cuadrito_inicial {
    width: 30.4px;
    height: 30.4px;
    top: 976px;
    right: 8px;
  }
}

@media (min-width: 2400px) {
  .home .seccion_1 .container .cuadrito_inicial {
    width: 38px;
    height: 38px;
    top: 1220px;
    right: 10px;
  }
}

.home .seccion_1 .esquina_izquierda {
  background-image: url(../img/home/esquina_izquierda.svg);
  background-position: 0% 100%;
  background-repeat: no-repeat;
  position: absolute;
  width: 166px;
  height: 166px;
  top: 208px;
  left: -130px;
}

@media (min-width: 414px) {
  .home .seccion_1 .esquina_izquierda {
    width: 214.14px;
    height: 214.14px;
    top: 268.32px;
    left: -167.7px;
  }
}

@media (min-width: 768px) {
  .home .seccion_1 .esquina_izquierda {
    width: 398.4px;
    height: 398.4px;
    top: 499.2px;
    left: -312px;
  }
}

@media (min-width: 992px) {
  .home .seccion_1 .esquina_izquierda {
    width: 529.28px;
    height: 529.28px;
    top: 165.4px;
    left: -410.192px;
  }
}

@media (min-width: 1200px) {
  .home .seccion_1 .esquina_izquierda {
    width: 640px;
    height: 640px;
    top: 200px;
    left: -496px;
  }
}

@media (min-width: 1920px) {
  .home .seccion_1 .esquina_izquierda {
    width: 1024px;
    height: 1024px;
    top: 320px;
    left: -793.6px;
  }
}

@media (min-width: 2400px) {
  .home .seccion_1 .esquina_izquierda {
    width: 820px;
    width: 1280px;
    height: 1280px;
    top: 400px;
    left: -992px;
  }
}

.home .seccion_1 .contenedor_carrousel_1 {
  padding-left: 0;
}

.home .seccion_1 .carrousel_1 {
  position: relative;
  width: 100%;
  margin-top: 18px;
  height: 320px;
}

@media (min-width: 414px) {
  .home .seccion_1 .carrousel_1 {
    margin-top: 23.22px;
    height: 412.8px;
  }
}

@media (min-width: 768px) {
  .home .seccion_1 .carrousel_1 {
    margin-top: 43.2px;
    height: 768px;
  }
}

@media (min-width: 992px) {
  .home .seccion_1 .carrousel_1 {
    margin-top: 114.126px;
    width: 394.479px;
    height: 231.56px;
  }
}

@media (min-width: 1200px) {
  .home .seccion_1 .carrousel_1 {
    margin-top: 138px;
    width: 477px;
    height: 280px;
  }
}

@media (min-width: 1920px) {
  .home .seccion_1 .carrousel_1 {
    margin-top: 220.8px;
    width: 763.2px;
    height: 448px;
  }
}

@media (min-width: 2400px) {
  .home .seccion_1 .carrousel_1 {
    margin-top: 276px;
    width: 954px;
    height: 560px;
  }
}

.home .seccion_1 .carrousel_1 .linea {
  position: absolute;
  top: 0;
  height: 2px;
  width: 10px;
  left: 20px;
}

@media (min-width: 414px) {
  .home .seccion_1 .carrousel_1 .linea {
    height: 2.58px;
    width: 12.9px;
    left: 25.8px;
  }
}

@media (min-width: 768px) {
  .home .seccion_1 .carrousel_1 .linea {
    height: 4.8px;
    width: 24px;
    left: 48px;
  }
}

@media (min-width: 992px) {
  .home .seccion_1 .carrousel_1 .linea {
    height: 8.27px;
    width: 24.81px;
    left: 67.814px;
  }
}

@media (min-width: 1200px) {
  .home .seccion_1 .carrousel_1 .linea {
    height: 10px;
    width: 30px;
    left: 82px;
  }
}

@media (min-width: 1920px) {
  .home .seccion_1 .carrousel_1 .linea {
    height: 16px;
    width: 48px;
    left: 131.2px;
  }
}

@media (min-width: 2400px) {
  .home .seccion_1 .carrousel_1 .linea {
    height: 20px;
    width: 60px;
    left: 164px;
  }
}

.home .seccion_1 .carrousel_1 .opciones {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  width: 90px;
  height: 6px;
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 414px) {
  .home .seccion_1 .carrousel_1 .opciones {
    width: 116.1px;
    height: 7.74px;
  }
}

@media (min-width: 768px) {
  .home .seccion_1 .carrousel_1 .opciones {
    width: 216px;
    height: 14.4px;
  }
}

@media (max-width: 991px) {
  .home .seccion_1 .carrousel_1 .opciones {
    display: flex;
    align-content: center;
    justify-content: space-between;
  }
}

@media (min-width: 992px) {
  .home .seccion_1 .carrousel_1 .opciones {
    left: 4.962px;
    top: 71.122px;
    width: 7.443px;
    height: 103.375px;
    right: initial;
    bottom: initial;
    margin-left: initial;
    margin-right: initial;
  }
}

@media (min-width: 1200px) {
  .home .seccion_1 .carrousel_1 .opciones {
    left: 6px;
    top: 86px;
    width: 9px;
    height: 125px;
  }
}

@media (min-width: 1920px) {
  .home .seccion_1 .carrousel_1 .opciones {
    left: 9.6px;
    top: 137.6px;
    width: 14.4px;
    height: 200px;
  }
}

@media (min-width: 2400px) {
  .home .seccion_1 .carrousel_1 .opciones {
    left: 12px;
    top: 172px;
    width: 18px;
    height: 250px;
  }
}

.home .seccion_1 .carrousel_1 .opciones .opcion {
  cursor: pointer;
  border-radius: 50%;
  border: 1px solid #212120;
  width: 6px;
  height: 6px;
}

@media (min-width: 414px) {
  .home .seccion_1 .carrousel_1 .opciones .opcion {
    border: 1px solid #212120;
    width: 7.74px;
    height: 7.74px;
  }
}

@media (min-width: 768px) {
  .home .seccion_1 .carrousel_1 .opciones .opcion {
    border: 2px solid #212120;
    width: 14.4px;
    height: 14.4px;
  }
}

@media (min-width: 992px) {
  .home .seccion_1 .carrousel_1 .opciones .opcion {
    margin-bottom: 16.54px;
    border: 1px solid #212120;
    width: 7.443px;
    height: 7.443px;
  }
}

@media (min-width: 1200px) {
  .home .seccion_1 .carrousel_1 .opciones .opcion {
    margin-bottom: 20px;
    border: 1px solid #212120;
    width: 9px;
    height: 9px;
  }
}

@media (min-width: 1920px) {
  .home .seccion_1 .carrousel_1 .opciones .opcion {
    margin-bottom: 32px;
    border: 1.6px solid #212120;
    width: 14.4px;
    height: 14.4px;
  }
}

@media (min-width: 2400px) {
  .home .seccion_1 .carrousel_1 .opciones .opcion {
    margin-bottom: 40px;
    border: 2px solid #212120;
    width: 18px;
    height: 18px;
  }
}

.home .seccion_1 .carrousel_1 .opciones .opcion.active {
  background-color: #9e1b7e;
  border-color: #9e1b7e;
}

.home .seccion_1 .carrousel_1 .contenedor_texto {
  position: absolute;
  line-height: 1;
  left: 25px;
  top: 62px;
}

@media (min-width: 414px) {
  .home .seccion_1 .carrousel_1 .contenedor_texto {
    left: 32.25px;
    top: 79.98px;
  }
}

@media (min-width: 768px) {
  .home .seccion_1 .carrousel_1 .contenedor_texto {
    left: 60px;
    top: 148.8px;
  }
}

@media (min-width: 992px) {
  .home .seccion_1 .carrousel_1 .contenedor_texto {
    left: 67.814px;
    width: 326.665px;
    height: 205.923px;
    bottom: 0;
    top: initial;
  }
}

@media (min-width: 1200px) {
  .home .seccion_1 .carrousel_1 .contenedor_texto {
    left: 82px;
    width: 395px;
    height: 249px;
  }
}

@media (min-width: 1920px) {
  .home .seccion_1 .carrousel_1 .contenedor_texto {
    left: 131.2px;
    width: 632px;
    height: 398.4px;
  }
}

@media (min-width: 2400px) {
  .home .seccion_1 .carrousel_1 .contenedor_texto {
    left: 164px;
    width: 790px;
    height: 498px;
  }
}

.home .seccion_1 .carrousel_1 .contenedor_texto .linea_1 {
  font-family: "Gotham-Medium";
  color: #3f3f3d;
  font-size: 20px;
  line-height: 1.2;
}

@media (min-width: 414px) {
  .home .seccion_1 .carrousel_1 .contenedor_texto .linea_1 {
    font-size: 25.8px;
  }
}

@media (min-width: 768px) {
  .home .seccion_1 .carrousel_1 .contenedor_texto .linea_1 {
    font-size: 48px;
  }
}

@media (min-width: 992px) {
  .home .seccion_1 .carrousel_1 .contenedor_texto .linea_1 {
    line-height: 1;
    letter-spacing: -0.006em;
    height: 41.35px;
    font-size: 37.215px;
    margin-bottom: 1.654px;
  }
}

@media (min-width: 1200px) {
  .home .seccion_1 .carrousel_1 .contenedor_texto .linea_1 {
    height: 50px;
    font-size: 45px;
    margin-bottom: 2px;
  }
}

@media (min-width: 1920px) {
  .home .seccion_1 .carrousel_1 .contenedor_texto .linea_1 {
    height: 80px;
    font-size: 72px;
    margin-bottom: 3.2px;
  }
}

@media (min-width: 2400px) {
  .home .seccion_1 .carrousel_1 .contenedor_texto .linea_1 {
    height: 100px;
    font-size: 90px;
    margin-bottom: 4px;
  }
}

.home .seccion_1 .carrousel_1 .contenedor_texto .linea_2 {
  font-family: "Gotham-Black";
  color: #3f3f3d;
  font-size: 20px;
  line-height: 1.2;
}

@media (min-width: 414px) {
  .home .seccion_1 .carrousel_1 .contenedor_texto .linea_2 {
    font-size: 25.8px;
  }
}

@media (min-width: 768px) {
  .home .seccion_1 .carrousel_1 .contenedor_texto .linea_2 {
    font-size: 48px;
  }
}

@media (min-width: 992px) {
  .home .seccion_1 .carrousel_1 .contenedor_texto .linea_2 {
    line-height: 1;
  }
}

.home .seccion_1 .carrousel_1 .contenedor_texto .linea_3 {
  font-family: "Gotham-Black";
  color: #3f3f3d;
  font-size: 20px;
  line-height: 1.2;
}

@media (min-width: 414px) {
  .home .seccion_1 .carrousel_1 .contenedor_texto .linea_3 {
    font-size: 25.8px;
  }
}

@media (min-width: 768px) {
  .home .seccion_1 .carrousel_1 .contenedor_texto .linea_3 {
    font-size: 48px;
  }
}

@media (min-width: 992px) {
  .home .seccion_1 .carrousel_1 .contenedor_texto .linea_3 {
    line-height: 1;
  }
}

@media (min-width: 992px) {
  .home .seccion_1 .carrousel_1 .contenedor_texto[data-opcion='1'] {
    width: 301.855px;
  }
}

@media (min-width: 1200px) {
  .home .seccion_1 .carrousel_1 .contenedor_texto[data-opcion='1'] {
    width: 365px;
  }
}

@media (min-width: 1920px) {
  .home .seccion_1 .carrousel_1 .contenedor_texto[data-opcion='1'] {
    width: 584px;
  }
}

@media (min-width: 2400px) {
  .home .seccion_1 .carrousel_1 .contenedor_texto[data-opcion='1'] {
    width: 730px;
  }
}

@media (min-width: 992px) {
  .home .seccion_1 .carrousel_1 .contenedor_texto[data-opcion='1'] .linea_2 {
    text-align: left;
    font-size: 49.62px;
    margin-bottom: 4.135px;
  }
}

@media (min-width: 1200px) {
  .home .seccion_1 .carrousel_1 .contenedor_texto[data-opcion='1'] .linea_2 {
    font-size: 60px;
    margin-bottom: 5px;
  }
}

@media (min-width: 1920px) {
  .home .seccion_1 .carrousel_1 .contenedor_texto[data-opcion='1'] .linea_2 {
    font-size: 96px;
    margin-bottom: 8px;
  }
}

@media (min-width: 2400px) {
  .home .seccion_1 .carrousel_1 .contenedor_texto[data-opcion='1'] .linea_2 {
    font-size: 120px;
    margin-bottom: 10px;
  }
}

@media (min-width: 992px) {
  .home .seccion_1 .carrousel_1 .contenedor_texto[data-opcion='1'] .linea_3 {
    font-size: 49.62px;
    margin-bottom: 25.637px;
    text-align: right;
  }
}

@media (min-width: 1200px) {
  .home .seccion_1 .carrousel_1 .contenedor_texto[data-opcion='1'] .linea_3 {
    font-size: 60px;
    margin-bottom: 31px;
  }
}

@media (min-width: 1920px) {
  .home .seccion_1 .carrousel_1 .contenedor_texto[data-opcion='1'] .linea_3 {
    font-size: 96px;
    margin-bottom: 49.6px;
  }
}

@media (min-width: 2400px) {
  .home .seccion_1 .carrousel_1 .contenedor_texto[data-opcion='1'] .linea_3 {
    font-size: 120px;
    margin-bottom: 62px;
  }
}

@media (min-width: 992px) {
  .home .seccion_1 .carrousel_1 .contenedor_texto[data-opcion='2'] {
    width: 233.214px;
  }
}

@media (min-width: 1200px) {
  .home .seccion_1 .carrousel_1 .contenedor_texto[data-opcion='2'] {
    width: 282px;
  }
}

@media (min-width: 1920px) {
  .home .seccion_1 .carrousel_1 .contenedor_texto[data-opcion='2'] {
    width: 451.2px;
  }
}

@media (min-width: 2400px) {
  .home .seccion_1 .carrousel_1 .contenedor_texto[data-opcion='2'] {
    width: 564px;
  }
}

@media (min-width: 992px) {
  .home .seccion_1 .carrousel_1 .contenedor_texto[data-opcion='2'] .linea_3 {
    text-align: right;
    font-size: 50.40565px;
    margin-bottom: 32.253px;
  }
}

@media (min-width: 1200px) {
  .home .seccion_1 .carrousel_1 .contenedor_texto[data-opcion='2'] .linea_3 {
    font-size: 60.95px;
    margin-bottom: 39px;
  }
}

@media (min-width: 1920px) {
  .home .seccion_1 .carrousel_1 .contenedor_texto[data-opcion='2'] .linea_3 {
    font-size: 97.52px;
    margin-bottom: 62.4px;
  }
}

@media (min-width: 2400px) {
  .home .seccion_1 .carrousel_1 .contenedor_texto[data-opcion='2'] .linea_3 {
    font-size: 121.9px;
    margin-bottom: 78px;
  }
}

@media (min-width: 992px) {
  .home .seccion_1 .carrousel_1 .contenedor_texto[data-opcion='3'] {
    width: 302.682px;
  }
}

@media (min-width: 1200px) {
  .home .seccion_1 .carrousel_1 .contenedor_texto[data-opcion='3'] {
    width: 366px;
  }
}

@media (min-width: 1920px) {
  .home .seccion_1 .carrousel_1 .contenedor_texto[data-opcion='3'] {
    width: 585.6px;
  }
}

@media (min-width: 2400px) {
  .home .seccion_1 .carrousel_1 .contenedor_texto[data-opcion='3'] {
    width: 732px;
  }
}

@media (min-width: 992px) {
  .home .seccion_1 .carrousel_1 .contenedor_texto[data-opcion='3'] .linea_2 {
    text-align: center;
    font-size: 44.10391px;
    margin-bottom: 4.135px;
  }
}

@media (min-width: 1200px) {
  .home .seccion_1 .carrousel_1 .contenedor_texto[data-opcion='3'] .linea_2 {
    font-size: 53.33px;
    margin-bottom: 5px;
  }
}

@media (min-width: 1920px) {
  .home .seccion_1 .carrousel_1 .contenedor_texto[data-opcion='3'] .linea_2 {
    font-size: 85.328px;
    margin-bottom: 8px;
  }
}

@media (min-width: 2400px) {
  .home .seccion_1 .carrousel_1 .contenedor_texto[data-opcion='3'] .linea_2 {
    font-size: 106.66px;
    margin-bottom: 10px;
  }
}

@media (min-width: 992px) {
  .home .seccion_1 .carrousel_1 .contenedor_texto[data-opcion='3'] .linea_3 {
    text-align: right;
    letter-spacing: -0.025em;
    font-size: 50.40565px;
    margin-bottom: 25.637px;
  }
}

@media (min-width: 1200px) {
  .home .seccion_1 .carrousel_1 .contenedor_texto[data-opcion='3'] .linea_3 {
    font-size: 60.95px;
    margin-bottom: 31px;
  }
}

@media (min-width: 1920px) {
  .home .seccion_1 .carrousel_1 .contenedor_texto[data-opcion='3'] .linea_3 {
    font-size: 97.52px;
    margin-bottom: 49.6px;
  }
}

@media (min-width: 2400px) {
  .home .seccion_1 .carrousel_1 .contenedor_texto[data-opcion='3'] .linea_3 {
    font-size: 121.9px;
    margin-bottom: 62px;
  }
}

@media (min-width: 992px) {
  .home .seccion_1 .carrousel_1 .contenedor_texto[data-opcion='4'] {
    width: 322.53px;
  }
}

@media (min-width: 1200px) {
  .home .seccion_1 .carrousel_1 .contenedor_texto[data-opcion='4'] {
    width: 390px;
  }
}

@media (min-width: 1920px) {
  .home .seccion_1 .carrousel_1 .contenedor_texto[data-opcion='4'] {
    width: 624px;
  }
}

@media (min-width: 2400px) {
  .home .seccion_1 .carrousel_1 .contenedor_texto[data-opcion='4'] {
    width: 780px;
  }
}

@media (min-width: 992px) {
  .home .seccion_1 .carrousel_1 .contenedor_texto[data-opcion='4'] .linea_2 {
    text-align: left;
    margin-bottom: 4.135px;
    font-size: 50.40565px;
    margin-left: 31.426px;
  }
}

@media (min-width: 1200px) {
  .home .seccion_1 .carrousel_1 .contenedor_texto[data-opcion='4'] .linea_2 {
    margin-bottom: 5px;
    font-size: 60.95px;
    margin-left: 38px;
  }
}

@media (min-width: 1920px) {
  .home .seccion_1 .carrousel_1 .contenedor_texto[data-opcion='4'] .linea_2 {
    margin-bottom: 8px;
    font-size: 97.52px;
    margin-left: 60.8px;
  }
}

@media (min-width: 2400px) {
  .home .seccion_1 .carrousel_1 .contenedor_texto[data-opcion='4'] .linea_2 {
    margin-bottom: 10px;
    font-size: 121.9px;
    margin-left: 76px;
  }
}

@media (min-width: 992px) {
  .home .seccion_1 .carrousel_1 .contenedor_texto[data-opcion='4'] .linea_3 {
    text-align: right;
    letter-spacing: -0.025em;
    font-size: 50.40565px;
    margin-bottom: 25.637px;
  }
}

@media (min-width: 1200px) {
  .home .seccion_1 .carrousel_1 .contenedor_texto[data-opcion='4'] .linea_3 {
    font-size: 60.95px;
    margin-bottom: 31px;
  }
}

@media (min-width: 1920px) {
  .home .seccion_1 .carrousel_1 .contenedor_texto[data-opcion='4'] .linea_3 {
    font-size: 97.52px;
    margin-bottom: 49.6px;
  }
}

@media (min-width: 2400px) {
  .home .seccion_1 .carrousel_1 .contenedor_texto[data-opcion='4'] .linea_3 {
    font-size: 121.9px;
    margin-bottom: 62px;
  }
}

@media (min-width: 992px) {
  .home .seccion_1 .carrousel_1 .contenedor_texto[data-opcion='5'] {
    width: 228.252px;
  }
}

@media (min-width: 1200px) {
  .home .seccion_1 .carrousel_1 .contenedor_texto[data-opcion='5'] {
    width: 276px;
  }
}

@media (min-width: 1920px) {
  .home .seccion_1 .carrousel_1 .contenedor_texto[data-opcion='5'] {
    width: 441.6px;
  }
}

@media (min-width: 2400px) {
  .home .seccion_1 .carrousel_1 .contenedor_texto[data-opcion='5'] {
    width: 552px;
  }
}

@media (min-width: 992px) {
  .home .seccion_1 .carrousel_1 .contenedor_texto[data-opcion='5'] .linea_2 {
    text-align: left;
    font-size: 44.10391px;
    height: 39.696px;
  }
}

@media (min-width: 1200px) {
  .home .seccion_1 .carrousel_1 .contenedor_texto[data-opcion='5'] .linea_2 {
    font-size: 53.33px;
    height: 48px;
  }
}

@media (min-width: 1920px) {
  .home .seccion_1 .carrousel_1 .contenedor_texto[data-opcion='5'] .linea_2 {
    font-size: 85.328px;
    height: 76.8px;
  }
}

@media (min-width: 2400px) {
  .home .seccion_1 .carrousel_1 .contenedor_texto[data-opcion='5'] .linea_2 {
    font-size: 106.66px;
    height: 96px;
  }
}

@media (min-width: 992px) {
  .home .seccion_1 .carrousel_1 .contenedor_texto[data-opcion='5'] .linea_3 {
    text-align: right;
    font-size: 50.40565px;
    margin-bottom: 25.637px;
  }
}

@media (min-width: 1200px) {
  .home .seccion_1 .carrousel_1 .contenedor_texto[data-opcion='5'] .linea_3 {
    font-size: 60.95px;
    margin-bottom: 31px;
  }
}

@media (min-width: 1920px) {
  .home .seccion_1 .carrousel_1 .contenedor_texto[data-opcion='5'] .linea_3 {
    font-size: 97.52px;
    margin-bottom: 49.6px;
  }
}

@media (min-width: 2400px) {
  .home .seccion_1 .carrousel_1 .contenedor_texto[data-opcion='5'] .linea_3 {
    font-size: 121.9px;
    margin-bottom: 62px;
  }
}

.home .seccion_1 .carrousel_1 .contenedor_texto .contenedor-boton {
  display: flex;
  align-items: center;
  position: relative;
  box-sizing: border-box;
  color: #fff;
  background: #fff;
  background-clip: padding-box;
  /* !importanté */
  width: 72px;
  height: 30px;
  border-radius: 30px;
  margin-top: 18px;
  border: solid 1px transparent;
  /* !importanté */
}

@media (min-width: 414px) {
  .home .seccion_1 .carrousel_1 .contenedor_texto .contenedor-boton {
    width: 92.88px;
    height: 38.7px;
    border-radius: 38.7px;
    margin-top: 23.22px;
    border: solid 1px transparent;
    /* !importanté */
  }
}

@media (min-width: 768px) {
  .home .seccion_1 .carrousel_1 .contenedor_texto .contenedor-boton {
    width: 172.8px;
    height: 72px;
    border-radius: 72px;
    margin-top: 43.2px;
    border: solid 2px transparent;
    /* !importanté */
  }
}

@media (min-width: 992px) {
  .home .seccion_1 .carrousel_1 .contenedor_texto .contenedor-boton {
    width: 102.548px;
    height: 30.599px;
    border-radius: 28.945px;
    border: solid 1px transparent;
    /* !importanté */
    margin: auto;
    float: right;
  }
}

@media (min-width: 1200px) {
  .home .seccion_1 .carrousel_1 .contenedor_texto .contenedor-boton {
    width: 124px;
    height: 37px;
    border-radius: 35px;
    border: solid 2px transparent;
    /* !importanté */
  }
}

@media (min-width: 1920px) {
  .home .seccion_1 .carrousel_1 .contenedor_texto .contenedor-boton {
    width: 198.4px;
    height: 59.2px;
    border-radius: 56px;
    border: solid 3px transparent;
    /* !importanté */
  }
}

@media (min-width: 2400px) {
  .home .seccion_1 .carrousel_1 .contenedor_texto .contenedor-boton {
    width: 248px;
    height: 74px;
    border-radius: 70px;
    border: solid 4 transparent;
    /* !importanté */
  }
}

.home .seccion_1 .carrousel_1 .contenedor_texto .contenedor-boton:before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  margin: -1px;
  /* !importanté */
  border-radius: inherit;
  /* !importanté */
  background: linear-gradient(90deg, #6aaddf 0%, #9e1b7e 100%);
}

@media (min-width: 992px) {
  .home .seccion_1 .carrousel_1 .contenedor_texto .contenedor-boton:before {
    margin: -1px;
    /* !importanté */
  }
}

@media (min-width: 1200px) {
  .home .seccion_1 .carrousel_1 .contenedor_texto .contenedor-boton:before {
    margin: -2px;
    /* !importanté */
  }
}

@media (min-width: 1920px) {
  .home .seccion_1 .carrousel_1 .contenedor_texto .contenedor-boton:before {
    margin: -3px;
    /* !importanté */
  }
}

@media (min-width: 2400px) {
  .home .seccion_1 .carrousel_1 .contenedor_texto .contenedor-boton:before {
    margin: -4px;
    /* !importanté */
  }
}

.home .seccion_1 .carrousel_1 .contenedor_texto .contenedor-boton .go {
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  font-family: "Gotham-Medium";
  color: #3f3f3d;
  font-size: 16px;
}

@media (min-width: 414px) {
  .home .seccion_1 .carrousel_1 .contenedor_texto .contenedor-boton .go {
    font-size: 20.64px;
  }
}

@media (min-width: 768px) {
  .home .seccion_1 .carrousel_1 .contenedor_texto .contenedor-boton .go {
    font-size: 38.4px;
  }
}

@media (min-width: 992px) {
  .home .seccion_1 .carrousel_1 .contenedor_texto .contenedor-boton .go {
    font-size: 19.021px;
  }
}

@media (min-width: 1200px) {
  .home .seccion_1 .carrousel_1 .contenedor_texto .contenedor-boton .go {
    font-size: 23px;
  }
}

@media (min-width: 1920px) {
  .home .seccion_1 .carrousel_1 .contenedor_texto .contenedor-boton .go {
    font-size: 36.8px;
  }
}

@media (min-width: 2400px) {
  .home .seccion_1 .carrousel_1 .contenedor_texto .contenedor-boton .go {
    font-size: 46px;
  }
}

.home .seccion_1 .carrousel_1 .contenedor_texto .contenedor-boton .go img {
  display: block;
  height: 12px;
  padding-top: 1px;
  margin-left: 5px;
}

@media (min-width: 414px) {
  .home .seccion_1 .carrousel_1 .contenedor_texto .contenedor-boton .go img {
    height: 15.48px;
    margin-left: 6.45px;
  }
}

@media (min-width: 768px) {
  .home .seccion_1 .carrousel_1 .contenedor_texto .contenedor-boton .go img {
    height: 28.8px;
    margin-left: 12px;
  }
}

@media (min-width: 992px) {
  .home .seccion_1 .carrousel_1 .contenedor_texto .contenedor-boton .go img {
    margin-left: 9.097px;
    height: 14.886px;
    padding-top: 0.827px;
  }
}

@media (min-width: 1200px) {
  .home .seccion_1 .carrousel_1 .contenedor_texto .contenedor-boton .go img {
    margin-left: 11px;
    height: 18px;
    padding-top: 1px;
  }
}

@media (min-width: 1920px) {
  .home .seccion_1 .carrousel_1 .contenedor_texto .contenedor-boton .go img {
    margin-left: 17.6px;
    height: 28.8px;
    padding-top: 1.6px;
  }
}

@media (min-width: 2400px) {
  .home .seccion_1 .carrousel_1 .contenedor_texto .contenedor-boton .go img {
    margin-left: 22px;
    height: 36px;
    padding-top: 2px;
  }
}

@media (max-width: 991px) {
  .home .seccion_1 .contenedor-imagen-principal {
    position: absolute;
    right: 0;
    margin: auto;
    z-index: -1;
    top: 19px;
    left: 115px;
    width: 246px;
    height: 489px;
    padding: 0;
  }
}

@media (max-width: 991px) and (min-width: 414px) {
  .home .seccion_1 .contenedor-imagen-principal {
    top: 24.51px;
    left: 148.35px;
    width: 317.34px;
    height: 630.81px;
  }
}

@media (max-width: 991px) and (min-width: 768px) {
  .home .seccion_1 .contenedor-imagen-principal {
    top: 45.6px;
    left: 276px;
    width: 590.4px;
    height: 1173.6px;
  }
}

.home .seccion_1 .imagen-principal {
  background-image: url(../img/home/personajes/personaje_1.png);
  background-size: 100% auto;
  background-position: 0% 25%;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
}

@media (min-width: 992px) {
  .home .seccion_1 .imagen-principal {
    width: 100%;
    height: 549.955px;
    margin-left: 8.27px;
    background-position: 100% 0%;
  }
}

@media (min-width: 1200px) {
  .home .seccion_1 .imagen-principal {
    height: 665px;
    margin-left: 10px;
  }
}

@media (min-width: 1920px) {
  .home .seccion_1 .imagen-principal {
    height: 1064px;
    margin-left: 16px;
  }
}

@media (min-width: 2400px) {
  .home .seccion_1 .imagen-principal {
    height: 1330px;
    margin-left: 20px;
  }
}

.home .cinta_1 {
  position: relative;
}

.home .cinta_1 .fondo_mobile {
  background-color: #941b7e;
  height: 200px;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

@media (min-width: 414px) {
  .home .cinta_1 .fondo_mobile {
    height: 258px;
  }
}

@media (min-width: 768px) {
  .home .cinta_1 .fondo_mobile {
    height: 480px;
  }
}

@media (min-width: 992px) {
  .home .cinta_1 .fondo_mobile {
    display: none;
  }
}

.home .cinta_1 .contenedor-titulo {
  padding-right: 0;
}

.home .cinta_1 .titulo {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: #fff;
  font-family: "Gotham-Bold";
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;
  height: 100px;
  font-size: 20px;
  padding-left: 28px;
  z-index: 2;
}

@media (min-width: 414px) {
  .home .cinta_1 .titulo {
    height: 129px;
    font-size: 25.8px;
    padding-left: 36.12px;
  }
}

@media (min-width: 768px) {
  .home .cinta_1 .titulo {
    height: 240px;
    font-size: 48px;
    padding-left: 67.2px;
  }
}

@media (min-width: 992px) {
  .home .cinta_1 .titulo {
    height: 220.809px;
    font-size: 32.253px;
    justify-content: flex-end;
    padding-left: initial;
    color: #3f3f3d;
  }
}

@media (min-width: 1200px) {
  .home .cinta_1 .titulo {
    height: 267px;
    font-size: 39px;
  }
}

@media (min-width: 1920px) {
  .home .cinta_1 .titulo {
    height: 427.2px;
    font-size: 62.4px;
  }
}

@media (min-width: 2400px) {
  .home .cinta_1 .titulo {
    height: 534px;
    font-size: 78px;
  }
}

.home .cinta_1 .titulo .linea {
  width: 20px;
  height: 4px;
  margin-left: 110px;
  z-index: 2;
}

@media (min-width: 414px) {
  .home .cinta_1 .titulo .linea {
    width: 25.8px;
    height: 5.16px;
    margin-left: 141.9px;
  }
}

@media (min-width: 768px) {
  .home .cinta_1 .titulo .linea {
    width: 48px;
    height: 9.6px;
    margin-left: 264px;
  }
}

@media (min-width: 992px) {
  .home .cinta_1 .titulo .linea {
    width: 31.426px;
    height: 6.616px;
  }
}

@media (min-width: 1200px) {
  .home .cinta_1 .titulo .linea {
    width: 38px;
    height: 8px;
  }
}

@media (min-width: 1920px) {
  .home .cinta_1 .titulo .linea {
    width: 60.8px;
    height: 12.8px;
  }
}

@media (min-width: 2400px) {
  .home .cinta_1 .titulo .linea {
    width: 76px;
    height: 16px;
  }
}

.home .cinta_1 .titulo .texto {
  text-align: left;
  width: 100%;
  margin-bottom: 0px;
  z-index: 2;
}

@media (min-width: 992px) {
  .home .cinta_1 .titulo .texto {
    text-align: right;
    margin-bottom: 1.654px;
  }
}

@media (min-width: 1200px) {
  .home .cinta_1 .titulo .texto {
    margin-bottom: 2px;
  }
}

@media (min-width: 1920px) {
  .home .cinta_1 .titulo .texto {
    margin-bottom: 3.2px;
  }
}

@media (min-width: 2400px) {
  .home .cinta_1 .titulo .texto {
    margin-bottom: 4px;
  }
}

.home .cinta_1 .contenedor-cuadro-gris {
  padding-right: 0;
  z-index: 2;
}

@media (min-width: 992px) {
  .home .cinta_1 .contenedor-cuadro-gris {
    background-color: #e7e7e7;
    width: 51.5%;
    padding-left: 62.852px;
    padding-right: 62.852px;
  }
}

@media (min-width: 1200px) {
  .home .cinta_1 .contenedor-cuadro-gris {
    padding-left: 76px;
    padding-right: 76px;
  }
}

@media (min-width: 1920px) {
  .home .cinta_1 .contenedor-cuadro-gris {
    padding-left: 121.6px;
    padding-right: 121.6px;
  }
}

@media (min-width: 2400px) {
  .home .cinta_1 .contenedor-cuadro-gris {
    padding-left: 152px;
    padding-right: 152px;
  }
}

.home .cinta_1 .cuadro-gris {
  background-color: #e7e7e7;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #222220;
  height: 167px;
  font-size: 12px;
  width: 240px;
  padding-left: 19px;
  padding-right: 19px;
  float: right;
  text-align: left;
  letter-spacing: -0.07em;
  line-height: 1.2;
}

@media (min-width: 414px) {
  .home .cinta_1 .cuadro-gris {
    height: 215.43px;
    font-size: 15.48px;
    width: 309.6px;
    padding-left: 21.93px;
    padding-right: 21.93px;
  }
}

@media (min-width: 768px) {
  .home .cinta_1 .cuadro-gris {
    height: 400.8px;
    font-size: 28.8px;
    width: 576px;
    padding-left: 40.8px;
    padding-right: 40.8px;
  }
}

@media (min-width: 992px) {
  .home .cinta_1 .cuadro-gris {
    height: 220.809px;
    font-size: 12.405px;
    width: initial;
    padding-left: initial;
    padding-right: initial;
    float: none;
    text-align: left;
  }
}

@media (min-width: 1200px) {
  .home .cinta_1 .cuadro-gris {
    height: 267px;
    font-size: 15px;
  }
}

@media (min-width: 1920px) {
  .home .cinta_1 .cuadro-gris {
    height: 427.2px;
    font-size: 24px;
  }
}

@media (min-width: 2400px) {
  .home .cinta_1 .cuadro-gris {
    height: 534px;
    font-size: 30px;
  }
}

.home .cinta_1 .cuadro-gris .azul {
  color: #6AADDF;
}

.home .cinta_1 .cuadro-gris .bold {
  font-family: "Gotham-Bold";
}

.home .cinta_1 .cuadro-gris .book {
  font-family: "Gotham-Book";
}

.home .cinta_1 .cuadro-gris .italic {
  font-family: "Gotham-Book-Italic";
}

.home .cinta_1 .cuadro-gris .medium {
  padding-top: 0.3em;
  font-family: "Gotham-Medium";
  letter-spacing: 0em;
}

.home .grid > div > div > .col-12 {
  padding: 0;
}

.home .grid .grid-contenedor {
  width: 94%;
  display: flex;
  flex-wrap: wrap;
  line-height: 1.2;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  height: 533px;
}

@media (min-width: 992px) {
  .home .grid .grid-contenedor {
    height: 440.791px;
  }
}

@media (min-width: 1200px) {
  .home .grid .grid-contenedor {
    height: 533px;
  }
}

@media (min-width: 1920px) {
  .home .grid .grid-contenedor {
    height: 852.8px;
  }
}

@media (min-width: 2400px) {
  .home .grid .grid-contenedor {
    height: 1066px;
  }
}

.home .grid .grid-contenedor .slide {
  width: 100%;
  height: 100%;
  display: none;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: space-between;
  align-content: space-between;
  transition: right, left 1s;
  position: absolute;
}

.home .grid .grid-contenedor .slide.active {
  display: flex;
}

.home .grid .grid-contenedor .slide .cuadro_principal,
.home .grid .grid-contenedor .slide .cuadro_secundario {
  position: relative;
  background-size: auto 100%;
  background-position: 50% 50%;
  transition: background-size 0.5s ease-in;
  padding: 35px 38px;
  /*.go{
						display: none;
						width: 139px;
						height: 61px;
						font-size: 39px;

						@media (min-width: 992px){
							//*0.827;
							width: 115px;
							height: 50px;
							font-size: 32px;
						}
						@media (min-width: 1200px){
							width: 139px;
							height: 61px;
							font-size: 39px;
						}
						@media (min-width: (1200px*1.6)){
							//*1.6;
							width: 222px;
							height: 97px;
							font-size: 62px;
						}
						@media (min-width: (1200px*2)){
							//*2;
							width: 139px*2;
							height: 61px*2;
							font-size: 39px*2;
						}

						img{
							display: block;
							margin-left: 11px;
							height: 29px;
							padding-top: 2px;

							@media (min-width: 992px){
								//*0.827;
								margin-left: 11px*0.827;
								height: 29px*0.827;
								padding-top: 2px*0.827;
							}
							@media (min-width: 1200px){
								margin-left: 11px;
								height: 29px;
								padding-top: 2px;
							}
							@media (min-width: (1200px*1.6)){
								//*1.6;
								margin-left: 11px*1.6;
								height: 29px*1.6;
								padding-top: 2px*1.6;
							}
							@media (min-width: (1200px*2)){
								//*2;
								margin-left: 11px*2;
								height: 29px*2;
								padding-top: 2px*2;
							}

						}
					}
					*/
}

@media (min-width: 992px) {
  .home .grid .grid-contenedor .slide .cuadro_principal,
  .home .grid .grid-contenedor .slide .cuadro_secundario {
    padding: 28.945px 31.426px;
  }
}

@media (min-width: 1200px) {
  .home .grid .grid-contenedor .slide .cuadro_principal,
  .home .grid .grid-contenedor .slide .cuadro_secundario {
    padding: 35px 38px;
  }
}

@media (min-width: 1920px) {
  .home .grid .grid-contenedor .slide .cuadro_principal,
  .home .grid .grid-contenedor .slide .cuadro_secundario {
    padding: 56px 60.8px;
  }
}

@media (min-width: 2400px) {
  .home .grid .grid-contenedor .slide .cuadro_principal,
  .home .grid .grid-contenedor .slide .cuadro_secundario {
    padding: 70px 76px;
  }
}

.home .grid .grid-contenedor .slide .cuadro_principal .titulo,
.home .grid .grid-contenedor .slide .cuadro_secundario .titulo {
  font-size: 23px;
  margin-bottom: 7px;
}

@media (min-width: 992px) {
  .home .grid .grid-contenedor .slide .cuadro_principal .titulo,
  .home .grid .grid-contenedor .slide .cuadro_secundario .titulo {
    font-size: 19.021px;
    margin-bottom: 5.789px;
  }
}

@media (min-width: 1200px) {
  .home .grid .grid-contenedor .slide .cuadro_principal .titulo,
  .home .grid .grid-contenedor .slide .cuadro_secundario .titulo {
    font-size: 23px;
    margin-bottom: 7px;
  }
}

@media (min-width: 1920px) {
  .home .grid .grid-contenedor .slide .cuadro_principal .titulo,
  .home .grid .grid-contenedor .slide .cuadro_secundario .titulo {
    font-size: 36.8px;
    margin-bottom: 11.2px;
  }
}

@media (min-width: 2400px) {
  .home .grid .grid-contenedor .slide .cuadro_principal .titulo,
  .home .grid .grid-contenedor .slide .cuadro_secundario .titulo {
    font-size: 46px;
    margin-bottom: 14px;
  }
}

.home .grid .grid-contenedor .slide .cuadro_principal .go,
.home .grid .grid-contenedor .slide .cuadro_secundario .go {
  display: none;
  width: 92px;
  height: 42px;
  font-size: 23px;
}

@media (min-width: 992px) {
  .home .grid .grid-contenedor .slide .cuadro_principal .go,
  .home .grid .grid-contenedor .slide .cuadro_secundario .go {
    width: 76px;
    height: 35px;
    font-size: 19px;
  }
}

@media (min-width: 1200px) {
  .home .grid .grid-contenedor .slide .cuadro_principal .go,
  .home .grid .grid-contenedor .slide .cuadro_secundario .go {
    width: 92px;
    height: 42px;
    font-size: 23px;
  }
}

@media (min-width: 1920px) {
  .home .grid .grid-contenedor .slide .cuadro_principal .go,
  .home .grid .grid-contenedor .slide .cuadro_secundario .go {
    width: 147px;
    height: 67px;
    font-size: 37px;
  }
}

@media (min-width: 2400px) {
  .home .grid .grid-contenedor .slide .cuadro_principal .go,
  .home .grid .grid-contenedor .slide .cuadro_secundario .go {
    width: 184px;
    height: 84px;
    font-size: 46px;
  }
}

.home .grid .grid-contenedor .slide .cuadro_principal .go img,
.home .grid .grid-contenedor .slide .cuadro_secundario .go img {
  display: block;
  margin-left: 10px;
  height: 22px;
  padding-top: 1px;
}

@media (min-width: 992px) {
  .home .grid .grid-contenedor .slide .cuadro_principal .go img,
  .home .grid .grid-contenedor .slide .cuadro_secundario .go img {
    margin-left: 8.27px;
    height: 18.194px;
    padding-top: 0.827px;
  }
}

@media (min-width: 1200px) {
  .home .grid .grid-contenedor .slide .cuadro_principal .go img,
  .home .grid .grid-contenedor .slide .cuadro_secundario .go img {
    margin-left: 10px;
    height: 22px;
    padding-top: 1px;
  }
}

@media (min-width: 1920px) {
  .home .grid .grid-contenedor .slide .cuadro_principal .go img,
  .home .grid .grid-contenedor .slide .cuadro_secundario .go img {
    margin-left: 16px;
    height: 35.2px;
    padding-top: 1.6px;
  }
}

@media (min-width: 2400px) {
  .home .grid .grid-contenedor .slide .cuadro_principal .go img,
  .home .grid .grid-contenedor .slide .cuadro_secundario .go img {
    margin-left: 20px;
    height: 44px;
    padding-top: 2px;
  }
}

.home .grid .grid-contenedor .slide .cuadro_small {
  position: relative;
  background-size: auto 100%;
  background-position: 50% 50%;
  transition: background-size 0.5s ease-in;
  padding: 13px 14px;
}

@media (min-width: 992px) {
  .home .grid .grid-contenedor .slide .cuadro_small {
    padding: 10.751px 11.578px;
  }
}

@media (min-width: 1200px) {
  .home .grid .grid-contenedor .slide .cuadro_small {
    padding: 13px 14px;
  }
}

@media (min-width: 1920px) {
  .home .grid .grid-contenedor .slide .cuadro_small {
    padding: 20.8px 22.4px;
  }
}

@media (min-width: 2400px) {
  .home .grid .grid-contenedor .slide .cuadro_small {
    padding: 26px 28px;
  }
}

.home .grid .grid-contenedor .slide .cuadro_small .titulo {
  font-size: 23px;
  margin-bottom: 8px;
}

@media (min-width: 992px) {
  .home .grid .grid-contenedor .slide .cuadro_small .titulo {
    font-size: 19.021px;
    margin-bottom: 6.616px;
  }
}

@media (min-width: 1200px) {
  .home .grid .grid-contenedor .slide .cuadro_small .titulo {
    font-size: 23px;
    margin-bottom: 8px;
  }
}

@media (min-width: 1920px) {
  .home .grid .grid-contenedor .slide .cuadro_small .titulo {
    font-size: 36.8px;
    margin-bottom: 12.8px;
  }
}

@media (min-width: 2400px) {
  .home .grid .grid-contenedor .slide .cuadro_small .titulo {
    font-size: 46px;
    margin-bottom: 16px;
  }
}

.home .grid .grid-contenedor .slide .cuadro_small .go {
  display: none;
  width: 92px;
  height: 42px;
  font-size: 23px;
}

@media (min-width: 992px) {
  .home .grid .grid-contenedor .slide .cuadro_small .go {
    width: 76px;
    height: 35px;
    font-size: 19px;
  }
}

@media (min-width: 1200px) {
  .home .grid .grid-contenedor .slide .cuadro_small .go {
    width: 92px;
    height: 42px;
    font-size: 23px;
  }
}

@media (min-width: 1920px) {
  .home .grid .grid-contenedor .slide .cuadro_small .go {
    width: 147px;
    height: 67px;
    font-size: 37px;
  }
}

@media (min-width: 2400px) {
  .home .grid .grid-contenedor .slide .cuadro_small .go {
    width: 184px;
    height: 84px;
    font-size: 46px;
  }
}

.home .grid .grid-contenedor .slide .cuadro_small .go img {
  display: block;
  margin-left: 10px;
  height: 22px;
  padding-top: 1px;
}

@media (min-width: 992px) {
  .home .grid .grid-contenedor .slide .cuadro_small .go img {
    margin-left: 8.27px;
    height: 18.194px;
    padding-top: 0.827px;
  }
}

@media (min-width: 1200px) {
  .home .grid .grid-contenedor .slide .cuadro_small .go img {
    margin-left: 10px;
    height: 22px;
    padding-top: 1px;
  }
}

@media (min-width: 1920px) {
  .home .grid .grid-contenedor .slide .cuadro_small .go img {
    margin-left: 16px;
    height: 35.2px;
    padding-top: 1.6px;
  }
}

@media (min-width: 2400px) {
  .home .grid .grid-contenedor .slide .cuadro_small .go img {
    margin-left: 20px;
    height: 44px;
    padding-top: 2px;
  }
}

.home .grid .grid-contenedor .slide .cuadro_principal,
.home .grid .grid-contenedor .slide .cuadro_small.cuadro_small_1,
.home .grid .grid-contenedor .slide .cuadro_small.cuadro_small_2,
.home .grid .grid-contenedor .slide .cuadro_secundario {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.home .grid .grid-contenedor .slide .cuadro_principal .titulo,
.home .grid .grid-contenedor .slide .cuadro_small.cuadro_small_1 .titulo,
.home .grid .grid-contenedor .slide .cuadro_small.cuadro_small_2 .titulo,
.home .grid .grid-contenedor .slide .cuadro_secundario .titulo {
  display: none;
}

.home .grid .grid-contenedor .slide .cuadro_principal .contenedor-boton,
.home .grid .grid-contenedor .slide .cuadro_small.cuadro_small_1 .contenedor-boton,
.home .grid .grid-contenedor .slide .cuadro_small.cuadro_small_2 .contenedor-boton,
.home .grid .grid-contenedor .slide .cuadro_secundario .contenedor-boton {
  display: none;
}

.home .grid .grid-contenedor .slide .cuadro_principal:hover,
.home .grid .grid-contenedor .slide .cuadro_small.cuadro_small_1:hover,
.home .grid .grid-contenedor .slide .cuadro_small.cuadro_small_2:hover,
.home .grid .grid-contenedor .slide .cuadro_secundario:hover {
  background-size: auto 110% !important;
}

.home .grid .grid-contenedor .slide .cuadro_principal:hover .overlay_1,
.home .grid .grid-contenedor .slide .cuadro_small.cuadro_small_1:hover .overlay_1,
.home .grid .grid-contenedor .slide .cuadro_small.cuadro_small_2:hover .overlay_1,
.home .grid .grid-contenedor .slide .cuadro_secundario:hover .overlay_1 {
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #3f3f3d;
  mix-blend-mode: color;
}

.home .grid .grid-contenedor .slide .cuadro_principal:hover .overlay_2,
.home .grid .grid-contenedor .slide .cuadro_small.cuadro_small_1:hover .overlay_2,
.home .grid .grid-contenedor .slide .cuadro_small.cuadro_small_2:hover .overlay_2,
.home .grid .grid-contenedor .slide .cuadro_secundario:hover .overlay_2 {
  z-index: 2;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(106, 173, 223, 0.5);
  mix-blend-mode: color;
}

.home .grid .grid-contenedor .slide .cuadro_principal:hover .overlay_3,
.home .grid .grid-contenedor .slide .cuadro_small.cuadro_small_1:hover .overlay_3,
.home .grid .grid-contenedor .slide .cuadro_small.cuadro_small_2:hover .overlay_3,
.home .grid .grid-contenedor .slide .cuadro_secundario:hover .overlay_3 {
  z-index: 3;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: black;
  background: -moz-linear-gradient(90deg, black 0%, rgba(0, 0, 0, 0) 100%);
  background: -webkit-linear-gradient(90deg, black 0%, rgba(0, 0, 0, 0) 100%);
  background: linear-gradient(90deg, black 0%, rgba(0, 0, 0, 0) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000",endColorstr="#000000",GradientType=1);
  opacity: 0.7;
}

.home .grid .grid-contenedor .slide .cuadro_principal:hover .titulo,
.home .grid .grid-contenedor .slide .cuadro_small.cuadro_small_1:hover .titulo,
.home .grid .grid-contenedor .slide .cuadro_small.cuadro_small_2:hover .titulo,
.home .grid .grid-contenedor .slide .cuadro_secundario:hover .titulo {
  display: block;
  z-index: 4;
  font-family: "Gotham-Black";
  color: white;
}

.home .grid .grid-contenedor .slide .cuadro_principal:hover .go,
.home .grid .grid-contenedor .slide .cuadro_small.cuadro_small_1:hover .go,
.home .grid .grid-contenedor .slide .cuadro_small.cuadro_small_2:hover .go,
.home .grid .grid-contenedor .slide .cuadro_secundario:hover .go {
  z-index: 5;
  text-decoration: none;
  display: flex !important;
  justify-content: center;
  align-items: center;
  font-family: "Gotham-Book";
  color: #fff;
  position: relative;
}

.home .grid .grid-contenedor .slide .cuadro_principal:hover .go:before,
.home .grid .grid-contenedor .slide .cuadro_small.cuadro_small_1:hover .go:before,
.home .grid .grid-contenedor .slide .cuadro_small.cuadro_small_2:hover .go:before,
.home .grid .grid-contenedor .slide .cuadro_secundario:hover .go:before {
  content: '';
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  /*background: rgb(106,173,223);
								background: -moz-linear-gradient(90deg, rgba(106,173,223,1) 0%, rgba(166,187,53,1) 100%);
								background: -webkit-linear-gradient(90deg, rgba(106,173,223,1) 0%, rgba(166,187,53,1) 100%);
								background: linear-gradient(90deg, rgba(106,173,223,1) 0%, rgba(166,187,53,1) 100%);
								filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#6aaddf",endColorstr="#a6bb35",GradientType=1); 
								*/
  background: #6aaddf;
  background: -moz-linear-gradient(90deg, #6aaddf 0%, #9e1b7e 100%);
  background: -webkit-linear-gradient(90deg, #6aaddf 0%, #9e1b7e 100%);
  background: linear-gradient(90deg, #6aaddf 0%, #9e1b7e 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#6aaddf",endColorstr="#9e1b7e",GradientType=1);
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: destination-out;
  mask-composite: exclude;
  padding: 2px;
  border-radius: 35px;
}

@media (min-width: 992px) {
  .home .grid .grid-contenedor .slide .cuadro_principal:hover .go:before,
  .home .grid .grid-contenedor .slide .cuadro_small.cuadro_small_1:hover .go:before,
  .home .grid .grid-contenedor .slide .cuadro_small.cuadro_small_2:hover .go:before,
  .home .grid .grid-contenedor .slide .cuadro_secundario:hover .go:before {
    padding: 1px;
    border-radius: 29px;
  }
}

@media (min-width: 1200px) {
  .home .grid .grid-contenedor .slide .cuadro_principal:hover .go:before,
  .home .grid .grid-contenedor .slide .cuadro_small.cuadro_small_1:hover .go:before,
  .home .grid .grid-contenedor .slide .cuadro_small.cuadro_small_2:hover .go:before,
  .home .grid .grid-contenedor .slide .cuadro_secundario:hover .go:before {
    padding: 2px;
    border-radius: 35px;
  }
}

@media (min-width: 1920px) {
  .home .grid .grid-contenedor .slide .cuadro_principal:hover .go:before,
  .home .grid .grid-contenedor .slide .cuadro_small.cuadro_small_1:hover .go:before,
  .home .grid .grid-contenedor .slide .cuadro_small.cuadro_small_2:hover .go:before,
  .home .grid .grid-contenedor .slide .cuadro_secundario:hover .go:before {
    padding: 3px;
    border-radius: 56px;
  }
}

@media (min-width: 2400px) {
  .home .grid .grid-contenedor .slide .cuadro_principal:hover .go:before,
  .home .grid .grid-contenedor .slide .cuadro_small.cuadro_small_1:hover .go:before,
  .home .grid .grid-contenedor .slide .cuadro_small.cuadro_small_2:hover .go:before,
  .home .grid .grid-contenedor .slide .cuadro_secundario:hover .go:before {
    padding: 4px;
    border-radius: 70px;
  }
}

.home .grid .grid-contenedor .slide[data-slide='1'] {
  left: 0;
}

.home .grid .grid-contenedor .slide[data-slide='1'] .cuadro_principal {
  width: 42.5%;
  height: 100%;
}

.home .grid .grid-contenedor .slide[data-slide='1'] .cuadro_small.cuadro_small_1 {
  width: calc(21.5% - 6px);
  height: calc(50% - 1.5px);
}

@media (min-width: 992px) {
  .home .grid .grid-contenedor .slide[data-slide='1'] .cuadro_small.cuadro_small_1 {
    width: calc(21.5% - 6px * 0.827);
    height: calc(50% - 1.5px * 0.827);
  }
}

@media (min-width: 1200px) {
  .home .grid .grid-contenedor .slide[data-slide='1'] .cuadro_small.cuadro_small_1 {
    width: calc(21.5% - 6px);
    height: calc(50% - 1.5px);
  }
}

@media (min-width: 1920px) {
  .home .grid .grid-contenedor .slide[data-slide='1'] .cuadro_small.cuadro_small_1 {
    width: calc(21.5% - 6px * 1.6);
    height: calc(50% - 1.5px * 1.6);
  }
}

@media (min-width: 2400px) {
  .home .grid .grid-contenedor .slide[data-slide='1'] .cuadro_small.cuadro_small_1 {
    width: calc(21.5% - 6px * 2);
    height: calc(50% - 1.5px * 2);
  }
}

.home .grid .grid-contenedor .slide[data-slide='1'] .cuadro_small.cuadro_small_2 {
  width: calc(21.5% - 6px);
  height: calc(50% - 1.5px);
}

@media (min-width: 992px) {
  .home .grid .grid-contenedor .slide[data-slide='1'] .cuadro_small.cuadro_small_2 {
    width: calc(21.5% - 6px * 0.827);
    height: calc(50% - 1.5px * 0.827);
  }
}

@media (min-width: 1200px) {
  .home .grid .grid-contenedor .slide[data-slide='1'] .cuadro_small.cuadro_small_2 {
    width: calc(21.5% - 6px);
    height: calc(50% - 1.5px);
  }
}

@media (min-width: 1920px) {
  .home .grid .grid-contenedor .slide[data-slide='1'] .cuadro_small.cuadro_small_2 {
    width: calc(21.5% - 6px * 1.6);
    height: calc(50% - 1.5px * 1.6);
  }
}

@media (min-width: 2400px) {
  .home .grid .grid-contenedor .slide[data-slide='1'] .cuadro_small.cuadro_small_2 {
    width: calc(21.5% - 6px * 2);
    height: calc(50% - 1.5px * 2);
  }
}

.home .grid .grid-contenedor .slide[data-slide='1'] .cuadro_secundario {
  width: 36%;
  height: 100%;
}

.home .grid .grid-contenedor .slide[data-slide='2'] {
  left: 100%;
  z-index: 9;
}

.home .grid .grid-contenedor .slide[data-slide='2'] .cuadro_principal {
  width: 42.5%;
  height: 100%;
}

.home .grid .grid-contenedor .slide[data-slide='2'] .cuadro_small.cuadro_small_1 {
  width: calc(21.5% - 6px);
  height: calc(50% - 1.5px);
}

@media (min-width: 992px) {
  .home .grid .grid-contenedor .slide[data-slide='2'] .cuadro_small.cuadro_small_1 {
    width: calc(21.5% - 6px * 0.827);
    height: calc(50% - 1.5px * 0.827);
  }
}

@media (min-width: 1200px) {
  .home .grid .grid-contenedor .slide[data-slide='2'] .cuadro_small.cuadro_small_1 {
    width: calc(21.5% - 6px);
    height: calc(50% - 1.5px);
  }
}

@media (min-width: 1920px) {
  .home .grid .grid-contenedor .slide[data-slide='2'] .cuadro_small.cuadro_small_1 {
    width: calc(21.5% - 6px * 1.6);
    height: calc(50% - 1.5px * 1.6);
  }
}

@media (min-width: 2400px) {
  .home .grid .grid-contenedor .slide[data-slide='2'] .cuadro_small.cuadro_small_1 {
    width: calc(21.5% - 6px * 2);
    height: calc(50% - 1.5px * 2);
  }
}

.home .grid .grid-contenedor .slide[data-slide='2'] .cuadro_small.cuadro_small_2 {
  width: calc(21.5% - 6px);
  height: calc(50% - 1.5px);
}

@media (min-width: 992px) {
  .home .grid .grid-contenedor .slide[data-slide='2'] .cuadro_small.cuadro_small_2 {
    width: calc(21.5% - 6px * 0.827);
    height: calc(50% - 1.5px * 0.827);
  }
}

@media (min-width: 1200px) {
  .home .grid .grid-contenedor .slide[data-slide='2'] .cuadro_small.cuadro_small_2 {
    width: calc(21.5% - 6px);
    height: calc(50% - 1.5px);
  }
}

@media (min-width: 1920px) {
  .home .grid .grid-contenedor .slide[data-slide='2'] .cuadro_small.cuadro_small_2 {
    width: calc(21.5% - 6px * 1.6);
    height: calc(50% - 1.5px * 1.6);
  }
}

@media (min-width: 2400px) {
  .home .grid .grid-contenedor .slide[data-slide='2'] .cuadro_small.cuadro_small_2 {
    width: calc(21.5% - 6px * 2);
    height: calc(50% - 1.5px * 2);
  }
}

.home .grid .grid-contenedor .slide[data-slide='2'] .cuadro_secundario {
  width: 36%;
  height: 100%;
}

.home .grid .botones {
  width: 6%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  z-index: 10;
}

.home .grid .botones .flecha {
  background-image: url(../img/home/mayorque.svg);
  background-size: 100%;
  background-repeat: no-repeat;
  cursor: pointer;
  transition: transform 0.4s;
  width: 29px;
  height: 46px;
}

@media (min-width: 992px) {
  .home .grid .botones .flecha {
    width: 23.983px;
    height: 38.042px;
  }
}

@media (min-width: 1200px) {
  .home .grid .botones .flecha {
    width: 29px;
    height: 46px;
  }
}

@media (min-width: 1920px) {
  .home .grid .botones .flecha {
    width: 46.4px;
    height: 73.6px;
  }
}

@media (min-width: 2400px) {
  .home .grid .botones .flecha {
    width: 58px;
    height: 92px;
  }
}

.home .grid .botones .flecha.active {
  transform: rotate(-180deg);
}

.home .grid_mobile {
  padding-bottom: 24px;
}

@media (min-width: 414px) {
  .home .grid_mobile {
    padding-bottom: 30.96px;
  }
}

@media (min-width: 768px) {
  .home .grid_mobile {
    padding-bottom: 57.6px;
  }
}

.home .grid_mobile > .container {
  padding-left: 0;
  padding-right: 0;
}

.home .grid_mobile > .container > .row {
  margin-left: 0;
  margin-right: 0;
}

.home .grid_mobile .carousel-indicators {
  z-index: 10;
  margin-bottom: 0;
  bottom: -24px;
}

@media (min-width: 414px) {
  .home .grid_mobile .carousel-indicators {
    bottom: -30.96px;
  }
}

@media (min-width: 768px) {
  .home .grid_mobile .carousel-indicators {
    bottom: -57.6px;
  }
}

.home .grid_mobile .carousel-indicators button {
  border-radius: 50%;
  border: 1px solid #1b1b1a;
  width: 5px;
  height: 5px;
  margin-right: 9px;
  margin-left: 9px;
}

@media (min-width: 414px) {
  .home .grid_mobile .carousel-indicators button {
    width: 6.45px;
    height: 6.45px;
    margin-right: 11.61px;
    margin-left: 11.61px;
  }
}

@media (min-width: 768px) {
  .home .grid_mobile .carousel-indicators button {
    width: 12px;
    height: 12px;
    margin-right: 21.6px;
    margin-left: 21.6px;
  }
}

.home .grid_mobile .carousel-indicators button.active {
  background-color: #b81480;
  border: 1px solid #b81480;
}

@media (min-width: 768px) {
  .home .grid_mobile .carousel-indicators button.active {
    border: 2px solid #b81480;
  }
}

.home .grid_mobile .carousel-item {
  transition: transform 0.6s ease-in-out;
  position: relative;
}

.home .grid_mobile .carousel-item .imagen {
  display: flex;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: 50% 50%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 0;
}

.home .grid_mobile .carousel-item .imagen .overlay_1 {
  z-index: 3;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: black;
  background: -moz-linear-gradient(0deg, black 0%, rgba(0, 0, 0, 0) 100%);
  background: -webkit-linear-gradient(0deg, black 0%, rgba(0, 0, 0, 0) 100%);
  background: linear-gradient(0deg, black 0%, rgba(0, 0, 0, 0) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000",endColorstr="#000000",GradientType=1);
  opacity: 0.7;
}

.home .grid_mobile .carousel-item .imagen .titulo {
  font-family: "Gotham-Black";
  color: #fff;
  font-size: 20px;
  margin-bottom: 2px;
  position: relative;
  z-index: 5;
}

@media (min-width: 414px) {
  .home .grid_mobile .carousel-item .imagen .titulo {
    font-size: 25.8px;
    margin-bottom: 2.58px;
  }
}

@media (min-width: 768px) {
  .home .grid_mobile .carousel-item .imagen .titulo {
    font-size: 48px;
    margin-bottom: 4.8px;
  }
}

.home .grid_mobile .carousel-item:after {
  padding-top: 56.25%;
  /* 16:9 ratio */
  display: block;
  content: '';
}

.home .grid_mobile .boton-mobil {
  display: block;
  width: 71px;
  height: 30px;
  background-image: url(../img/home/boton-carousel.svg);
  background-size: 100% auto;
  background-repeat: no-repeat;
  position: relative;
  z-index: 5;
}

@media (min-width: 414px) {
  .home .grid_mobile .boton-mobil {
    width: 91.59px;
    height: 38.7px;
  }
}

@media (min-width: 768px) {
  .home .grid_mobile .boton-mobil {
    width: 170.4px;
    height: 72px;
  }
}

.home .partners {
  position: relative;
  padding-bottom: 20px;
}

@media (min-width: 414px) {
  .home .partners {
    padding-bottom: 25.8px;
  }
}

@media (min-width: 768px) {
  .home .partners {
    padding-bottom: 48px;
  }
}

@media (min-width: 992px) {
  .home .partners {
    padding-bottom: 0px;
  }
}

@media (min-width: 992px) {
  .home .partners .linea {
    position: absolute;
    background-color: #9e1b7e;
    right: 0;
    top: 124.05px;
    width: 31.426px;
    height: 346.513px;
  }
}

@media (min-width: 1200px) {
  .home .partners .linea {
    top: 150px;
    width: 38px;
    height: 419px;
  }
}

@media (min-width: 1920px) {
  .home .partners .linea {
    top: 240px;
    width: 60.8px;
    height: 670.4px;
  }
}

@media (min-width: 2400px) {
  .home .partners .linea {
    top: 300px;
    width: 76px;
    height: 838px;
  }
}

.home .partners .cuadrito {
  position: absolute;
  width: 5px;
  height: 5px;
  top: 376px;
  right: 26px;
}

@media (min-width: 414px) {
  .home .partners .cuadrito {
    width: 6.45px;
    height: 6.45px;
    top: 485.04px;
    right: 33.54px;
  }
}

@media (min-width: 768px) {
  .home .partners .cuadrito {
    width: 12px;
    height: 12px;
    top: 902.4px;
    right: 62.4px;
  }
}

@media (min-width: 992px) {
  .home .partners .cuadrito {
    width: 15.713px;
    height: 15.713px;
    bottom: 66.16px;
    right: 14.135px;
    top: initial;
  }
}

@media (min-width: 1200px) {
  .home .partners .cuadrito {
    width: 19px;
    height: 19px;
    bottom: 80px;
    right: 5px;
  }
}

@media (min-width: 1920px) {
  .home .partners .cuadrito {
    width: 30.4px;
    height: 30.4px;
    bottom: 128px;
    right: 8px;
  }
}

@media (min-width: 2400px) {
  .home .partners .cuadrito {
    width: 38px;
    height: 38px;
    bottom: 160px;
    right: 10px;
  }
}

.home .partners > div {
  position: relative;
}

@media (min-width: 992px) {
  .home .partners > div {
    padding: 0;
    padding-top: 82.7px;
    padding-bottom: 144.725px;
  }
}

@media (min-width: 1200px) {
  .home .partners > div {
    padding-top: 100px;
    padding-bottom: 175px;
  }
}

@media (min-width: 1920px) {
  .home .partners > div {
    padding-top: 160px;
    padding-bottom: 280px;
  }
}

@media (min-width: 2400px) {
  .home .partners > div {
    padding-top: 200px;
    padding-bottom: 350px;
  }
}

.home .partners .titulo {
  font-family: "Gotham-LightItalic";
  color: #41413f;
  font-size: 15px;
  text-align: center;
  padding-top: 49px;
  padding-bottom: 21px;
}

@media (min-width: 414px) {
  .home .partners .titulo {
    font-size: 19.35px;
    padding-top: 63.21px;
    padding-bottom: 27.09px;
  }
}

@media (min-width: 768px) {
  .home .partners .titulo {
    font-size: 36px;
    padding-top: 117.6px;
    padding-bottom: 50.4px;
  }
}

@media (min-width: 992px) {
  .home .partners .titulo {
    text-align: left;
    font-size: 19.021px;
    padding-left: 37.215px;
    padding-top: 3.308px;
    margin-top: 0;
    padding-bottom: initial;
  }
}

@media (min-width: 1200px) {
  .home .partners .titulo {
    font-size: 23px;
    padding-left: 45px;
    padding-top: 4px;
  }
}

@media (min-width: 1920px) {
  .home .partners .titulo {
    font-size: 36.8px;
    padding-left: 72px;
    padding-top: 6.4px;
  }
}

@media (min-width: 2400px) {
  .home .partners .titulo {
    font-size: 46px;
    padding-left: 90px;
    padding-top: 8px;
  }
}

.home .partners .logos {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 24.25%);
  grid-column-gap: 1%;
  grid-row-gap: 7px;
}

@media (min-width: 414px) {
  .home .partners .logos {
    grid-row-gap: 9.03px;
  }
}

@media (min-width: 768px) {
  .home .partners .logos {
    grid-row-gap: 16.8px;
  }
}

@media (min-width: 992px) {
  .home .partners .logos {
    grid-template-columns: repeat(5, 19%);
    grid-column-gap: 1.25%;
    grid-row-gap: 24.81px;
  }
}

@media (min-width: 1200px) {
  .home .partners .logos {
    grid-row-gap: 30px;
  }
}

@media (min-width: 1920px) {
  .home .partners .logos {
    grid-row-gap: 48px;
  }
}

@media (min-width: 2400px) {
  .home .partners .logos {
    grid-row-gap: 60px;
  }
}

.home .partners .logos .logo {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity 0.1s ease-in;
  opacity: 0.495;
}

.home .partners .logos .logo img {
  width: 80%;
}

.home #footer_home {
  background-color: #941b7e;
  width: 100%;
  height: 25px;
  margin-top: 84px;
}

@media (min-width: 414px) {
  .home #footer_home {
    height: 32.25px;
    margin-top: 108.36px;
  }
}

@media (min-width: 768px) {
  .home #footer_home {
    height: 60px;
    margin-top: 201.6px;
  }
}

@media (min-width: 992.4px) {
  .home #footer_home {
    display: none;
  }
}

.jobs {
  min-height: 480px;
}

@media (max-width: 991.4px) {
  .jobs .esquina_derecha {
    background: none;
    display: none;
  }
}

@media (min-width: 414px) {
  .jobs {
    min-height: 619.2px;
  }
}

@media (min-width: 768px) {
  .jobs {
    min-height: 1008px;
  }
}

@media (min-width: 992.4px) {
  .jobs {
    min-height: 545.82px;
  }
}

@media (min-width: 1200px) {
  .jobs {
    min-height: 660px;
  }
}

@media (min-width: 1920px) {
  .jobs {
    min-height: 1056px;
  }
}

@media (min-width: 2400px) {
  .jobs {
    min-height: 1320px;
  }
}

@media (min-width: 992.4px) {
  .jobs .seccion_0 {
    display: none;
  }
}

.jobs .seccion_0 .breadcrumb {
  line-height: 1;
  position: relative;
  display: block;
  display: inline-block;
  margin-bottom: 0px;
  padding-bottom: 9px;
  margin-top: 14px;
  margin-left: 8px;
}

@media (min-width: 414px) {
  .jobs .seccion_0 .breadcrumb {
    padding-bottom: 11.61px;
    margin-top: 18.06px;
    margin-left: 10.32px;
  }
}

@media (min-width: 768px) {
  .jobs .seccion_0 .breadcrumb {
    padding-bottom: 21.6px;
    margin-top: 33.6px;
    margin-left: 19.2px;
  }
}

.jobs .seccion_0 .breadcrumb a {
  font-family: "Gotham-Book";
  font-size: 9px;
  color: #3c3d38;
  text-decoration: none;
}

@media (min-width: 414px) {
  .jobs .seccion_0 .breadcrumb a {
    font-size: 11.61px;
  }
}

@media (min-width: 768px) {
  .jobs .seccion_0 .breadcrumb a {
    font-size: 21.6px;
  }
}

.jobs .seccion_0 .breadcrumb a.active {
  font-family: "Gotham-Bold";
  margin-right: 0px;
}

.jobs .seccion_0 .breadcrumb span {
  color: #000000;
  font-family: "Gotham-Book";
  font-size: 9px;
  margin-left: 3px;
  margin-right: 0px;
}

@media (min-width: 414px) {
  .jobs .seccion_0 .breadcrumb span {
    font-size: 11.61px;
    margin-left: 3.87px;
  }
}

@media (min-width: 768px) {
  .jobs .seccion_0 .breadcrumb span {
    font-size: 21.6px;
    margin-left: 7.2px;
  }
}

.jobs .seccion_0 .breadcrumb::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 1px;
  background: #000;
  bottom: 0;
  left: 0;
}

.jobs .seccion_1 {
  position: relative;
  padding-top: 30px;
}

.jobs .seccion_1 .cuadrito_inicial {
  position: absolute;
  z-index: 11;
  width: 5px;
  height: 5px;
  top: 295px;
  right: 26px;
}

@media (min-width: 414px) {
  .jobs .seccion_1 .cuadrito_inicial {
    width: 6.45px;
    height: 6.45px;
    top: 380.55px;
    right: 33.54px;
  }
}

@media (min-width: 768px) {
  .jobs .seccion_1 .cuadrito_inicial {
    width: 12px;
    height: 12px;
    top: 568px;
    right: 62.4px;
  }
}

@media (min-width: 992.4px) {
  .jobs .seccion_1 .cuadrito_inicial {
    width: 15.713px;
    height: 15.713px;
    top: 395.306px;
    right: 4.135px;
  }
}

@media (min-width: 1200px) {
  .jobs .seccion_1 .cuadrito_inicial {
    width: 19px;
    height: 19px;
    top: 478px;
    right: 5px;
  }
}

@media (min-width: 1920px) {
  .jobs .seccion_1 .cuadrito_inicial {
    width: 30.4px;
    height: 30.4px;
    top: 764.8px;
    right: 8px;
  }
}

@media (min-width: 2400px) {
  .jobs .seccion_1 .cuadrito_inicial {
    width: 38px;
    height: 38px;
    top: 956px;
    right: 10px;
  }
}

.jobs .seccion_1 .container {
  position: relative;
}

@media (min-width: 414px) {
  .jobs .seccion_1 {
    padding-top: 38.7px;
  }
}

@media (min-width: 768px) {
  .jobs .seccion_1 {
    padding-top: 72px;
  }
}

@media (min-width: 992.4px) {
  .jobs .seccion_1 {
    padding-top: 109.164px;
  }
}

@media (min-width: 1200px) {
  .jobs .seccion_1 {
    padding-top: 132px;
  }
}

@media (min-width: 1920px) {
  .jobs .seccion_1 {
    padding-top: 211.2px;
  }
}

@media (min-width: 2400px) {
  .jobs .seccion_1 {
    padding-top: 264px;
  }
}

.jobs .seccion_1 .linea {
  height: 2px;
  width: 10px;
  margin-left: 8px;
}

@media (min-width: 414px) {
  .jobs .seccion_1 .linea {
    margin-left: 10.32px;
  }
}

@media (min-width: 768px) {
  .jobs .seccion_1 .linea {
    margin-left: 19.2px;
  }
}

@media (min-width: 992.4px) {
  .jobs .seccion_1 .linea {
    margin-left: 0;
  }
}

@media (min-width: 414px) {
  .jobs .seccion_1 .linea {
    height: 2.58px;
    width: 12.9px;
  }
}

@media (min-width: 768px) {
  .jobs .seccion_1 .linea {
    height: 4.8px;
    width: 24px;
  }
}

@media (min-width: 992.4px) {
  .jobs .seccion_1 .linea {
    height: 8.27px;
    width: 24.81px;
  }
}

@media (min-width: 1200px) {
  .jobs .seccion_1 .linea {
    height: 10px;
    width: 30px;
  }
}

@media (min-width: 1920px) {
  .jobs .seccion_1 .linea {
    height: 16px;
    width: 48px;
  }
}

@media (min-width: 2400px) {
  .jobs .seccion_1 .linea {
    height: 20px;
    width: 60px;
  }
}

.jobs .seccion_1 .titulo {
  font-family: "Gotham-Medium";
  color: #3f3f3d;
  line-height: 1.2;
  margin-bottom: 31px;
  font-size: 20px;
  margin-left: 8px;
  margin-top: 5px;
}

@media (min-width: 414px) {
  .jobs .seccion_1 .titulo {
    font-size: 25.8px;
    margin-left: 10.32px;
    margin-top: 6.45px;
    margin-bottom: 39.99px;
  }
}

@media (min-width: 768px) {
  .jobs .seccion_1 .titulo {
    font-size: 48px;
    margin-left: 19.2px;
    margin-top: 12px;
    margin-bottom: 74.4px;
  }
}

@media (min-width: 992.4px) {
  .jobs .seccion_1 .titulo {
    margin-left: 0;
    margin-top: 0;
  }
}

@media (min-width: 992.4px) {
  .jobs .seccion_1 .titulo {
    font-size: 49.62px;
    margin-bottom: 49.62px;
  }
}

@media (min-width: 1200px) {
  .jobs .seccion_1 .titulo {
    font-size: 60px;
    margin-bottom: 60px;
  }
}

@media (min-width: 1920px) {
  .jobs .seccion_1 .titulo {
    font-size: 96px;
    margin-bottom: 96px;
  }
}

@media (min-width: 2400px) {
  .jobs .seccion_1 .titulo {
    font-size: 120px;
    margin-bottom: 120px;
  }
}

.jobs .seccion_1 .titulo span {
  font-family: "Gotham-Black";
}

.jobs .seccion_1 .descripcion {
  font-family: "Gotham-Book";
  line-height: 1.2;
  color: #3c3d38;
  margin: 0 auto 40px;
  text-align: justify;
  font-size: 9px;
  width: 240px;
}

@media (min-width: 414px) {
  .jobs .seccion_1 .descripcion {
    font-size: 11.61px;
    width: 309.6px;
    margin: 0 auto 51.6px;
  }
}

@media (min-width: 768px) {
  .jobs .seccion_1 .descripcion {
    font-size: 21.6px;
    width: 576px;
    margin: 0 auto 96px;
  }
}

@media (min-width: 992.4px) {
  .jobs .seccion_1 .descripcion {
    text-align: left;
    letter-spacing: -0.016em;
    font-size: 15.713px;
    width: 421.77px;
    margin-bottom: 62.025px;
    margin-left: initial;
    margin-right: initial;
  }
}

@media (min-width: 1200px) {
  .jobs .seccion_1 .descripcion {
    font-size: 19px;
    width: 510px;
    margin-bottom: 75px;
  }
}

@media (min-width: 1920px) {
  .jobs .seccion_1 .descripcion {
    font-size: 30.4px;
    width: 816px;
    margin-bottom: 120px;
  }
}

@media (min-width: 2400px) {
  .jobs .seccion_1 .descripcion {
    font-size: 38px;
    width: 1020px;
    margin-bottom: 150px;
  }
}

.jobs .seccion_2 > div > div > div {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.jobs .seccion_2 .linea {
  width: 343px;
  height: 1px;
  background-color: #3f3f3d;
  display: none;
}

@media (min-width: 992.4px) {
  .jobs .seccion_2 .linea {
    display: flex;
    width: 283.661px;
    height: 0.827px;
  }
}

@media (min-width: 1200px) {
  .jobs .seccion_2 .linea {
    width: 343px;
    height: 1px;
  }
}

@media (min-width: 1920px) {
  .jobs .seccion_2 .linea {
    width: 548.8px;
    height: 1.6px;
  }
}

@media (min-width: 2400px) {
  .jobs .seccion_2 .linea {
    width: 686px;
    height: 2px;
  }
}

.jobs .seccion_2 .linea_2 {
  width: 343px;
  height: 1px;
  display: none;
}

@media (min-width: 992.4px) {
  .jobs .seccion_2 .linea_2 {
    display: flex;
    width: 283.661px;
    height: 0.827px;
  }
}

@media (min-width: 1200px) {
  .jobs .seccion_2 .linea_2 {
    width: 343px;
    height: 1px;
  }
}

@media (min-width: 1920px) {
  .jobs .seccion_2 .linea_2 {
    width: 548.8px;
    height: 1.6px;
  }
}

@media (min-width: 2400px) {
  .jobs .seccion_2 .linea_2 {
    width: 686px;
    height: 2px;
  }
}

.jobs .seccion_2 .contenedor-boton {
  display: flex;
  align-items: center;
  margin: auto;
  position: relative;
  box-sizing: border-box;
  color: #fff;
  background: #fff;
  background-clip: padding-box;
  /* !importanté */
  width: 230px;
  height: 30px;
  border-radius: 30px;
  font-size: 10px;
  border: solid 1px transparent;
  /* !importanté */
}

@media (min-width: 414px) {
  .jobs .seccion_2 .contenedor-boton {
    width: 296.7px;
    height: 38.7px;
    font-size: 12.9px;
    border-radius: 38.7px;
  }
}

@media (min-width: 768px) {
  .jobs .seccion_2 .contenedor-boton {
    width: 552px;
    height: 72px;
    font-size: 24px;
    border-radius: 72px;
  }
}

@media (min-width: 992.4px) {
  .jobs .seccion_2 .contenedor-boton {
    width: 251px;
    height: 33.08px;
    padding-left: 8px;
    border-radius: 33.08px;
  }
}

@media (min-width: 1200px) {
  .jobs .seccion_2 .contenedor-boton {
    width: 304px;
    height: 40px;
    padding-left: 10px;
    border-radius: 40px;
  }
}

@media (min-width: 1920px) {
  .jobs .seccion_2 .contenedor-boton {
    width: 486px;
    height: 64px;
    padding-left: 16px;
    border-radius: 64px;
    border: solid 1px transparent;
    /* !importanté */
  }
}

@media (min-width: 2400px) {
  .jobs .seccion_2 .contenedor-boton {
    width: 608px;
    height: 78px;
    padding-left: 20px;
    border-radius: 78px;
  }
}

.jobs .seccion_2 .contenedor-boton:before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  margin: -1px;
  /* !importanté */
  border-radius: inherit;
  /* !importanté */
  background: linear-gradient(90deg, #6aaddf 0%, #9e1b7e 100%);
  margin: -1px;
  /* !importanté */
}

@media (min-width: 992.4px) {
  .jobs .seccion_2 .contenedor-boton:before {
    margin: -1px;
    /* !importanté */
  }
}

@media (min-width: 1200px) {
  .jobs .seccion_2 .contenedor-boton:before {
    margin: -1px;
    /* !importanté */
  }
}

@media (min-width: 1920px) {
  .jobs .seccion_2 .contenedor-boton:before {
    margin: -2px;
    /* !importanté */
  }
}

@media (min-width: 2400px) {
  .jobs .seccion_2 .contenedor-boton:before {
    margin: -2px;
    /* !importanté */
  }
}

.jobs .seccion_2 .contenedor-boton .go {
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  font-family: "Gotham-Medium";
  color: #3f3f3d;
  font-size: 10px;
}

@media (min-width: 414px) {
  .jobs .seccion_2 .contenedor-boton .go {
    font-size: 12.9px;
  }
}

@media (min-width: 768px) {
  .jobs .seccion_2 .contenedor-boton .go {
    font-size: 24px;
  }
}

@media (min-width: 992.4px) {
  .jobs .seccion_2 .contenedor-boton .go {
    font-size: 13.232px;
  }
}

@media (min-width: 1200px) {
  .jobs .seccion_2 .contenedor-boton .go {
    font-size: 16px;
  }
}

@media (min-width: 1920px) {
  .jobs .seccion_2 .contenedor-boton .go {
    font-size: 25.6px;
  }
}

@media (min-width: 2400px) {
  .jobs .seccion_2 .contenedor-boton .go {
    font-size: 32px;
  }
}

.jobs .seccion_2 .contenedor-boton .go img {
  margin-left: 10px;
  height: 10px;
  width: auto;
}

@media (min-width: 414px) {
  .jobs .seccion_2 .contenedor-boton .go img {
    height: 12.9px;
    margin-left: 12.9px;
  }
}

@media (min-width: 768px) {
  .jobs .seccion_2 .contenedor-boton .go img {
    height: 24px;
    margin-left: 24px;
  }
}

@media (min-width: 992px) {
  .jobs .seccion_2 .contenedor-boton .go img {
    height: 10.751px;
    margin-left: 9.097px;
  }
}

@media (min-width: 1200px) {
  .jobs .seccion_2 .contenedor-boton .go img {
    margin-left: 11px;
    height: 13px;
  }
}

@media (min-width: 1920px) {
  .jobs .seccion_2 .contenedor-boton .go img {
    height: 20.8px;
    margin-left: 17.6px;
  }
}

@media (min-width: 2400px) {
  .jobs .seccion_2 .contenedor-boton .go img {
    height: 26px;
    margin-left: 22px;
  }
}

.jobs #footer_jobs {
  position: fixed;
  background-color: #941b7e;
  width: 100%;
  height: 25px;
  bottom: 0;
}

@media (min-width: 414px) {
  .jobs #footer_jobs {
    height: 32.25px;
  }
}

@media (min-width: 768px) {
  .jobs #footer_jobs {
    height: 60px;
  }
}

@media (min-width: 992.4px) {
  .jobs #footer_jobs {
    display: none;
  }
}

.contact {
  min-height: 510px;
}

.contact .esquina_derecha {
  background: none;
  display: none;
}

@media (min-width: 414px) {
  .contact {
    min-height: 657.9px;
  }
}

@media (min-width: 768px) {
  .contact {
    min-height: 1224px;
  }
}

@media (min-width: 992px) {
  .contact {
    min-height: 670px;
  }
}

@media (min-width: 1200px) {
  .contact {
    min-height: 800px;
  }
}

@media (min-width: 1920px) {
  .contact {
    min-height: 1280px;
  }
}

@media (min-width: 2400px) {
  .contact {
    min-height: 1640px;
  }
}

@media (min-width: 992px) {
  .contact .seccion_0 {
    display: none;
  }
}

.contact .seccion_0 .breadcrumb {
  line-height: 1;
  position: relative;
  display: block;
  display: inline-block;
  margin-bottom: 0px;
  padding-bottom: 9px;
  margin-top: 14px;
  margin-left: 8px;
}

@media (min-width: 414px) {
  .contact .seccion_0 .breadcrumb {
    padding-bottom: 11.61px;
    margin-top: 18.06px;
    margin-left: 10.32px;
  }
}

@media (min-width: 768px) {
  .contact .seccion_0 .breadcrumb {
    padding-bottom: 21.6px;
    margin-top: 33.6px;
    margin-left: 19.2px;
  }
}

.contact .seccion_0 .breadcrumb a {
  font-family: "Gotham-Book";
  font-size: 9px;
  color: #3c3d38;
  text-decoration: none;
}

@media (min-width: 414px) {
  .contact .seccion_0 .breadcrumb a {
    font-size: 11.61px;
  }
}

@media (min-width: 768px) {
  .contact .seccion_0 .breadcrumb a {
    font-size: 21.6px;
  }
}

.contact .seccion_0 .breadcrumb a.active {
  font-family: "Gotham-Bold";
  margin-right: 0px;
}

.contact .seccion_0 .breadcrumb span {
  color: #000000;
  font-family: "Gotham-Book";
  font-size: 9px;
  margin-left: 3px;
  margin-right: 0px;
}

@media (min-width: 414px) {
  .contact .seccion_0 .breadcrumb span {
    font-size: 11.61px;
    margin-left: 3.87px;
  }
}

@media (min-width: 768px) {
  .contact .seccion_0 .breadcrumb span {
    font-size: 21.6px;
    margin-left: 7.2px;
  }
}

.contact .seccion_0 .breadcrumb::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 1px;
  background: #000;
  bottom: 0;
  left: 0;
}

.contact .seccion_1 {
  position: relative;
  padding-top: 30px;
}

.contact .seccion_1 .cuadrito_inicial {
  position: absolute;
  z-index: 11;
  display: none;
}

@media (min-width: 992px) {
  .contact .seccion_1 .cuadrito_inicial {
    display: block;
    width: 15.713px;
    height: 15.713px;
    top: 395.306px;
    right: 4.135px;
  }
}

@media (min-width: 1200px) {
  .contact .seccion_1 .cuadrito_inicial {
    width: 19px;
    height: 19px;
    top: 478px;
    right: 5px;
  }
}

@media (min-width: 1920px) {
  .contact .seccion_1 .cuadrito_inicial {
    width: 30.4px;
    height: 30.4px;
    top: 764.8px;
    right: 8px;
  }
}

@media (min-width: 2400px) {
  .contact .seccion_1 .cuadrito_inicial {
    width: 38px;
    height: 38px;
    top: 956px;
    right: 10px;
  }
}

.contact .seccion_1 .container {
  position: relative;
}

@media (min-width: 414px) {
  .contact .seccion_1 {
    padding-top: 38.7px;
  }
}

@media (min-width: 768px) {
  .contact .seccion_1 {
    padding-top: 72px;
  }
}

@media (min-width: 992px) {
  .contact .seccion_1 {
    padding-top: 109.164px;
  }
}

@media (min-width: 1200px) {
  .contact .seccion_1 {
    padding-top: 132px;
  }
}

@media (min-width: 1920px) {
  .contact .seccion_1 {
    padding-top: 211.2px;
  }
}

@media (min-width: 2400px) {
  .contact .seccion_1 {
    padding-top: 264px;
  }
}

.contact .seccion_1 .linea {
  height: 2px;
  width: 10px;
  margin-left: 8px;
}

@media (min-width: 414px) {
  .contact .seccion_1 .linea {
    margin-left: 10.32px;
  }
}

@media (min-width: 768px) {
  .contact .seccion_1 .linea {
    margin-left: 19.2px;
  }
}

@media (min-width: 992px) {
  .contact .seccion_1 .linea {
    margin-left: 0;
  }
}

@media (min-width: 414px) {
  .contact .seccion_1 .linea {
    height: 2.58px;
    width: 12.9px;
  }
}

@media (min-width: 768px) {
  .contact .seccion_1 .linea {
    height: 4.8px;
    width: 24px;
  }
}

@media (min-width: 992px) {
  .contact .seccion_1 .linea {
    height: 8.27px;
    width: 24.81px;
  }
}

@media (min-width: 1200px) {
  .contact .seccion_1 .linea {
    height: 10px;
    width: 30px;
  }
}

@media (min-width: 1920px) {
  .contact .seccion_1 .linea {
    height: 16px;
    width: 48px;
  }
}

@media (min-width: 2400px) {
  .contact .seccion_1 .linea {
    height: 20px;
    width: 60px;
  }
}

.contact .seccion_1 .titulo {
  font-family: "Gotham-Medium";
  color: #3f3f3d;
  line-height: 1.2;
  margin-bottom: 16px;
  font-size: 20px;
  margin-left: 8px;
  margin-top: 5px;
}

@media (min-width: 414px) {
  .contact .seccion_1 .titulo {
    font-size: 25.8px;
    margin-left: 10.32px;
    margin-top: 6.45px;
    margin-bottom: 20.64px;
  }
}

@media (min-width: 768px) {
  .contact .seccion_1 .titulo {
    font-size: 48px;
    margin-left: 19.2px;
    margin-top: 12px;
    margin-bottom: 38.4px;
  }
}

@media (min-width: 992px) {
  .contact .seccion_1 .titulo {
    margin-left: 0;
    margin-top: 0;
  }
}

@media (min-width: 992px) {
  .contact .seccion_1 .titulo {
    font-size: 49.62px;
    margin-bottom: 49.62px;
  }
}

@media (min-width: 1200px) {
  .contact .seccion_1 .titulo {
    font-size: 60px;
    margin-bottom: 60px;
  }
}

@media (min-width: 1920px) {
  .contact .seccion_1 .titulo {
    font-size: 96px;
    margin-bottom: 96px;
  }
}

@media (min-width: 2400px) {
  .contact .seccion_1 .titulo {
    font-size: 120px;
    margin-bottom: 120px;
  }
}

.contact .seccion_1 .titulo span {
  font-family: "Gotham-Black";
}

.contact .seccion_1 .descripcion {
  font-family: "Gotham-Book";
  color: #3c3d38;
  line-height: 1.2;
  margin: 0 auto 17px;
  text-align: justify;
  font-size: 9px;
  width: 242px;
}

@media (min-width: 414px) {
  .contact .seccion_1 .descripcion {
    font-size: 11.61px;
    width: 312.18px;
    margin: 0 auto 21.93px;
  }
}

@media (min-width: 768px) {
  .contact .seccion_1 .descripcion {
    font-size: 21.6px;
    width: 580.8px;
    margin: 0 auto 40.8px;
  }
}

@media (min-width: 992px) {
  .contact .seccion_1 .descripcion {
    text-align: left;
    letter-spacing: -0.016em;
    font-size: 15.713px;
    width: 421.77px;
    margin-bottom: 24.81px;
    margin-left: initial;
    margin-right: initial;
  }
}

@media (min-width: 1200px) {
  .contact .seccion_1 .descripcion {
    font-size: 19px;
    width: 510px;
    margin-bottom: 30px;
  }
}

@media (min-width: 1920px) {
  .contact .seccion_1 .descripcion {
    font-size: 30.4px;
    width: 816px;
    margin-bottom: 48px;
  }
}

@media (min-width: 2400px) {
  .contact .seccion_1 .descripcion {
    font-size: 38px;
    width: 1020px;
    margin-bottom: 60px;
  }
}

.contact .seccion_1 .subtitulo {
  color: #222220;
  font-family: "Gotham-Medium";
  line-height: 1.2;
  font-size: 15px;
  text-align: center;
}

@media (min-width: 414px) {
  .contact .seccion_1 .subtitulo {
    font-size: 19.35px;
  }
}

@media (min-width: 768px) {
  .contact .seccion_1 .subtitulo {
    font-size: 36px;
  }
}

@media (min-width: 992px) {
  .contact .seccion_1 .subtitulo {
    text-align: left;
    font-size: 31.426px;
    font-family: "Gotham-Bold";
  }
}

@media (min-width: 1200px) {
  .contact .seccion_1 .subtitulo {
    font-size: 38px;
  }
}

@media (min-width: 1920px) {
  .contact .seccion_1 .subtitulo {
    font-size: 60.8px;
  }
}

@media (min-width: 2400px) {
  .contact .seccion_1 .subtitulo {
    font-size: 76px;
  }
}

.contact .seccion_1 .columna-derecha {
  margin-top: 24px;
  margin-left: 0px;
}

@media (min-width: 414px) {
  .contact .seccion_1 .columna-derecha {
    margin-top: 30.96px;
  }
}

@media (min-width: 768px) {
  .contact .seccion_1 .columna-derecha {
    margin-top: 57.6px;
  }
}

@media (min-width: 992px) {
  .contact .seccion_1 .columna-derecha {
    margin-top: 23.156px;
    margin-left: 41.35px;
  }
}

@media (min-width: 1200px) {
  .contact .seccion_1 .columna-derecha {
    margin-top: 28px;
    margin-left: 50px;
  }
}

@media (min-width: 1920px) {
  .contact .seccion_1 .columna-derecha {
    margin-top: 44.8px;
    margin-left: 80px;
  }
}

@media (min-width: 2400px) {
  .contact .seccion_1 .columna-derecha {
    margin-top: 56px;
    margin-left: 100px;
  }
}

.contact .seccion_1 .columna-derecha .contenedor_pasos {
  height: 150px;
  margin-bottom: 20px;
}

@media (min-width: 414px) {
  .contact .seccion_1 .columna-derecha .contenedor_pasos {
    height: 193.5px;
    margin-bottom: 25.8px;
  }
}

@media (min-width: 768px) {
  .contact .seccion_1 .columna-derecha .contenedor_pasos {
    height: 360px;
    margin-bottom: 48px;
  }
}

@media (min-width: 992px) {
  .contact .seccion_1 .columna-derecha .contenedor_pasos {
    height: 190.21px;
    margin-bottom: 41.35px;
  }
}

@media (min-width: 1200px) {
  .contact .seccion_1 .columna-derecha .contenedor_pasos {
    height: 230px;
    margin-bottom: 50px;
  }
}

@media (min-width: 1920px) {
  .contact .seccion_1 .columna-derecha .contenedor_pasos {
    height: 368px;
    margin-bottom: 80px;
  }
}

@media (min-width: 2400px) {
  .contact .seccion_1 .columna-derecha .contenedor_pasos {
    height: 460px;
    margin-bottom: 100px;
  }
}

.contact .seccion_1 .columna-derecha .contenedor_pasos .paso_1 .opciones .opcion {
  display: flex;
  align-items: center;
  font-family: "Gotham-Book";
  color: #9e9c9b;
  background: #fff;
  background-clip: padding-box;
  cursor: pointer;
  box-sizing: border-box;
  position: relative;
  width: 230px;
  height: 30px;
  margin-bottom: 10px;
  padding-left: 10px;
  border-radius: 30px;
  font-size: 10px;
  border: solid 1px transparent;
  /* !importanté */
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 414px) {
  .contact .seccion_1 .columna-derecha .contenedor_pasos .paso_1 .opciones .opcion {
    width: 296.7px;
    height: 38.7px;
    font-size: 12.9px;
    border-radius: 38.7px;
    padding-left: 12.9px;
  }
}

@media (min-width: 768px) {
  .contact .seccion_1 .columna-derecha .contenedor_pasos .paso_1 .opciones .opcion {
    width: 552px;
    height: 72px;
    font-size: 24px;
    border-radius: 72px;
    padding-left: 24px;
  }
}

@media (min-width: 992px) {
  .contact .seccion_1 .columna-derecha .contenedor_pasos .paso_1 .opciones .opcion {
    font-size: 12.405px;
    width: 287.796px;
    height: 31.426px;
    margin-bottom: 21.502px;
    padding-left: 13.232px;
    border-radius: 31.426px;
    margin-left: initial;
    margin-right: initial;
  }
}

@media (min-width: 1200px) {
  .contact .seccion_1 .columna-derecha .contenedor_pasos .paso_1 .opciones .opcion {
    font-size: 15px;
    width: 348px;
    height: 38px;
    margin-bottom: 26px;
    padding-left: 16px;
    border-radius: 38px;
  }
}

@media (min-width: 1920px) {
  .contact .seccion_1 .columna-derecha .contenedor_pasos .paso_1 .opciones .opcion {
    font-size: 24px;
    width: 557px;
    height: 61px;
    margin-bottom: 42px;
    padding-left: 26px;
    border-radius: 61px;
    border: solid 2px transparent;
    /* !importanté */
  }
}

@media (min-width: 2400px) {
  .contact .seccion_1 .columna-derecha .contenedor_pasos .paso_1 .opciones .opcion {
    font-size: 30px;
    width: 696px;
    height: 76px;
    margin-bottom: 52px;
    padding-left: 32px;
    border-radius: 76px;
  }
}

.contact .seccion_1 .columna-derecha .contenedor_pasos .paso_1 .opciones .opcion:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  margin: -2px;
  /* !importanté */
  border-radius: inherit;
  /* !importanté */
  background: #6aaddf;
  background: -moz-linear-gradient(90deg, #6aaddf 0%, #9e1b7e 100%);
  background: -webkit-linear-gradient(90deg, #6aaddf 0%, #9e1b7e 100%);
  background: linear-gradient(90deg, #6aaddf 0%, #9e1b7e 100%);
  margin: -1px;
  /* !importanté */
}

@media (min-width: 992px) {
  .contact .seccion_1 .columna-derecha .contenedor_pasos .paso_1 .opciones .opcion:before {
    margin: -1px;
    /* !importanté */
  }
}

@media (min-width: 1200px) {
  .contact .seccion_1 .columna-derecha .contenedor_pasos .paso_1 .opciones .opcion:before {
    margin: -1px;
    /* !importanté */
  }
}

@media (min-width: 1920px) {
  .contact .seccion_1 .columna-derecha .contenedor_pasos .paso_1 .opciones .opcion:before {
    margin: -2px;
    /* !importanté */
  }
}

@media (min-width: 2400px) {
  .contact .seccion_1 .columna-derecha .contenedor_pasos .paso_1 .opciones .opcion:before {
    margin: -2px;
    /* !importanté */
  }
}

.contact .seccion_1 .columna-derecha .contenedor_pasos .paso_1 .opciones .opcion .circulo {
  position: absolute;
  z-index: 1;
  top: 0;
  bottom: 0;
  margin: auto;
  background-image: url(../img/contact/circulo.svg);
  background-size: 100% auto;
  background-repeat: no-repeat;
  width: 12px;
  height: 12px;
  right: 10px;
}

@media (min-width: 414px) {
  .contact .seccion_1 .columna-derecha .contenedor_pasos .paso_1 .opciones .opcion .circulo {
    width: 15.48px;
    height: 15.48px;
    right: 12.9px;
  }
}

@media (min-width: 768px) {
  .contact .seccion_1 .columna-derecha .contenedor_pasos .paso_1 .opciones .opcion .circulo {
    width: 28.8px;
    height: 28.8px;
    right: 24px;
  }
}

@media (min-width: 992px) {
  .contact .seccion_1 .columna-derecha .contenedor_pasos .paso_1 .opciones .opcion .circulo {
    width: 12px;
    height: 12px;
    right: 12.405px;
  }
}

@media (min-width: 1200px) {
  .contact .seccion_1 .columna-derecha .contenedor_pasos .paso_1 .opciones .opcion .circulo {
    width: 14px;
    height: 14px;
    right: 15px;
  }
}

@media (min-width: 1920px) {
  .contact .seccion_1 .columna-derecha .contenedor_pasos .paso_1 .opciones .opcion .circulo {
    width: 22px;
    height: 22px;
    right: 24px;
  }
}

@media (min-width: 2400px) {
  .contact .seccion_1 .columna-derecha .contenedor_pasos .paso_1 .opciones .opcion .circulo {
    width: 28px;
    height: 28px;
    right: 30px;
  }
}

.contact .seccion_1 .columna-derecha .contenedor_pasos .paso_1 .opciones .opcion.active {
  font-family: "Gotham-Bold";
  color: #fff;
  background-color: transparent;
}

.contact .seccion_1 .columna-derecha .contenedor_pasos .paso_1 .opciones .opcion.active .circulo {
  background-image: url(../img/contact/circulo_active.svg);
}

@media (hover: hover) {
  .contact .seccion_1 .columna-derecha .contenedor_pasos .paso_1 .opciones .opcion:hover {
    font-family: "Gotham-Bold";
    color: #fff;
    background-color: transparent;
  }
  .contact .seccion_1 .columna-derecha .contenedor_pasos .paso_1 .opciones .opcion:hover .circulo {
    background-image: url(../img/contact/circulo_active.svg);
  }
}

.contact .seccion_1 .columna-derecha .contenedor_pasos .paso_2 .contenedor_input {
  display: flex;
  align-items: center;
  position: relative;
  box-sizing: border-box;
  color: #fff;
  background: #fff;
  background-clip: padding-box;
  /* !importanté */
  width: 230px;
  height: 30px;
  margin-bottom: 10px;
  border-radius: 30px;
  border: solid 1px transparent;
  /* !importanté */
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 414px) {
  .contact .seccion_1 .columna-derecha .contenedor_pasos .paso_2 .contenedor_input {
    width: 296.7px;
    height: 38.7px;
    font-size: 12.9px;
    border-radius: 38.7px;
  }
}

@media (min-width: 768px) {
  .contact .seccion_1 .columna-derecha .contenedor_pasos .paso_2 .contenedor_input {
    width: 552px;
    height: 72px;
    font-size: 24px;
    border-radius: 72px;
  }
}

@media (min-width: 992px) {
  .contact .seccion_1 .columna-derecha .contenedor_pasos .paso_2 .contenedor_input {
    height: 31.426px;
    width: 248.1px;
    margin-bottom: 21.502px;
    border-radius: 28.945px;
    border: solid 1px transparent;
    /* !importanté */
    margin-left: initial;
    margin-right: initial;
  }
}

@media (min-width: 1200px) {
  .contact .seccion_1 .columna-derecha .contenedor_pasos .paso_2 .contenedor_input {
    height: 36px;
    width: 298px;
    margin-bottom: 26px;
    border-radius: 35px;
    border: solid 1px transparent;
    /* !importanté */
  }
}

@media (min-width: 1920px) {
  .contact .seccion_1 .columna-derecha .contenedor_pasos .paso_2 .contenedor_input {
    height: 61px;
    width: 479px;
    margin-bottom: 42px;
    border-radius: 56px;
    border: solid 2px transparent;
    /* !importanté */
  }
}

@media (min-width: 2400px) {
  .contact .seccion_1 .columna-derecha .contenedor_pasos .paso_2 .contenedor_input {
    height: 76px;
    width: 600px;
    margin-bottom: 52px;
    border-radius: 70px;
    border: solid 2px transparent;
    /* !importanté */
  }
}

.contact .seccion_1 .columna-derecha .contenedor_pasos .paso_2 .contenedor_input:before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  margin: -1px;
  /* !importanté */
  border-radius: inherit;
  /* !importanté */
  background: linear-gradient(90deg, #6aaddf 0%, #9e1b7e 100%);
}

@media (min-width: 992px) {
  .contact .seccion_1 .columna-derecha .contenedor_pasos .paso_2 .contenedor_input:before {
    margin: -1px;
    /* !importanté */
  }
}

@media (min-width: 1200px) {
  .contact .seccion_1 .columna-derecha .contenedor_pasos .paso_2 .contenedor_input:before {
    margin: -1px;
    /* !importanté */
  }
}

@media (min-width: 1920px) {
  .contact .seccion_1 .columna-derecha .contenedor_pasos .paso_2 .contenedor_input:before {
    margin: -2px;
    /* !importanté */
  }
}

@media (min-width: 2400px) {
  .contact .seccion_1 .columna-derecha .contenedor_pasos .paso_2 .contenedor_input:before {
    margin: -2px;
    /* !importanté */
  }
}

.contact .seccion_1 .columna-derecha .contenedor_pasos .paso_2 input {
  background-color: transparent;
  border: none;
  position: absolute;
  top: 0;
  left: 0;
  color: #3c3d38;
  line-height: 1.2;
  font-family: "Gotham-Book";
  padding-left: 15px;
  padding-right: 15px;
  font-size: 10px;
  height: 36px;
  width: 298px;
  border-radius: 35px;
  width: 100%;
  height: 100%;
  text-align: center;
}

@media (min-width: 414px) {
  .contact .seccion_1 .columna-derecha .contenedor_pasos .paso_2 input {
    font-size: 12.9px;
  }
}

@media (min-width: 768px) {
  .contact .seccion_1 .columna-derecha .contenedor_pasos .paso_2 input {
    font-size: 24px;
  }
}

@media (min-width: 992px) {
  .contact .seccion_1 .columna-derecha .contenedor_pasos .paso_2 input {
    padding-left: 12.405px;
    padding-right: 12.405px;
    font-size: 12.405px;
    height: 29.772px;
    width: 246.446px;
    border-radius: 28.945px;
    text-align: left;
  }
}

@media (min-width: 1200px) {
  .contact .seccion_1 .columna-derecha .contenedor_pasos .paso_2 input {
    padding-left: 15px;
    padding-right: 15px;
    font-size: 15px;
    height: 36px;
    width: 298px;
    border-radius: 35px;
  }
}

@media (min-width: 1920px) {
  .contact .seccion_1 .columna-derecha .contenedor_pasos .paso_2 input {
    padding-left: 24px;
    padding-right: 24px;
    font-size: 24px;
    height: 57px;
    width: 477px;
    border-radius: 56px;
  }
}

@media (min-width: 2400px) {
  .contact .seccion_1 .columna-derecha .contenedor_pasos .paso_2 input {
    padding-left: 30px;
    padding-right: 30px;
    font-size: 30px;
    height: 72px;
    width: 596px;
    border-radius: 70px;
  }
}

.contact .seccion_1 .columna-derecha .contenedor_pasos .paso_2 input:focus-visible {
  outline: none;
}

.contact .seccion_1 .columna-derecha .contenedor_pasos .paso_2 .contenedor_textarea {
  display: flex;
  align-items: center;
  position: relative;
  box-sizing: border-box;
  color: #fff;
  background: #fff;
  background-clip: padding-box;
  /* !importanté */
  height: 70px;
  width: 230px;
  border-radius: 15px;
  border: solid 1px transparent;
  /* !importanté */
  margin: auto;
}

@media (min-width: 414px) {
  .contact .seccion_1 .columna-derecha .contenedor_pasos .paso_2 .contenedor_textarea {
    width: 296.7px;
    height: 90.3px;
    border-radius: 19.35px;
  }
}

@media (min-width: 768px) {
  .contact .seccion_1 .columna-derecha .contenedor_pasos .paso_2 .contenedor_textarea {
    width: 552px;
    height: 168px;
    border-radius: 36px;
  }
}

@media (min-width: 992px) {
  .contact .seccion_1 .columna-derecha .contenedor_pasos .paso_2 .contenedor_textarea {
    height: 84.354px;
    width: 248.1px;
    border-radius: 14.886px;
    border: solid 1px transparent;
    /* !importanté */
    margin-left: initial;
    margin-right: initial;
  }
}

@media (min-width: 1200px) {
  .contact .seccion_1 .columna-derecha .contenedor_pasos .paso_2 .contenedor_textarea {
    height: 102px;
    width: 298px;
    border-radius: 18px;
    border: solid 1px transparent;
    /* !importanté */
  }
}

@media (min-width: 1920px) {
  .contact .seccion_1 .columna-derecha .contenedor_pasos .paso_2 .contenedor_textarea {
    height: 163px;
    width: 480px;
    border-radius: 29px;
    border: solid 2px transparent;
    /* !importanté */
  }
}

@media (min-width: 2400px) {
  .contact .seccion_1 .columna-derecha .contenedor_pasos .paso_2 .contenedor_textarea {
    height: 204px;
    width: 600px;
    border-radius: 36px;
    border: solid 2px transparent;
    /* !importanté */
  }
}

.contact .seccion_1 .columna-derecha .contenedor_pasos .paso_2 .contenedor_textarea:before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  margin: -1px;
  /* !importanté */
  border-radius: inherit;
  /* !importanté */
  background: linear-gradient(90deg, #6aaddf 0%, #9e1b7e 100%);
}

@media (min-width: 992px) {
  .contact .seccion_1 .columna-derecha .contenedor_pasos .paso_2 .contenedor_textarea:before {
    margin: -1px;
    /* !importanté */
  }
}

@media (min-width: 1200px) {
  .contact .seccion_1 .columna-derecha .contenedor_pasos .paso_2 .contenedor_textarea:before {
    margin: -1px;
    /* !importanté */
  }
}

@media (min-width: 1920px) {
  .contact .seccion_1 .columna-derecha .contenedor_pasos .paso_2 .contenedor_textarea:before {
    margin: -2px;
    /* !importanté */
  }
}

@media (min-width: 2400px) {
  .contact .seccion_1 .columna-derecha .contenedor_pasos .paso_2 .contenedor_textarea:before {
    margin: -2px;
    /* !importanté */
  }
}

.contact .seccion_1 .columna-derecha .contenedor_pasos .paso_2 textarea {
  background-color: transparent;
  border: none;
  position: absolute;
  top: 0;
  left: 0;
  color: #3c3d38;
  font-family: "Gotham-Book";
  line-height: 1.2;
  padding-left: 15px;
  padding-right: 15px;
  width: 100%;
  height: 100%;
  border-radius: 15px;
  font-size: 10px;
  resize: none;
  text-align: center;
}

@media (min-width: 414px) {
  .contact .seccion_1 .columna-derecha .contenedor_pasos .paso_2 textarea {
    font-size: 12.9px;
    border-radius: 19.35px;
  }
}

@media (min-width: 768px) {
  .contact .seccion_1 .columna-derecha .contenedor_pasos .paso_2 textarea {
    font-size: 24px;
    border-radius: 36px;
  }
}

@media (min-width: 992px) {
  .contact .seccion_1 .columna-derecha .contenedor_pasos .paso_2 textarea {
    padding-left: 12.405px;
    padding-right: 12.405px;
    margin-top: 8.27px;
    margin-bottom: 8.27px;
    height: 66.16px;
    width: 246.446px;
    border-radius: 28.945px;
    font-size: 12.405px;
    text-align: left;
  }
}

@media (min-width: 1200px) {
  .contact .seccion_1 .columna-derecha .contenedor_pasos .paso_2 textarea {
    padding-left: 15px;
    padding-right: 15px;
    margin-top: 10px;
    margin-bottom: 10px;
    height: 80px;
    width: 298px;
    border-radius: 35px;
    font-size: 15px;
  }
}

@media (min-width: 1920px) {
  .contact .seccion_1 .columna-derecha .contenedor_pasos .paso_2 textarea {
    padding-left: 24px;
    padding-right: 24px;
    margin-top: 16px;
    margin-bottom: 16px;
    height: 128px;
    width: 476.8px;
    border-radius: 56px;
    font-size: 24px;
  }
}

@media (min-width: 2400px) {
  .contact .seccion_1 .columna-derecha .contenedor_pasos .paso_2 textarea {
    padding-left: 30px;
    padding-right: 30px;
    margin-top: 20px;
    margin-bottom: 20px;
    height: 160px;
    width: 596px;
    border-radius: 70px;
    font-size: 30px;
  }
}

.contact .seccion_1 .columna-derecha .contenedor_pasos .paso_2 textarea:focus-visible {
  outline: none;
}

.contact .seccion_1 .columna-derecha .contenedor_boton {
  display: flex;
  align-items: center;
  flex-direction: column-reverse;
}

@media (min-width: 992px) {
  .contact .seccion_1 .columna-derecha .contenedor_boton {
    flex-direction: row;
  }
}

.contact .seccion_1 .columna-derecha .contenedor_boton #btn-next {
  display: flex !important;
  align-items: center;
  margin: auto;
  position: relative;
  box-sizing: border-box;
  color: #3f3f3d;
  background: #fff;
  background-clip: padding-box;
  text-decoration: none;
  justify-content: center;
  font-family: "Gotham-Medium";
  width: 100px;
  height: 30px;
  border-radius: 30px;
  font-size: 10px;
  border: solid 1px transparent;
  /* !importanté */
}

@media (min-width: 414px) {
  .contact .seccion_1 .columna-derecha .contenedor_boton #btn-next {
    width: 129px;
    height: 38.7px;
    font-size: 12.9px;
    border-radius: 38.7px;
  }
}

@media (min-width: 768px) {
  .contact .seccion_1 .columna-derecha .contenedor_boton #btn-next {
    width: 240px;
    height: 72px;
    font-size: 24px;
    border-radius: 72px;
  }
}

@media (min-width: 992px) {
  .contact .seccion_1 .columna-derecha .contenedor_boton #btn-next {
    width: 79px;
    height: 32px;
    font-size: 13px;
    margin-left: 17px;
    margin-right: initial;
    border-radius: 32px;
  }
}

@media (min-width: 1200px) {
  .contact .seccion_1 .columna-derecha .contenedor_boton #btn-next {
    width: 96px;
    height: 39px;
    font-size: 16px;
    margin-left: 21px;
    border-radius: 39px;
  }
}

@media (min-width: 1920px) {
  .contact .seccion_1 .columna-derecha .contenedor_boton #btn-next {
    width: 153px;
    height: 62px;
    font-size: 25px;
    margin-left: 33px;
    border-radius: 62px;
    border: solid 2px transparent;
    /* !importanté */
  }
}

@media (min-width: 2400px) {
  .contact .seccion_1 .columna-derecha .contenedor_boton #btn-next {
    width: 192px;
    height: 78px;
    font-size: 32px;
    margin-left: 42px;
    border-radius: 78px;
  }
}

.contact .seccion_1 .columna-derecha .contenedor_boton #btn-next:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  margin: -2px;
  /* !importanté */
  border-radius: inherit;
  /* !importanté */
  background: #6aaddf;
  background: -moz-linear-gradient(90deg, #6aaddf 0%, #9e1b7e 100%);
  background: -webkit-linear-gradient(90deg, #6aaddf 0%, #9e1b7e 100%);
  background: linear-gradient(90deg, #6aaddf 0%, #9e1b7e 100%);
  margin: -1px;
  /* !importanté */
}

@media (min-width: 992px) {
  .contact .seccion_1 .columna-derecha .contenedor_boton #btn-next:before {
    margin: -1px;
    /* !importanté */
  }
}

@media (min-width: 1200px) {
  .contact .seccion_1 .columna-derecha .contenedor_boton #btn-next:before {
    margin: -1px;
    /* !importanté */
  }
}

@media (min-width: 1920px) {
  .contact .seccion_1 .columna-derecha .contenedor_boton #btn-next:before {
    margin: -2px;
    /* !importanté */
  }
}

@media (min-width: 2400px) {
  .contact .seccion_1 .columna-derecha .contenedor_boton #btn-next:before {
    margin: -2px;
    /* !importanté */
  }
}

.contact .seccion_1 .columna-derecha .contenedor_boton #btn-next.send {
  width: 230px;
}

@media (min-width: 414px) {
  .contact .seccion_1 .columna-derecha .contenedor_boton #btn-next.send {
    width: 296.7px;
  }
}

@media (min-width: 768px) {
  .contact .seccion_1 .columna-derecha .contenedor_boton #btn-next.send {
    width: 552px;
  }
}

@media (min-width: 992px) {
  .contact .seccion_1 .columna-derecha .contenedor_boton #btn-next.send {
    width: 79px;
  }
}

@media (min-width: 1200px) {
  .contact .seccion_1 .columna-derecha .contenedor_boton #btn-next.send {
    width: 96px;
  }
}

@media (min-width: 1920px) {
  .contact .seccion_1 .columna-derecha .contenedor_boton #btn-next.send {
    width: 153px;
  }
}

@media (min-width: 2400px) {
  .contact .seccion_1 .columna-derecha .contenedor_boton #btn-next.send {
    width: 192px;
  }
}

.contact .seccion_1 .columna-derecha .contenedor_boton #btn-next .texto {
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  font-family: "Gotham-Medium";
  color: #3f3f3d;
  font-size: 10px;
}

@media (min-width: 414px) {
  .contact .seccion_1 .columna-derecha .contenedor_boton #btn-next .texto {
    font-size: 12.9px;
  }
}

@media (min-width: 768px) {
  .contact .seccion_1 .columna-derecha .contenedor_boton #btn-next .texto {
    font-size: 24px;
  }
}

@media (min-width: 992.4px) {
  .contact .seccion_1 .columna-derecha .contenedor_boton #btn-next .texto {
    font-size: 13.232px;
  }
}

@media (min-width: 1200px) {
  .contact .seccion_1 .columna-derecha .contenedor_boton #btn-next .texto {
    font-size: 16px;
  }
}

@media (min-width: 1920px) {
  .contact .seccion_1 .columna-derecha .contenedor_boton #btn-next .texto {
    font-size: 25.6px;
  }
}

@media (min-width: 2400px) {
  .contact .seccion_1 .columna-derecha .contenedor_boton #btn-next .texto {
    font-size: 32px;
  }
}

.contact .seccion_1 .columna-derecha .contenedor_boton #btn-next .texto img {
  margin-left: 10px;
  height: 10px;
  width: auto;
}

@media (min-width: 414px) {
  .contact .seccion_1 .columna-derecha .contenedor_boton #btn-next .texto img {
    height: 12.9px;
    margin-left: 12.9px;
  }
}

@media (min-width: 768px) {
  .contact .seccion_1 .columna-derecha .contenedor_boton #btn-next .texto img {
    height: 24px;
    margin-left: 24px;
  }
}

@media (min-width: 992px) {
  .contact .seccion_1 .columna-derecha .contenedor_boton #btn-next .texto img {
    margin-left: 12.405px;
    height: 10.751px;
  }
}

@media (min-width: 1200px) {
  .contact .seccion_1 .columna-derecha .contenedor_boton #btn-next .texto img {
    margin-left: 15px;
    height: 13px;
  }
}

@media (min-width: 1920px) {
  .contact .seccion_1 .columna-derecha .contenedor_boton #btn-next .texto img {
    margin-left: 24px;
    height: 20.8px;
  }
}

@media (min-width: 2400px) {
  .contact .seccion_1 .columna-derecha .contenedor_boton #btn-next .texto img {
    margin-left: 30px;
    height: 26px;
  }
}

.contact .seccion_1 .columna-derecha .contenedor_boton .linea_2 {
  position: relative;
  width: 230px;
  height: 1px;
}

@media (min-width: 414px) {
  .contact .seccion_1 .columna-derecha .contenedor_boton .linea_2 {
    width: 296.7px;
  }
}

@media (min-width: 768px) {
  .contact .seccion_1 .columna-derecha .contenedor_boton .linea_2 {
    width: 552px;
  }
}

@media (min-width: 992px) {
  .contact .seccion_1 .columna-derecha .contenedor_boton .linea_2 {
    background-color: #3f3f3d;
    display: block;
    width: 229.906px;
    height: 1px;
  }
}

@media (min-width: 1200px) {
  .contact .seccion_1 .columna-derecha .contenedor_boton .linea_2 {
    width: 278px;
    height: 1px;
  }
}

@media (min-width: 1920px) {
  .contact .seccion_1 .columna-derecha .contenedor_boton .linea_2 {
    width: 444.8px;
    height: 1.6px;
  }
}

@media (min-width: 2400px) {
  .contact .seccion_1 .columna-derecha .contenedor_boton .linea_2 {
    width: 556px;
    height: 2px;
  }
}

.contact .seccion_1 .columna-derecha .contenedor_boton .linea_2 .mensaje {
  position: absolute;
  width: 100%;
  font-size: 10px;
  top: 4px;
  text-align: center;
  font-family: "Gotham-Book";
  color: #3c3d38;
}

@media (min-width: 414px) {
  .contact .seccion_1 .columna-derecha .contenedor_boton .linea_2 .mensaje {
    font-size: 12.9px;
  }
}

@media (min-width: 768px) {
  .contact .seccion_1 .columna-derecha .contenedor_boton .linea_2 .mensaje {
    font-size: 24px;
  }
}

@media (min-width: 992px) {
  .contact .seccion_1 .columna-derecha .contenedor_boton .linea_2 .mensaje {
    text-align: right;
    font-size: 11.1645px;
  }
}

@media (min-width: 1200px) {
  .contact .seccion_1 .columna-derecha .contenedor_boton .linea_2 .mensaje {
    font-size: 13.5px;
  }
}

@media (min-width: 1920px) {
  .contact .seccion_1 .columna-derecha .contenedor_boton .linea_2 .mensaje {
    font-size: 21.6px;
    top: 6.4px;
  }
}

@media (min-width: 2400px) {
  .contact .seccion_1 .columna-derecha .contenedor_boton .linea_2 .mensaje {
    font-size: 27px;
    top: 8px;
  }
}

.work .esquina_derecha {
  background: none;
}

.work .seccion_0 .breadcrumb {
  line-height: 1;
  position: relative;
  display: block;
  display: inline-block;
  margin-bottom: 0px;
  padding-bottom: 9px;
  margin-top: 14px;
  margin-left: 8px;
}

@media (min-width: 414px) {
  .work .seccion_0 .breadcrumb {
    padding-bottom: 11.61px;
    margin-top: 18.06px;
    margin-left: 10.32px;
  }
}

@media (min-width: 768px) {
  .work .seccion_0 .breadcrumb {
    padding-bottom: 21.6px;
    margin-top: 33.6px;
    margin-left: 19.2px;
  }
}

@media (min-width: 992px) {
  .work .seccion_0 .breadcrumb {
    margin-left: 0px;
    padding-bottom: 11.578px;
    margin-top: 11.578px;
  }
}

@media (min-width: 1200px) {
  .work .seccion_0 .breadcrumb {
    padding-bottom: 14px;
    margin-top: 14px;
  }
}

@media (min-width: 1920px) {
  .work .seccion_0 .breadcrumb {
    padding-bottom: 22.4px;
    margin-top: 22.4px;
  }
}

@media (min-width: 2400px) {
  .work .seccion_0 .breadcrumb {
    padding-bottom: 28px;
    margin-top: 28px;
  }
}

.work .seccion_0 .breadcrumb a {
  font-family: "Gotham-Book";
  font-size: 9px;
  color: #3c3d38;
  text-decoration: none;
}

@media (min-width: 414px) {
  .work .seccion_0 .breadcrumb a {
    font-size: 11.61px;
  }
}

@media (min-width: 768px) {
  .work .seccion_0 .breadcrumb a {
    font-size: 21.6px;
  }
}

@media (min-width: 992px) {
  .work .seccion_0 .breadcrumb a {
    font-size: 7.443px;
  }
}

@media (min-width: 1200px) {
  .work .seccion_0 .breadcrumb a {
    font-size: 9px;
  }
}

@media (min-width: 1920px) {
  .work .seccion_0 .breadcrumb a {
    font-size: 14.4px;
  }
}

@media (min-width: 2400px) {
  .work .seccion_0 .breadcrumb a {
    font-size: 18px;
  }
}

.work .seccion_0 .breadcrumb a.active {
  font-family: "Gotham-Bold";
  margin-right: 0px;
}

@media (min-width: 992px) {
  .work .seccion_0 .breadcrumb a.active {
    margin-right: 14.886px;
  }
}

@media (min-width: 1200px) {
  .work .seccion_0 .breadcrumb a.active {
    margin-right: 18px;
  }
}

@media (min-width: 1920px) {
  .work .seccion_0 .breadcrumb a.active {
    margin-right: 28.8px;
  }
}

@media (min-width: 2400px) {
  .work .seccion_0 .breadcrumb a.active {
    margin-right: 36px;
  }
}

.work .seccion_0 .breadcrumb span {
  color: #000000;
  font-family: "Gotham-Book";
  font-size: 9px;
  margin-left: 3px;
  margin-right: 0px;
}

@media (min-width: 414px) {
  .work .seccion_0 .breadcrumb span {
    font-size: 11.61px;
    margin-left: 3.87px;
  }
}

@media (min-width: 768px) {
  .work .seccion_0 .breadcrumb span {
    font-size: 21.6px;
    margin-left: 7.2px;
  }
}

@media (min-width: 992px) {
  .work .seccion_0 .breadcrumb span {
    font-size: 7.443px;
    margin-left: 5.789px;
    margin-right: 6.616px;
  }
}

@media (min-width: 1200px) {
  .work .seccion_0 .breadcrumb span {
    font-size: 9px;
    margin-left: 7px;
    margin-right: 8px;
  }
}

@media (min-width: 1920px) {
  .work .seccion_0 .breadcrumb span {
    font-size: 14.4px;
    margin-left: 11.2px;
    margin-right: 12.8px;
  }
}

@media (min-width: 2400px) {
  .work .seccion_0 .breadcrumb span {
    font-size: 18px;
    margin-left: 14px;
    margin-right: 16px;
  }
}

.work .seccion_0 .breadcrumb::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 1px;
  background: #000;
  bottom: 0;
  left: 0;
}

.work .seccion_1 {
  position: relative;
  padding-bottom: 72px;
  padding-top: 30px;
}

@media (min-width: 414px) {
  .work .seccion_1 {
    padding-bottom: 92.88px;
  }
}

@media (min-width: 768px) {
  .work .seccion_1 {
    padding-bottom: 172.8px;
  }
}

@media (min-width: 992px) {
  .work .seccion_1 {
    padding-bottom: 0px;
  }
}

.work .seccion_1 .container {
  position: relative;
}

@media (min-width: 414px) {
  .work .seccion_1 {
    padding-top: 38.7px;
  }
}

@media (min-width: 768px) {
  .work .seccion_1 {
    padding-top: 72px;
  }
}

@media (min-width: 992px) {
  .work .seccion_1 {
    padding-top: 77.738px;
  }
}

@media (min-width: 1200px) {
  .work .seccion_1 {
    padding-top: 94px;
  }
}

@media (min-width: 1920px) {
  .work .seccion_1 {
    padding-top: 150.4px;
  }
}

@media (min-width: 2400px) {
  .work .seccion_1 {
    padding-top: 188px;
  }
}

.work .seccion_1 .linea {
  height: 2px;
  width: 10px;
  margin-left: 8px;
}

@media (min-width: 414px) {
  .work .seccion_1 .linea {
    margin-left: 10.32px;
  }
}

@media (min-width: 768px) {
  .work .seccion_1 .linea {
    margin-left: 19.2px;
  }
}

@media (min-width: 992px) {
  .work .seccion_1 .linea {
    margin-left: 0;
  }
}

@media (min-width: 414px) {
  .work .seccion_1 .linea {
    height: 2.58px;
    width: 12.9px;
  }
}

@media (min-width: 768px) {
  .work .seccion_1 .linea {
    height: 4.8px;
    width: 24px;
  }
}

@media (min-width: 992px) {
  .work .seccion_1 .linea {
    height: 8.27px;
    width: 24.81px;
  }
}

@media (min-width: 1200px) {
  .work .seccion_1 .linea {
    height: 10px;
    width: 30px;
  }
}

@media (min-width: 1920px) {
  .work .seccion_1 .linea {
    height: 16px;
    width: 48px;
  }
}

@media (min-width: 2400px) {
  .work .seccion_1 .linea {
    height: 20px;
    width: 60px;
  }
}

.work .seccion_1 .titulo {
  font-family: "Gotham-Medium";
  color: #3f3f3d;
  line-height: 1.2;
  font-size: 20px;
  margin-left: 8px;
  margin-top: 5px;
  margin-bottom: 8px;
}

@media (min-width: 414px) {
  .work .seccion_1 .titulo {
    font-size: 25.8px;
    margin-left: 10.32px;
    margin-top: 6.45px;
    margin-bottom: 10.32px;
  }
}

@media (min-width: 768px) {
  .work .seccion_1 .titulo {
    font-size: 48px;
    margin-left: 19.2px;
    margin-top: 12px;
    margin-bottom: 19.2px;
  }
}

@media (min-width: 992px) {
  .work .seccion_1 .titulo {
    margin-left: 0;
    margin-top: 0;
    line-height: 0.76911;
    font-size: 33.08px;
    margin-bottom: 4.135px;
    padding-top: 10.751px;
  }
}

@media (min-width: 1200px) {
  .work .seccion_1 .titulo {
    line-height: 0.93;
    font-size: 40px;
    margin-bottom: 5px;
    padding-top: 13px;
  }
}

@media (min-width: 1920px) {
  .work .seccion_1 .titulo {
    line-height: 1.488;
    font-size: 64px;
    margin-bottom: 8px;
    padding-top: 20.8px;
  }
}

@media (min-width: 2400px) {
  .work .seccion_1 .titulo {
    line-height: 1.86;
    font-size: 80px;
    margin-bottom: 10px;
    padding-top: 26px;
  }
}

.work .seccion_1 .titulo strong {
  font-family: "Gotham-Black";
}

@media (min-width: 992px) {
  .work .seccion_1 .titulo strong {
    font-size: 41.35px;
  }
}

@media (min-width: 1200px) {
  .work .seccion_1 .titulo strong {
    font-size: 50px;
  }
}

@media (min-width: 1920px) {
  .work .seccion_1 .titulo strong {
    font-size: 80px;
  }
}

@media (min-width: 2400px) {
  .work .seccion_1 .titulo strong {
    font-size: 100px;
  }
}

.work .seccion_1 .titulo p {
  margin-bottom: 0;
}

@media (max-width: 991px) {
  .work .seccion_1 .titulo p {
    display: inline-block;
  }
}

.work .seccion_1 .cliente {
  font-family: "Gotham-MediumItalic";
  color: #3f3f3d;
  font-size: 6px;
  margin-bottom: 17px;
  margin-left: 8px;
}

@media (min-width: 414px) {
  .work .seccion_1 .cliente {
    font-size: 7.74px;
    margin-bottom: 21.93px;
    margin-left: 10.32px;
  }
}

@media (min-width: 768px) {
  .work .seccion_1 .cliente {
    font-size: 14.4px;
    margin-bottom: 40.8px;
    margin-left: 19.2px;
  }
}

@media (min-width: 992px) {
  .work .seccion_1 .cliente {
    font-size: 9.45261px;
    margin-bottom: 35.561px;
  }
}

@media (min-width: 1200px) {
  .work .seccion_1 .cliente {
    font-size: 11.43px;
    margin-bottom: 43px;
  }
}

@media (min-width: 1920px) {
  .work .seccion_1 .cliente {
    font-size: 18.288px;
    margin-bottom: 68.8px;
  }
}

@media (min-width: 2400px) {
  .work .seccion_1 .cliente {
    font-size: 22.86px;
    margin-bottom: 86px;
  }
}

.work .seccion_1 .descripcion {
  font-family: "Gotham-Book";
  color: #3f3f3d;
  line-height: 1.2;
  text-align: left;
  margin: 0 auto 24px;
  text-align: justify;
  font-size: 9px;
  width: 240px;
}

@media (min-width: 414px) {
  .work .seccion_1 .descripcion {
    font-size: 11.61px;
    width: 309.6px;
    margin: 0 auto 30.96px;
  }
}

@media (min-width: 768px) {
  .work .seccion_1 .descripcion {
    font-size: 21.6px;
    width: 576px;
    margin: 0 auto 57.6px;
  }
}

@media (min-width: 992px) {
  .work .seccion_1 .descripcion {
    text-align: left;
    letter-spacing: 0.01em;
    margin-bottom: 0px;
    font-size: 13.232px;
    width: 347.34px;
    margin-right: initial;
    margin-left: initial;
  }
}

@media (min-width: 1200px) {
  .work .seccion_1 .descripcion {
    font-size: 16px;
    width: 420px;
  }
}

@media (min-width: 1920px) {
  .work .seccion_1 .descripcion {
    font-size: 25.6px;
    width: 672px;
  }
}

@media (min-width: 2400px) {
  .work .seccion_1 .descripcion {
    font-size: 32px;
    width: 840px;
  }
}

.work .seccion_1 .contenedor_imagen_principal {
  position: relative;
}

.work .seccion_1 .contenedor_imagen_principal::before {
  position: absolute;
  z-index: -1;
  width: 100%;
  left: 0;
  bottom: -72px;
  height: calc(50% + 72px);
  content: '';
  background-color: #941b7e;
}

@media (min-width: 414px) {
  .work .seccion_1 .contenedor_imagen_principal::before {
    bottom: -92.88px;
    height: calc(50% + (72px * 1.29));
  }
}

@media (min-width: 768px) {
  .work .seccion_1 .contenedor_imagen_principal::before {
    bottom: -172.8px;
    height: calc(50% + (72px * 2.4));
  }
}

@media (min-width: 992px) {
  .work .seccion_1 .contenedor_imagen_principal::before {
    display: none;
  }
}

.work .seccion_1 .imagen_principal {
  width: 100%;
  background-size: cover;
  background-position: 50% 50%;
  position: relative;
  max-width: 280px;
  height: 158px;
  margin: auto;
}

@media (min-width: 414px) {
  .work .seccion_1 .imagen_principal {
    max-width: 361.2px;
    height: 203.82px;
  }
}

@media (min-width: 768px) {
  .work .seccion_1 .imagen_principal {
    max-width: 672px;
    height: 379.2px;
  }
}

@media (min-width: 992px) {
  .work .seccion_1 .imagen_principal {
    height: 306.817px;
    margin-right: initial;
    margin-left: initial;
  }
}

@media (min-width: 1200px) {
  .work .seccion_1 .imagen_principal {
    height: 371px;
    max-width: 100%;
  }
}

@media (min-width: 1920px) {
  .work .seccion_1 .imagen_principal {
    height: 593.6px;
  }
}

@media (min-width: 2400px) {
  .work .seccion_1 .imagen_principal {
    height: 742px;
  }
}

.work .seccion_1 .imagen_principal .categoria {
  position: absolute;
  transform: translateX(-100%);
  font-family: "Gotham-Black";
  left: -76px;
  top: 37px;
  font-size: 12px;
  font-size: 22.86px;
  line-height: 1;
  color: #941b7e;
}

.work .seccion_1 .imagen_principal .linea_categoria {
  position: absolute;
  width: 70px;
  height: 1px;
  background-color: #000000;
  left: -70px;
  top: 51px;
}

@media (min-width: 992px) {
  .work .seccion_1 .imagen_principal .rectangulo_gris {
    position: absolute;
    background-color: #3f3f3d;
    height: 74.43px;
    width: 12.405px;
    left: -12.405px;
    top: 96.759px;
  }
}

@media (min-width: 1200px) {
  .work .seccion_1 .imagen_principal .rectangulo_gris {
    height: 90px;
    width: 15px;
    left: -15px;
    top: 117px;
  }
}

@media (min-width: 1920px) {
  .work .seccion_1 .imagen_principal .rectangulo_gris {
    height: 144px;
    width: 24px;
    left: -24px;
    top: 187.2px;
  }
}

@media (min-width: 2400px) {
  .work .seccion_1 .imagen_principal .rectangulo_gris {
    height: 180px;
    width: 30px;
    left: -30px;
    top: 234px;
  }
}

.work .seccion_1 .imagen_principal .etiquetas {
  width: 100%;
  text-align: left;
  right: 0;
  position: absolute;
  font-family: "Gotham-MediumItalic";
  height: 15px;
  bottom: -18px;
  font-size: 6px;
  color: #fff;
}

@media (min-width: 414px) {
  .work .seccion_1 .imagen_principal .etiquetas {
    height: 19.35px;
    bottom: -23.22px;
    font-size: 7.74px;
  }
}

@media (min-width: 768px) {
  .work .seccion_1 .imagen_principal .etiquetas {
    height: 36px;
    bottom: -43.2px;
    font-size: 14.4px;
  }
}

@media (min-width: 992px) {
  .work .seccion_1 .imagen_principal .etiquetas {
    color: #9e9c9b;
    height: 16.54px;
    bottom: -20.675px;
    font-size: 7.55878px;
    text-align: right;
  }
}

@media (min-width: 1200px) {
  .work .seccion_1 .imagen_principal .etiquetas {
    height: 20px;
    bottom: -25px;
    font-size: 9.14px;
  }
}

@media (min-width: 1920px) {
  .work .seccion_1 .imagen_principal .etiquetas {
    height: 32px;
    bottom: -40px;
    font-size: 14.624px;
  }
}

@media (min-width: 2400px) {
  .work .seccion_1 .imagen_principal .etiquetas {
    height: 40px;
    bottom: -50px;
    font-size: 18.28px;
  }
}

.work .seccion_1_5 {
  margin-top: 20px;
}

@media (min-width: 414px) {
  .work .seccion_1_5 {
    margin-top: 25.8px;
  }
}

@media (min-width: 768px) {
  .work .seccion_1_5 {
    margin-top: 48px;
  }
}

@media (min-width: 992px) {
  .work .seccion_1_5 {
    margin-top: 49.62px;
  }
}

@media (min-width: 1200px) {
  .work .seccion_1_5 {
    margin-top: 60px;
  }
}

@media (min-width: 1920px) {
  .work .seccion_1_5 {
    margin-top: 96px;
  }
}

@media (min-width: 2400px) {
  .work .seccion_1_5 {
    margin-top: 120px;
  }
}

.work .seccion_1_5 video,
.work .seccion_1_5 img {
  width: 100%;
}

.work .seccion_2 {
  padding-top: 0px;
  position: relative;
}

@media (min-width: 992px) {
  .work .seccion_2 .rectangulo_morado {
    width: 157.13px;
    height: 62.852px;
    position: absolute;
    right: 0;
    top: 0px;
    background-color: #941b7e;
    z-index: 1;
  }
}

@media (min-width: 1200px) {
  .work .seccion_2 .rectangulo_morado {
    width: 190px;
    height: 76px;
  }
}

@media (min-width: 1920px) {
  .work .seccion_2 .rectangulo_morado {
    width: 304px;
    height: 121.6px;
  }
}

@media (min-width: 2400px) {
  .work .seccion_2 .rectangulo_morado {
    width: 380px;
    height: 152px;
  }
}

.work .seccion_2 .imagen_grande {
  position: relative;
  width: 100%;
  margin-bottom: 5px;
}

@media (min-width: 414px) {
  .work .seccion_2 .imagen_grande {
    margin-bottom: 6.45px;
  }
}

@media (min-width: 768px) {
  .work .seccion_2 .imagen_grande {
    margin-bottom: 12px;
  }
}

@media (min-width: 992px) {
  .work .seccion_2 .imagen_grande {
    margin-bottom: 3.308px;
  }
}

@media (min-width: 1200px) {
  .work .seccion_2 .imagen_grande {
    margin-bottom: 4px;
  }
}

@media (min-width: 1920px) {
  .work .seccion_2 .imagen_grande {
    margin-bottom: 6.4px;
  }
}

@media (min-width: 2400px) {
  .work .seccion_2 .imagen_grande {
    margin-bottom: 8px;
  }
}

.work .seccion_2 .imagen_grande:after {
  padding-top: 56.25%;
  /* 16:9 ratio */
  display: block;
  content: '';
}

.work .seccion_2 .fila {
  display: flex;
  flex-direction: column !important;
}

@media (min-width: 992px) {
  .work .seccion_2 .fila {
    flex-direction: row !important;
  }
}

.work .seccion_2 .imagen_mediana {
  position: relative;
  width: 100%;
  margin-bottom: 5px;
}

@media (min-width: 992px) {
  .work .seccion_2 .imagen_mediana {
    width: calc(50% - (2px * 0.827));
  }
}

@media (min-width: 1200px) {
  .work .seccion_2 .imagen_mediana {
    width: calc(50% - 2px);
  }
}

@media (min-width: 1920px) {
  .work .seccion_2 .imagen_mediana {
    width: calc(50% - (2px * 1.6));
  }
}

@media (min-width: 2400px) {
  .work .seccion_2 .imagen_mediana {
    width: calc(50% - (2px * 2));
  }
}

@media (min-width: 414px) {
  .work .seccion_2 .imagen_mediana {
    margin-bottom: 6.45px;
  }
}

@media (min-width: 768px) {
  .work .seccion_2 .imagen_mediana {
    margin-bottom: 12px;
  }
}

@media (min-width: 992px) {
  .work .seccion_2 .imagen_mediana {
    margin-bottom: 3.308px;
  }
}

@media (min-width: 1200px) {
  .work .seccion_2 .imagen_mediana {
    margin-bottom: 4px;
  }
}

@media (min-width: 1920px) {
  .work .seccion_2 .imagen_mediana {
    margin-bottom: 6.4px;
  }
}

@media (min-width: 2400px) {
  .work .seccion_2 .imagen_mediana {
    margin-bottom: 8px;
  }
}

.work .seccion_2 .imagen_mediana:after {
  padding-top: 56.25%;
  /* 16:9 ratio */
  display: block;
  content: '';
}

.work .seccion_2 .imagen_pequena {
  position: relative;
  width: 100%;
  margin-bottom: 5px;
}

@media (min-width: 992px) {
  .work .seccion_2 .imagen_pequena {
    width: calc(33.333% - (3px * 0.827));
  }
}

@media (min-width: 1200px) {
  .work .seccion_2 .imagen_pequena {
    width: calc(33.333% - 3px);
  }
}

@media (min-width: 1920px) {
  .work .seccion_2 .imagen_pequena {
    width: calc(33.333% - (3px * 1.6));
  }
}

@media (min-width: 2400px) {
  .work .seccion_2 .imagen_pequena {
    width: calc(33.333% - (3px * 2));
  }
}

@media (min-width: 414px) {
  .work .seccion_2 .imagen_pequena {
    margin-bottom: 6.45px;
  }
}

@media (min-width: 768px) {
  .work .seccion_2 .imagen_pequena {
    margin-bottom: 12px;
  }
}

@media (min-width: 992px) {
  .work .seccion_2 .imagen_pequena {
    margin-bottom: 3.308px;
  }
}

@media (min-width: 1200px) {
  .work .seccion_2 .imagen_pequena {
    margin-bottom: 4px;
  }
}

@media (min-width: 1920px) {
  .work .seccion_2 .imagen_pequena {
    margin-bottom: 6.4px;
  }
}

@media (min-width: 2400px) {
  .work .seccion_2 .imagen_pequena {
    margin-bottom: 8px;
  }
}

.work .seccion_2 .imagen_pequena:after {
  padding-top: 56.25%;
  /* 16:9 ratio */
  display: block;
  content: '';
}

.work .seccion_2 .imagen_grande .main,
.work .seccion_2 .imagen_mediana .main,
.work .seccion_2 .imagen_pequena .main {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
}

.work .seccion_2 .imagen_grande .main > *,
.work .seccion_2 .imagen_mediana .main > *,
.work .seccion_2 .imagen_pequena .main > * {
  max-width: 100%;
  max-height: 100%;
  width: 100%;
  margin: auto;
  display: block;
}

.work .seccion_2 .imagen_grande .main .imagen,
.work .seccion_2 .imagen_mediana .main .imagen,
.work .seccion_2 .imagen_pequena .main .imagen {
  background-color: #fff;
  background-size: cover;
  background-position: 50% 50%;
}

.work .seccion_3 {
  margin-top: 32px;
  margin-bottom: 133px;
}

.work .seccion_3 .container {
  position: relative;
}

.work .seccion_3 .fila {
  display: flex;
}

.work .seccion_3 .btn_izquierdo {
  width: 59px;
  height: 39px;
  background-image: url(../img/work/btn_izquierdo.svg);
  background-size: 100% auto;
  background-repeat: no-repeat;
}

.work .seccion_3 .btn_derecho {
  width: 59px;
  height: 39px;
  background-image: url(../img/work/btn_derecho.svg);
  background-size: 100% auto;
  background-repeat: no-repeat;
}

.work .seccion_3 .orden {
  color: #3f3f3d;
  font-family: "Gotham-Medium";
  font-size: 11.43px;
  padding-top: 10px;
}

.work .seccion_3 .cuadrito_inicial {
  position: absolute;
  width: 19px;
  height: 19px;
  top: 95px;
  right: 31px;
  z-index: 11;
}

@media (max-width: 991px) {
  .d2 .seccion_1 .container,
  .d2 .seccion_3 .container {
    max-width: initial;
  }
}

.d2 #giffy-images-container img {
  position: absolute;
  top: 0;
  left: 0;
  transition: opacity 0.3s;
}

.d2 .esquina_derecha {
  background: none;
}

.d2 .seccion_0 .breadcrumb {
  line-height: 1;
  position: relative;
  display: block;
  display: inline-block;
  margin-bottom: 0px;
  padding-bottom: 9px;
  margin-top: 14px;
  margin-left: 8px;
}

@media (min-width: 414px) {
  .d2 .seccion_0 .breadcrumb {
    padding-bottom: 11.61px;
    margin-top: 18.06px;
    margin-left: 10.32px;
  }
}

@media (min-width: 768px) {
  .d2 .seccion_0 .breadcrumb {
    padding-bottom: 21.6px;
    margin-top: 33.6px;
    margin-left: 19.2px;
  }
}

@media (min-width: 992px) {
  .d2 .seccion_0 .breadcrumb {
    margin-left: 0px;
    padding-bottom: 11.578px;
    margin-top: 11.578px;
  }
}

@media (min-width: 1200px) {
  .d2 .seccion_0 .breadcrumb {
    padding-bottom: 14px;
    margin-top: 14px;
  }
}

@media (min-width: 1920px) {
  .d2 .seccion_0 .breadcrumb {
    padding-bottom: 22.4px;
    margin-top: 22.4px;
  }
}

@media (min-width: 2400px) {
  .d2 .seccion_0 .breadcrumb {
    padding-bottom: 28px;
    margin-top: 28px;
  }
}

.d2 .seccion_0 .breadcrumb a {
  font-family: "Gotham-Book";
  font-size: 9px;
  color: #3c3d38;
  text-decoration: none;
}

@media (min-width: 414px) {
  .d2 .seccion_0 .breadcrumb a {
    font-size: 11.61px;
  }
}

@media (min-width: 768px) {
  .d2 .seccion_0 .breadcrumb a {
    font-size: 21.6px;
  }
}

@media (min-width: 992px) {
  .d2 .seccion_0 .breadcrumb a {
    font-size: 7.443px;
  }
}

@media (min-width: 1200px) {
  .d2 .seccion_0 .breadcrumb a {
    font-size: 9px;
  }
}

@media (min-width: 1920px) {
  .d2 .seccion_0 .breadcrumb a {
    font-size: 14.4px;
  }
}

@media (min-width: 2400px) {
  .d2 .seccion_0 .breadcrumb a {
    font-size: 18px;
  }
}

.d2 .seccion_0 .breadcrumb a.active {
  font-family: "Gotham-Bold";
  margin-right: 0px;
}

@media (min-width: 992px) {
  .d2 .seccion_0 .breadcrumb a.active {
    margin-right: 14.886px;
  }
}

@media (min-width: 1200px) {
  .d2 .seccion_0 .breadcrumb a.active {
    margin-right: 18px;
  }
}

@media (min-width: 1920px) {
  .d2 .seccion_0 .breadcrumb a.active {
    margin-right: 28.8px;
  }
}

@media (min-width: 2400px) {
  .d2 .seccion_0 .breadcrumb a.active {
    margin-right: 36px;
  }
}

.d2 .seccion_0 .breadcrumb span {
  color: #000000;
  font-family: "Gotham-Book";
  font-size: 9px;
  margin-left: 3px;
  margin-right: 0px;
}

@media (min-width: 414px) {
  .d2 .seccion_0 .breadcrumb span {
    font-size: 11.61px;
    margin-left: 3.87px;
  }
}

@media (min-width: 768px) {
  .d2 .seccion_0 .breadcrumb span {
    font-size: 21.6px;
    margin-left: 7.2px;
  }
}

@media (min-width: 992px) {
  .d2 .seccion_0 .breadcrumb span {
    font-size: 7.443px;
    margin-left: 5.789px;
    margin-right: 6.616px;
  }
}

@media (min-width: 1200px) {
  .d2 .seccion_0 .breadcrumb span {
    font-size: 9px;
    margin-left: 7px;
    margin-right: 8px;
  }
}

@media (min-width: 1920px) {
  .d2 .seccion_0 .breadcrumb span {
    font-size: 14.4px;
    margin-left: 11.2px;
    margin-right: 12.8px;
  }
}

@media (min-width: 2400px) {
  .d2 .seccion_0 .breadcrumb span {
    font-size: 18px;
    margin-left: 14px;
    margin-right: 16px;
  }
}

.d2 .seccion_0 .breadcrumb::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 1px;
  background: #000;
  bottom: 0;
  left: 0;
}

.d2 .seccion_1 {
  position: relative;
  padding-bottom: 36px;
  padding-top: 30px;
}

@media (min-width: 414px) {
  .d2 .seccion_1 {
    padding-bottom: 46.44px;
  }
}

@media (min-width: 768px) {
  .d2 .seccion_1 {
    padding-bottom: 86.4px;
  }
}

@media (min-width: 992px) {
  .d2 .seccion_1 {
    padding-bottom: 0px;
  }
}

.d2 .seccion_1 .container {
  position: relative;
}

@media (min-width: 414px) {
  .d2 .seccion_1 {
    padding-top: 38.7px;
  }
}

@media (min-width: 768px) {
  .d2 .seccion_1 {
    padding-top: 72px;
  }
}

@media (min-width: 992px) {
  .d2 .seccion_1 {
    padding-top: 77.738px;
  }
}

@media (min-width: 1200px) {
  .d2 .seccion_1 {
    padding-top: 94px;
  }
}

@media (min-width: 1920px) {
  .d2 .seccion_1 {
    padding-top: 150.4px;
  }
}

@media (min-width: 2400px) {
  .d2 .seccion_1 {
    padding-top: 188px;
  }
}

.d2 .seccion_1 .linea {
  height: 2px;
  width: 10px;
  margin-left: 8px;
}

@media (min-width: 414px) {
  .d2 .seccion_1 .linea {
    margin-left: 10.32px;
  }
}

@media (min-width: 768px) {
  .d2 .seccion_1 .linea {
    margin-left: 19.2px;
  }
}

@media (min-width: 992px) {
  .d2 .seccion_1 .linea {
    margin-left: 0;
  }
}

@media (min-width: 414px) {
  .d2 .seccion_1 .linea {
    height: 2.58px;
    width: 12.9px;
  }
}

@media (min-width: 768px) {
  .d2 .seccion_1 .linea {
    height: 4.8px;
    width: 24px;
  }
}

@media (min-width: 992px) {
  .d2 .seccion_1 .linea {
    margin-top: 6.616px;
    height: 8.27px;
    width: 24.81px;
  }
}

@media (min-width: 1200px) {
  .d2 .seccion_1 .linea {
    margin-top: 8px;
    height: 10px;
    width: 30px;
  }
}

@media (min-width: 1920px) {
  .d2 .seccion_1 .linea {
    margin-top: 12.8px;
    height: 16px;
    width: 48px;
  }
}

@media (min-width: 2400px) {
  .d2 .seccion_1 .linea {
    margin-top: 16px;
    height: 20px;
    width: 60px;
  }
}

.d2 .seccion_1 .titulo {
  font-family: "Gotham-Medium";
  color: #3f3f3d;
  line-height: 1.2;
  font-size: 20px;
  margin-left: 8px;
  margin-top: 5px;
  margin-bottom: 16px;
}

@media (min-width: 414px) {
  .d2 .seccion_1 .titulo {
    font-size: 25.8px;
    margin-left: 10.32px;
    margin-top: 6.45px;
    margin-bottom: 20.64px;
  }
}

@media (min-width: 768px) {
  .d2 .seccion_1 .titulo {
    font-size: 48px;
    margin-left: 19.2px;
    margin-top: 12px;
    margin-bottom: 38.4px;
  }
}

@media (min-width: 992px) {
  .d2 .seccion_1 .titulo {
    font-family: "Gotham-Black";
    line-height: 1.4;
    font-size: 41.35px;
    margin-bottom: 49.62px;
    padding-top: 4.962px;
    margin-left: 0;
    margin-top: 0;
  }
}

@media (min-width: 1200px) {
  .d2 .seccion_1 .titulo {
    font-size: 50px;
    margin-bottom: 60px;
    padding-top: 6px;
  }
}

@media (min-width: 1920px) {
  .d2 .seccion_1 .titulo {
    font-size: 80px;
    margin-bottom: 96px;
    padding-top: 9.6px;
  }
}

@media (min-width: 2400px) {
  .d2 .seccion_1 .titulo {
    font-size: 100px;
    margin-bottom: 120px;
    padding-top: 12px;
  }
}

.d2 .seccion_1 .descripcion {
  font-family: "Gotham-Book";
  color: #3f3f3d;
  line-height: 1.2;
  text-align: left;
  margin: 0 auto 30px;
  text-align: justify;
  font-size: 9px;
  width: 240px;
}

@media (min-width: 414px) {
  .d2 .seccion_1 .descripcion {
    font-size: 11.61px;
    width: 309.6px;
    margin: 0 auto 38.7px;
  }
}

@media (min-width: 768px) {
  .d2 .seccion_1 .descripcion {
    font-size: 21.6px;
    width: 576px;
    margin: 0 auto 72px;
  }
}

@media (min-width: 992px) {
  .d2 .seccion_1 .descripcion {
    text-align: left;
    letter-spacing: -0.013em;
    margin-bottom: 0px;
    font-size: 13.232px;
    width: 419.00782px;
    margin-right: initial;
    margin-left: initial;
  }
}

@media (min-width: 1200px) {
  .d2 .seccion_1 .descripcion {
    font-size: 16px;
    margin-bottom: 0px;
    width: 506.66px;
  }
}

@media (min-width: 1920px) {
  .d2 .seccion_1 .descripcion {
    font-size: 25.6px;
    margin-bottom: 0px;
    width: 810.656px;
  }
}

@media (min-width: 2400px) {
  .d2 .seccion_1 .descripcion {
    font-size: 32px;
    margin-bottom: 0px;
    width: 1013.32px;
  }
}

.d2 .seccion_1 .contenedor_imagen_principal {
  position: relative;
}

.d2 .seccion_1 .contenedor_imagen_principal::before {
  position: absolute;
  z-index: -1;
  width: 100%;
  left: 0;
  bottom: -36px;
  height: calc(50% + 36px);
  content: '';
  background-color: #941b7e;
}

@media (min-width: 414px) {
  .d2 .seccion_1 .contenedor_imagen_principal::before {
    bottom: -46.44px;
    height: calc(50% + (36px * 1.29));
  }
}

@media (min-width: 768px) {
  .d2 .seccion_1 .contenedor_imagen_principal::before {
    bottom: -86.4px;
    height: calc(50% + (36px * 2.4));
  }
}

@media (min-width: 992px) {
  .d2 .seccion_1 .contenedor_imagen_principal::before {
    display: none;
  }
}

.d2 .seccion_1 .imagen_principal {
  width: 100%;
  background-size: cover;
  background-position: 50% 50%;
  position: relative;
  background: #fff;
  max-width: 280px;
  height: 158px;
  margin: auto;
}

@media (min-width: 414px) {
  .d2 .seccion_1 .imagen_principal {
    max-width: 361.2px;
    height: 203.82px;
  }
}

@media (min-width: 768px) {
  .d2 .seccion_1 .imagen_principal {
    max-width: 672px;
    height: 379.2px;
  }
}

@media (min-width: 992px) {
  .d2 .seccion_1 .imagen_principal {
    max-width: 470.563px;
    height: 264.64px;
    margin-left: 12.405px;
    margin-right: initial;
  }
}

@media (min-width: 1200px) {
  .d2 .seccion_1 .imagen_principal {
    max-width: 569px;
    height: 320px;
    margin-left: 15px;
  }
}

@media (min-width: 1920px) {
  .d2 .seccion_1 .imagen_principal {
    max-width: 910.4px;
    height: 512px;
    margin-left: 24px;
  }
}

@media (min-width: 2400px) {
  .d2 .seccion_1 .imagen_principal {
    max-width: 1138px;
    height: 640px;
    margin-left: 30px;
  }
}

.d2 .seccion_1 .imagen_principal img {
  width: 100%;
}

.d2 .seccion_2 {
  padding-top: 83px;
  padding-bottom: 83px;
  position: relative;
  /* clear fix */
}

.d2 .seccion_2 .grid:after {
  content: '';
  display: block;
  clear: both;
}

.d2 .seccion_2 .grid-item,
.d2 .seccion_2 .grid-sizer {
  width: 0.01%;
}

.d2 .seccion_2 .gutter-sizer {
  width: 0.2%;
}

.d2 .seccion_2 .grid-item {
  float: left;
}

.d2 .seccion_2 .imagen .imagen_interna {
  background-size: auto 100%;
  background-position: 50% 50%;
  transition: background-size 0.5s ease-in;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.d2 .seccion_2 .imagen .titulo {
  display: none;
}

.d2 .seccion_2 .imagen .titulo {
  font-size: 23px;
  margin-bottom: 8px;
}

.d2 .seccion_2 .imagen .go {
  display: none;
  width: 185px;
  height: 45px;
  font-size: 20px;
}

.d2 .seccion_2 .imagen .go img {
  display: block;
  margin-left: 11px;
  height: 29px;
  padding-top: 2px;
}

@media (min-width: 992px) {
  .d2 .seccion_2 .imagen .go img {
    margin-left: 9.097px;
    height: 23.983px;
    padding-top: 1.654px;
  }
}

@media (min-width: 1200px) {
  .d2 .seccion_2 .imagen .go img {
    margin-left: 11px;
    height: 29px;
    padding-top: 2px;
  }
}

@media (min-width: 1920px) {
  .d2 .seccion_2 .imagen .go img {
    margin-left: 17.6px;
    height: 46.4px;
    padding-top: 3.2px;
  }
}

@media (min-width: 2400px) {
  .d2 .seccion_2 .imagen .go img {
    margin-left: 22px;
    height: 58px;
    padding-top: 4px;
  }
}

.d2 .seccion_2 .imagen:hover .imagen_interna {
  background-size: auto 110% !important;
}

.d2 .seccion_2 .imagen:hover .overlay_1 {
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #3f3f3d;
  mix-blend-mode: color;
}

.d2 .seccion_2 .imagen:hover .overlay_2 {
  z-index: 2;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(106, 173, 223, 0.5);
  mix-blend-mode: color;
}

.d2 .seccion_2 .imagen:hover .overlay_3 {
  z-index: 3;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: black;
  background: -moz-linear-gradient(90deg, black 0%, rgba(0, 0, 0, 0) 100%);
  background: -webkit-linear-gradient(90deg, black 0%, rgba(0, 0, 0, 0) 100%);
  background: linear-gradient(90deg, black 0%, rgba(0, 0, 0, 0) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000",endColorstr="#000000",GradientType=1);
  opacity: 0.7;
}

.d2 .seccion_2 .imagen:hover .titulo {
  display: block;
  z-index: 4;
  font-family: "Gotham-Black";
  color: white;
  text-align: center;
}

.d2 .seccion_2 .imagen:hover .go {
  z-index: 5;
  text-decoration: none;
  display: flex !important;
  justify-content: center;
  align-items: center;
  font-family: "Gotham-Book";
  color: #fff;
  position: relative;
}

.d2 .seccion_2 .imagen:hover .go:before {
  content: '';
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  /*background: rgb(106,173,223);
						background: -moz-linear-gradient(90deg, rgba(106,173,223,1) 0%, rgba(166,187,53,1) 100%);
						background: -webkit-linear-gradient(90deg, rgba(106,173,223,1) 0%, rgba(166,187,53,1) 100%);
						background: linear-gradient(90deg, rgba(106,173,223,1) 0%, rgba(166,187,53,1) 100%);
						filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#6aaddf",endColorstr="#a6bb35",GradientType=1); 
						
						background: rgb(106,173,223);
						background: -moz-linear-gradient(90deg, rgba(106,173,223,1) 0%, rgba(158,27,126,1) 100%);
						background: -webkit-linear-gradient(90deg, rgba(106,173,223,1) 0%, rgba(158,27,126,1) 100%);
						background: linear-gradient(90deg, rgba(106,173,223,1) 0%, rgba(158,27,126,1) 100%);
						filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#6aaddf",endColorstr="#9e1b7e",GradientType=1);  
						*/
  background: #b53925;
  background: -moz-linear-gradient(90deg, #b53925 0%, #c7802a 100%);
  background: -webkit-linear-gradient(90deg, #b53925 0%, #c7802a 100%);
  background: linear-gradient(90deg, #b53925 0%, #c7802a 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#B53925",endColorstr="#C7802A",GradientType=1);
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: destination-out;
  mask-composite: exclude;
  padding: 2px;
  border-radius: 35px;
}

@media (min-width: 992px) {
  .d2 .seccion_2 .imagen:hover .go:before {
    padding: 1px;
    border-radius: 29px;
  }
}

@media (min-width: 1200px) {
  .d2 .seccion_2 .imagen:hover .go:before {
    padding: 2px;
    border-radius: 35px;
  }
}

@media (min-width: 1920px) {
  .d2 .seccion_2 .imagen:hover .go:before {
    padding: 3px;
    border-radius: 56px;
  }
}

@media (min-width: 2400px) {
  .d2 .seccion_2 .imagen:hover .go:before {
    padding: 4px;
    border-radius: 70px;
  }
}

.d2 .seccion_2 .imagen_0 {
  width: 37.38%;
  background-color: red;
}

.d2 .seccion_2 .imagen_0:before {
  content: '';
  display: block;
  padding-top: 100%;
  width: 100%;
  position: relative;
  float: left;
}

.d2 .seccion_2 .imagen_0 .imagen_interna {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.d2 .seccion_2 .imagen_0 .imagen_interna:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.d2 .seccion_2 .imagen_1 {
  width: 31.31%;
  background-color: purple;
}

.d2 .seccion_2 .imagen_1:before {
  content: '';
  display: block;
  padding-top: 100%;
  width: 100%;
  position: relative;
  float: left;
}

.d2 .seccion_2 .imagen_1 .imagen_interna {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.d2 .seccion_2 .imagen_1 .imagen_interna:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.d2 .seccion_2 .imagen_2 {
  width: 31.31%;
  background-color: yellow;
}

.d2 .seccion_2 .imagen_2:before {
  content: '';
  display: block;
  padding-top: 100%;
  width: 100%;
  position: relative;
  float: left;
}

.d2 .seccion_2 .imagen_2 .imagen_interna {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.d2 .seccion_2 .imagen_2 .imagen_interna:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.d2 .seccion_2 .imagen_3 {
  width: 25.24%;
  background-color: green;
}

.d2 .seccion_2 .imagen_3:before {
  content: '';
  display: block;
  padding-top: 98.2%;
  width: 100%;
  position: relative;
  float: left;
}

.d2 .seccion_2 .imagen_3 .imagen_interna {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.d2 .seccion_2 .imagen_3 .imagen_interna:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.d2 .seccion_2 .imagen_4 {
  width: 37.38%;
  background-color: blue;
}

.d2 .seccion_2 .imagen_4:before {
  content: '';
  display: block;
  padding-top: 100%;
  width: 100%;
  position: relative;
  float: left;
}

.d2 .seccion_2 .imagen_4 .imagen_interna {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.d2 .seccion_2 .imagen_4 .imagen_interna:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.d2 .seccion_2 .imagen_5 {
  width: 18.69%;
  background-color: gray;
}

.d2 .seccion_2 .imagen_5:before {
  content: '';
  display: block;
  padding-top: 100%;
  width: 100%;
  position: relative;
  float: left;
}

.d2 .seccion_2 .imagen_5 .imagen_interna {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.d2 .seccion_2 .imagen_5 .imagen_interna:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.d2 .seccion_2 .imagen_6 {
  background-color: gray;
  width: 18.69%;
}

.d2 .seccion_2 .imagen_6:before {
  content: '';
  display: block;
  padding-top: 100%;
  width: 100%;
  position: relative;
  float: left;
}

.d2 .seccion_2 .imagen_6 .imagen_interna {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.d2 .seccion_2 .imagen_6 .imagen_interna:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.d2 .seccion_2 .imagen_7 {
  background-color: gray;
  width: 31.31%;
}

.d2 .seccion_2 .imagen_7:before {
  content: '';
  display: block;
  padding-top: 100%;
  width: 100%;
  position: relative;
  float: left;
}

.d2 .seccion_2 .imagen_7 .imagen_interna {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.d2 .seccion_2 .imagen_7 .imagen_interna:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.d2 .seccion_2 .imagen_8 {
  background-color: gray;
  width: 31.31%;
}

.d2 .seccion_2 .imagen_8:before {
  content: '';
  display: block;
  padding-top: 100%;
  width: 100%;
  position: relative;
  float: left;
}

.d2 .seccion_2 .imagen_8 .imagen_interna {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.d2 .seccion_2 .imagen_8 .imagen_interna:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.d2 .seccion_2 .imagen_9 {
  background-color: gray;
  width: 18.69%;
}

.d2 .seccion_2 .imagen_9:before {
  content: '';
  display: block;
  padding-top: 100%;
  width: 100%;
  position: relative;
  float: left;
}

.d2 .seccion_2 .imagen_9 .imagen_interna {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.d2 .seccion_2 .imagen_9 .imagen_interna:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.d2 .seccion_2 .imagen_10 {
  background-color: gray;
  width: 18.72%;
}

.d2 .seccion_2 .imagen_10:before {
  content: '';
  display: block;
  padding-top: 100%;
  width: 100%;
  position: relative;
  float: left;
}

.d2 .seccion_2 .imagen_10 .imagen_interna {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.d2 .seccion_2 .imagen_10 .imagen_interna:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.d2 .seccion_2 .rectangulo_morado {
  width: 190px;
  height: 76px;
  position: absolute;
  right: 0;
  top: 128px;
  background-color: #941b7e;
  z-index: 1;
}

.d2 .seccion_2 .imagen_grande {
  position: relative;
  width: 100%;
  margin-bottom: 4px;
}

.d2 .seccion_2 .imagen_grande:after {
  padding-top: 56.25%;
  /* 16:9 ratio */
  display: block;
  content: '';
}

.d2 .seccion_2 .fila {
  display: flex;
}

.d2 .seccion_2 .imagen_mediana {
  position: relative;
  width: calc(50% - 2px);
  margin-bottom: 4px;
}

.d2 .seccion_2 .imagen_mediana:after {
  padding-top: 56.25%;
  /* 16:9 ratio */
  display: block;
  content: '';
}

.d2 .seccion_2 .imagen_pequena {
  position: relative;
  width: calc(33.333% - 3px);
  margin-bottom: 4px;
}

.d2 .seccion_2 .imagen_pequena:after {
  padding-top: 56.25%;
  /* 16:9 ratio */
  display: block;
  content: '';
}

.d2 .seccion_2 .imagen_grande .main,
.d2 .seccion_2 .imagen_mediana .main,
.d2 .seccion_2 .imagen_pequena .main {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
}

.d2 .seccion_2 .imagen_grande .main > *,
.d2 .seccion_2 .imagen_mediana .main > *,
.d2 .seccion_2 .imagen_pequena .main > * {
  width: 100%;
  height: 100%;
}

.d2 .seccion_2 .imagen_grande .main .imagen,
.d2 .seccion_2 .imagen_mediana .main .imagen,
.d2 .seccion_2 .imagen_pequena .main .imagen {
  background-color: #fff;
  background-size: cover;
  background-position: 50% 50%;
}

.d2 .seccion_3 {
  padding-top: 5px;
}

@media (min-width: 414px) {
  .d2 .seccion_3 {
    padding-top: 6.45px;
  }
}

@media (min-width: 768px) {
  .d2 .seccion_3 {
    padding-top: 12px;
  }
}

@media (min-width: 992px) {
  .d2 .seccion_3 {
    display: none;
  }
}

.d2 .seccion_3 .imagen {
  height: 50vw;
  background-size: cover;
  background-position: 50% 50%;
  margin-bottom: 5px;
  padding-left: 0;
  padding-right: 0;
  transition: background-size 0.5s ease-in;
}

@media (min-width: 414px) {
  .d2 .seccion_3 .imagen {
    margin-bottom: 6.45px;
  }
}

@media (min-width: 768px) {
  .d2 .seccion_3 .imagen {
    margin-bottom: 12px;
  }
}

.d2 .seccion_3 .imagen .imagen_interna {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
}

.d2 .seccion_3 .imagen .overlay_1 {
  z-index: 3;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: black;
  background: -moz-linear-gradient(0deg, black 0%, rgba(0, 0, 0, 0) 100%);
  background: -webkit-linear-gradient(0deg, black 0%, rgba(0, 0, 0, 0) 100%);
  background: linear-gradient(0deg, black 0%, rgba(0, 0, 0, 0) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000",endColorstr="#000000",GradientType=1);
  opacity: 0.7;
}

.d2 .seccion_3 .imagen .titulo {
  font-family: "Gotham-Black";
  color: #fff;
  font-size: 19.3px;
  margin-bottom: 2px;
  position: relative;
  z-index: 5;
}

@media (min-width: 414px) {
  .d2 .seccion_3 .imagen .titulo {
    font-size: 24.897px;
    margin-bottom: 2.58px;
  }
}

@media (min-width: 768px) {
  .d2 .seccion_3 .imagen .titulo {
    font-size: 46.32px;
    margin-bottom: 4.8px;
  }
}

.d2 .seccion_3 .imagen .boton-movile {
  display: block;
  width: 68px;
  height: 29px;
  background-image: url(../img/work/boton-movile.svg);
  background-size: 100% auto;
  background-repeat: no-repeat;
  position: relative;
  z-index: 5;
}

@media (min-width: 414px) {
  .d2 .seccion_3 .imagen .boton-movile {
    width: 87.72px;
    height: 37.41px;
  }
}

@media (min-width: 768px) {
  .d2 .seccion_3 .imagen .boton-movile {
    width: 163.2px;
    height: 69.6px;
  }
}

.d2 #footer_work {
  background-color: #941b7e;
  width: 100%;
  height: 25px;
  margin-top: 84px;
}

@media (min-width: 414px) {
  .d2 #footer_work {
    height: 32.25px;
    margin-top: 108.36px;
  }
}

@media (min-width: 768px) {
  .d2 #footer_work {
    height: 60px;
    margin-top: 201.6px;
  }
}

@media (min-width: 992.4px) {
  .d2 #footer_work {
    display: none;
  }
}
